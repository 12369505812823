import { Client } from '../utils/client';
import { ApolloClient, gql } from 'apollo-boost';
import { onResponse, createError } from './grapqhlResponse';

export default class SchedulerService {

    client(): ApolloClient<any> {
        return new Client().createApolloClient();
    }

    scheduleDowngrade(downgradeType: string, projectsToBeDeleted, participantsToBeRemoved) {
        const participantsStr = JSON.stringify(participantsToBeRemoved).replace(/"([^(")"]+)":/g, "$1:");
        const projectsStr = JSON.stringify(projectsToBeDeleted).replace(/"([^(")"]+)":/g, "$1:");

        const scheduleDowngrade = gql`
            mutation {
                scheduleDowngrade(
                    downgradeType: "${downgradeType}",
                    projects: ${projectsStr},
                    participants: ${participantsStr}
                )
            }
        `;

        return this.client().mutate<{ scheduleDowngrade: any }>({
            mutation: scheduleDowngrade,
            errorPolicy: 'all'
        }).then(response =>
            onResponse(
                response,
                data => data.scheduleDowngrade
            )
        ).catch(createError);
    }
}