import React, { useState, useContext } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { ModalContext } from '../contexts/ModalContext';
import { useStripe, useElements, CardNumberElement, CardCvcElement, CardExpiryElement } from "@stripe/react-stripe-js";
import TextField from '@material-ui/core/TextField';
import { UserService } from '../../services/userService';
import RizkiLoadingEffect from '../load/RizkiLoadingEffect';
import { SnackbarContext } from '../contexts/SnackbarContext';
import { logError } from '../../services/grapqhlResponse';

const useStyles = makeStyles(theme => ({
    root: {
        width: "400px",
        paddingLeft: "7%",
        paddingRight: "7%",
        paddingTop: "7%",
        paddingBottom: "5%",
    },
    dialogContent: {
        display: "flex"
    },
    errorMessage: {
        display: "flex",
        height: "45px",
        justifyContent: "center",
        alignItems: "center",
        color: "#f70909de",
        fontFamily: "Roboto",
        fontSize: "16px",
        letterSpacing: "0.4px",
        lineHeight: "24px",
        opacity: 0.87
    },
}));


const useOptions = () => {
    return {
        style: {
            base: {
                fontSize: "18px",
                color: "#000000",
                letterSpacing: "0.4px",
                lineHeight: "30px",
                fontFamily: "Roboto",
                "::placeholder": {
                    fontSize: "18px",
                    color: "#aab7c4",
                    lineHeight: "30px"
                }
            },
            invalid: {
                color: "#f70909de"
            }
        }
    }
};

const CreditKarteDetailsModel = () => {
    const classes = useStyles();
    const options = useOptions();

    const userService = new UserService();
    const [modalProps, setModalProps] = useContext(ModalContext);

    const [errorMessage, setErrorMessage] = useState(null);
    const [saveInProgress, setSaveInProgress] = useState(false);

    const [cardHolderName, setCardHolderName] = useState("");
    const stripe = useStripe();
    const elements = useElements();
    const [, notifyUser] = useContext(SnackbarContext);

    const closeModal = () => {
        setModalProps({
            ...modalProps,
            PaymentDetails: {
                show: true,
                tab: 4
            },
            creditCardDetailsModal: {
                show: false
            }
        });

        setSaveInProgress(false);
    };

    const handleSave = async (event) => {
        setSaveInProgress(true);

        var regex = new RegExp("^[A-Za-z0-9_äÄöÖüÜß]");

        if (!regex.test(cardHolderName)) {
            notifyUser({
                show: true,
                type: "error",
                message: "Payment method could not be updated. Invalid name."
            });
            setSaveInProgress(false);
            return;
        }

        const cardName = cardHolderName;
        const cardElement = elements.getElement(CardNumberElement);
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        if (error) {
            setErrorMessage(error.message);
            setSaveInProgress(false);
        }
        else {
            setErrorMessage(null);
            stripe.createToken(cardElement, { name: cardName })
                .then(({ token }) => userService.updatePaymentDetails(paymentMethod.id, token.id, cardName))
                .then(() => {
                    notifyUser({
                        show: true,
                        type: "success",
                        message: "Payment method is updated successfully"
                    });
                    setSaveInProgress(false);
                    closeModal();
                }).catch(err => {
                    logError(err);
                    notifyUser({
                        show: true,
                        type: "error",
                        message: "Payment method could not be updated"
                    });
                    setSaveInProgress(false);
                })
        }
    }
    return (
        <div>
            <Dialog
                id="stripeMenuCreditCard"
                open={modalProps.creditCardDetailsModal.show}
                onClose={closeModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="lg"
            >
                <DialogContent className={classes.dialogContent} dividers>
                    <div className={classes.root}>
                        {saveInProgress ?
                            <Grid item xs={10} container justify="center" alignItems="center" style={{ "position": "absolute", marginTop: "15%" }}>
                                <RizkiLoadingEffect loading={saveInProgress}/>
                            </Grid> : null}
                        <Grid container direction="column" spacing={1}>
                            <Grid item xs={12}>
                                {
                                    errorMessage ? <span id="stripeErrorMessage" className={classes.errorMessage}>
                                        {errorMessage}
                                    </span> : null
                                }
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    type="text"
                                    // @ts-ignore
                                    pattern="^[a-zA-Z0-9]"
                                    id="cardHolderChange"
                                    name="cardHolder"
                                    label="Card Holder Name"
                                    variant="outlined"
                                    value={cardHolderName}

                                    fullWidth
                                    onChange={event => {
                                        setCardHolderName(event.target.value);
                                    }}
                                    required
                                    inputProps={{
                                        maxLength: 100,
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <CardNumberElement
                                    options={options}
                                    id="cardNumberChange"
                                    // @ts-ignore
                                    name="cardNumber"
                                    onChange={event => {
                                    }}
                                />
                            </Grid>
                            <Grid container item spacing={1}>
                                <Grid item xs={6}>
                                    <CardExpiryElement
                                        id="exp-dateChange"
                                        options={options}
                                        onChange={event => {
                                        }}
                                        // @ts-ignore
                                        inputProps={{
                                            disabled: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <CardCvcElement
                                        id="cvcChange"
                                        options={options}
                                        onChange={event => {
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeModal} color="primary" variant="outlined">
                        Cancel
                    </Button>
                    <Button disabled={saveInProgress} onClick={handleSave} color="primary" variant="contained">
                        SAVE
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default CreditKarteDetailsModel;