import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Moment from "moment";
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";
import CollectionsOutlinedIcon from "@material-ui/icons/CollectionsOutlined";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import Box from "@material-ui/core/Box";
import { RiskService } from "../../services/riskService";
import { RiskContext, RiskHistoryIndexed } from "../contexts/RiskContext";
import ShareRiskMatrixModal from "../ShareRiskMatrix/ShareRiskMatrixModal";
import RiskExportModal from "../RiskExport/RiskExportModal";
import { ModalContext } from "../contexts/ModalContext";
import { ServiceContext } from "../../services/serviceContext";
import { ResponseError, logError } from "../../services/grapqhlResponse";
import { UserContext } from "../contexts/UserContext";
import { DateTime } from "luxon";
import { RiskHistory } from "../RiskDetails/riskHistory";

// @ts-ignore
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
  },
  label: {
    color: "#000000",
    fontFamily: "Roboto",
    fontSize: "10px",
    fontWeight: [900],
    letterSpacing: "1.5px",
    lineHeight: "16px",
    opacity: 0.87,
  },
  formControl: {
    marginRight: "0px",
  },
  picked: {
    color: "#0869ff",
  },
  unpicked: {
    color: "#bdbdbd",
  },
}));

const RiskMatrixFooter = ({ selectedRisks, riskModal, project, projectRisks, toPdf }) => {
  const classes = useStyles();
  const riskService = new RiskService();
  const [user] = useContext(UserContext);
  const [movements, setMovements] = useState<Array<RiskHistory>>([]);
  const [pickedRisk, setPickedRisk] = useContext(RiskContext);
  const [shareRiskMatrixModal, setShareRiskMatrixModal] = useState(false);
  const [exportRiskMatrixModal, setExportRiskMatrixModal] = useState(false);
  const [modalProps, setModalProps] = useContext(ModalContext);
  const participantService = useContext(ServiceContext).participantService;
  const [isShareIconVisible, setShareIconVisible] = useState(null);

  const checkpermission = () => {
    if (user.subscriptionType === "BASIC" || user.subscriptionType === "BUSINESS") {
      setModalProps({
        ...modalProps,
        errorModal: {
          show: true,
        },
      });
      return false;
    }

    return true;
  };

  const shareIconClick = () => {
    if (checkpermission()) {
      setShareRiskMatrixModal(true);
    }
  };

  const openExportPopup = () => {
    if (checkpermission()) {
      setExportRiskMatrixModal(true);
    }
  };

  const shareIconVisible = (memberList) => {
    let allowed: boolean = true;

    memberList.forEach((member) => {
      if ((member.user && member.user.email === user.email) || member.email === user.email) {
        allowed = member.projectRole === "Project Admin" || member.projectRole === "Project Manager";
      }
    });

    return allowed;
  };

  const checkifShareIconVisible = () => {
    return isShareIconVisible;
  };

  useEffect(() => {
    const getMembers = () => {
      if (project && project.id) {
        participantService
          .participantsByProjectId(project.id)
          .then((response) => {
            const share = shareIconVisible(response);

            if (share) {
              setShareIconVisible(share);
            }
          })
          .catch((err: ResponseError) => logError(err));
      }
    };
    getMembers();
  }, [project]);

  useEffect(() => {
    const accumulateHistoryPerDay = () => {
      setMovements([]);
      setPickedRisk([]);
      if (selectedRisks && selectedRisks.length === 1) {
        riskService
          .getFullRiskHistoriesByRiskId(selectedRisks[0].id, {
            limit: 10000,
            offset: 0,
          })
          .then((response) => {
            const uniqueDays: Array<RiskHistory> = response.rows
              .filter((item) => item.activity !== "Risk Voted")
              .reduceRight<Array<RiskHistory>>((acc, cur, index, source) => {
                if (index === source.length - 1) {
                  return acc.concat(cur);
                }
                const last = acc[acc.length - 1];
                const lastDay = DateTime.fromISO(last.createdAt).toFormat("yyyyMd");
                const curDay = DateTime.fromISO(cur.createdAt).toFormat("yyyyMd");
                if (lastDay === curDay && last.changes.probability && last.changes.impact) {
                  return acc;
                }
                return acc.concat(cur);
              }, [])
              .reverse();

            const groupByPI = (
              acc: Array<RiskHistory>,
              cur: RiskHistory,
              index: number,
              source: Array<RiskHistory>
            ) => {
              if (index === source.length - 1) {
                return acc.concat(cur);
              }
              const last = acc[acc.length - 1];
              if (cur.risk.probability === last.risk.probability && cur.risk.impact === last.risk.impact) {
                return acc;
              }
              return acc.concat(cur);
            };

            setMovements(
              uniqueDays
                .reverse()
                .filter((item) => item.changes && item.changes.probability && item.changes.impact)
                .slice(0, 15)
                .reverse()
            );
            setPickedRisk(
              uniqueDays
                .reduceRight<Array<RiskHistory>>(groupByPI, [])
                .slice(0, 15)
                .map((h, index) => ({ ...h, index }))
            );
          })
          .catch((err) => logError(err));
      }
    };

    accumulateHistoryPerDay();
  }, [selectedRisks, riskModal]);

  const handlePick = (data: RiskHistoryIndexed, picked: Array<RiskHistoryIndexed>) => {
    if (!itemInPicked(picked, data)) {
      let temp = [...picked];
      temp.push(data);
      temp.sort((a, b) => (Moment(a.createdAt) > Moment(b.createdAt) ? 1 : -1));
      setPickedRisk(temp);
    } else {
      let filtered = picked.filter((item) => data.index !== item.index);
      setPickedRisk(filtered);
    }
  };

  const itemInPicked = (picked: Array<RiskHistoryIndexed>, item: RiskHistoryIndexed): boolean => {
    for (let i = 0; i < picked.length; i++) {
      if (picked[i].index === item.index) {
        return true;
      }
    }
    return false;
  };

  let radios = movements
    .map((item, index) => ({ ...item, index }))
    .map((item) => {
      const picked = pickedRisk;
      return (
        <FormControlLabel
          // @ts-ignore
          className={classes.formControl}
          value={item.id}
          label={
            <span
              // @ts-ignore
              className={classes.label}
              style={{
                color: itemInPicked(picked, item) ? "#000000" : "#bdbdbd",
              }}
            >
              {Moment(item.createdAt).format("DD/MM")}
            </span>
          }
          labelPlacement="top"
          control={
            <IconButton onClick={() => handlePick(item, picked)}>
              <FiberManualRecordIcon
                className={
                  // @ts-ignore
                  itemInPicked(picked, item) ? classes.picked : classes.unpicked
                }
                style={{ fontSize: "15" }}
              />
            </IconButton>
          }
        />
      );
    });

  return (
    <Box
      // @ts-ignore
      className={classes.root}
      width="90%"
      height="50%"
      display="flex"
    >
      <Box width="90%" height="100%" display="flex" justifyContent="flex-start" alignItems="center">
        {radios}
      </Box>
      {checkifShareIconVisible() ? (
        <Box width="10%" display="flex" justifyContent="space-evenly" alignItems="center">
          <IconButton id="shareProject" onClick={shareIconClick}>
            <ShareOutlinedIcon
              // @ts-ignore
              className={classes.unpicked}
            />
          </IconButton>

          <IconButton id="exportProject" onClick={openExportPopup}>
            <CollectionsOutlinedIcon
              // @ts-ignore
              className={classes.unpicked}
            />
          </IconButton>
        </Box>
      ) : null}
      <ShareRiskMatrixModal
        project={project}
        projectRisks={projectRisks}
        shareRiskMatrixModal={shareRiskMatrixModal}
        setShareRiskMatrixModal={setShareRiskMatrixModal}
      />
      <RiskExportModal
        show={exportRiskMatrixModal}
        close={setExportRiskMatrixModal}
        risks={projectRisks}
        toPdf={toPdf}
      ></RiskExportModal>
    </Box>
  );
};

export default RiskMatrixFooter;
