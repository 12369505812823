import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';
import { ModalContext } from '../contexts/ModalContext';
import useLoadingEffect from '../load/useLoadingEffect';
import { SubscriptionType } from './subscriptionType';
import SubscriptionService from '../../services/subscriptionService';
import stepToModal from './stepToModal';
import { ResponseError } from '../../services/grapqhlResponse';
import { SubscriptionWorkflowContext } from './SubscriptionWorkflowContext';
import { SnackbarContext } from '../contexts/SnackbarContext';
import { WorkflowItem } from './workflow';

const DowngradeModal = () => {
    const [modalProps, setModalProps] = useContext(ModalContext);
    const [, setWorkflow] = useContext(SubscriptionWorkflowContext);
    const [, notifyUser] = useContext(SnackbarContext);

    const [loading, callActionWith] = useLoadingEffect(
        (type: SubscriptionType) => new SubscriptionService().getSubscriptionWorkflow(type),
        {
            onSuccess: (type, workflow) => {
                const steps: Array<WorkflowItem> = ['DOWNGRADE', ...workflow.steps, 'CONFIRM'];
                setWorkflow({
                    to: type,
                    type: workflow.type,
                    steps: steps,
                    step: 1,
                    projects: [],
                    participants: []
                });
                setModalProps({
                    ...modalProps,
                    downgradeModal: {
                        ...modalProps.downgradeModal,
                        show: false
                    },
                    [stepToModal(steps[1])]: {
                        show: true,
                    }
                })
            },
            onError: (error: ResponseError) => notifyUser({
                show: true,
                type: "error",
                message: 'Could not open Downgrade Workflow'
            })
        });

    const downgrade = () => {
        callActionWith(modalProps.downgradeModal.downgradeTo);
    }

    return (
        <div>
            <Dialog
                open={modalProps.downgradeModal.show}
                onClose={() => { }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>
                    Downgrade to {modalProps.downgradeModal.downgradeTo}
                </DialogTitle>
                <DialogContent dividers>
                    <DialogContentText id="alert-dialog-description">
                        <Typography gutterBottom>
                            Your new subscription needs to be downgraded to {modalProps.downgradeModal.downgradeTo}.
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} onClick={() => { downgrade() }} color="primary" variant="contained">
                        Downgrade
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default DowngradeModal;