import React, { useState } from "react";
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';

type Props = {
    setSearchTerm: (searchTerm: string) => void
}

const RizkiSearchBox = ({ setSearchTerm }: Props) => {
    const [fieldClean, setFieldClean] = useState(true);
    const [value, setValue] = useState('');

    const searchFor = (value: string) => {
        const format = /[\\\"´´``]/;

        if (format.test(value)) {
            console.log('falsh');
            return false;
        }

        setValue(value);
        setSearchTerm(value);
        setFieldClean(!value);
    }

    return (
        <div>
            <TextField
                id="rizki-search-box-id"
                value={value}
                label="Search"
                onChange={event => searchFor(event.target.value)}
                InputProps={{
                    endAdornment:
                        // @ts-ignore
                        <InputAdornment>
                            {fieldClean
                                ? <SearchIcon
                                    data-testid={'search-box-search-icon-id'}
                                    style={{ cursor: 'default' }}
                                />
                                : <ClearIcon
                                    data-testid={'search-box-clear-icon-id'}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => searchFor('')}
                                />
                            }
                        </InputAdornment>
                }}>
            </TextField>
        </div >);
}

export default RizkiSearchBox