import React, { useContext } from 'react';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import { SnackbarContext } from '../contexts/SnackbarContext';

const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const RizkiSnackbar = (props) => {
    const [settings, setSettings] = useContext(SnackbarContext);
    const { show, type, message } = settings;

    const closeSnackBar = (event, reason) => {
        console.log('closeSnackbar', event, reason);
        if (reason === 'clickaway') {
            return;
        }
        setSettings({ ...settings, show: false });
    };

    return (
        <Snackbar open={show} autoHideDuration={4000} onClose={closeSnackBar}>
            <Alert id="alert-message" severity={type}>
                {message}
            </Alert>
        </Snackbar>
    )
}

export default RizkiSnackbar;