import { FieldError } from "react-hook-form";

const getErrorMessage = (error: FieldError, fieldName: String, errors?): string => {
    if (error == null) {
        return '';
    }

    return error.type === 'required' && (error.message != null || error.message.trim() != "")
        ? `${fieldName} is required`
        : error.message.toString();
}

export default getErrorMessage;