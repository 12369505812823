import React, { useEffect, useState } from "react";
import { load } from "./config";
import { Configuration } from "./configuration";

export default function ConfigLoader(props) {

    const [config, setConfig] = useState<{ conf?: Configuration, loaded: boolean }>({ loaded: false });

    useEffect(() => {
        load().then(c => setConfig({ conf: c, loaded: true }));
    }, []);

    if (config.loaded) {
        return props.ready(config);
    } else {
        return null;
    }
}