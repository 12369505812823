import { User } from "./contexts/user";
import { SubscriptionType } from "./Subscription/subscriptionType";

const checkSubscriptionExpired = (
    user: User,
    buySubscription: () => void,
    downgradeSubscription: (downgradeTo: SubscriptionType) => void) => {
    if (!user.subscriptionValidUntil) {
        return;
    }
    if (user.subscriptionValidUntil.getTime() < new Date().getTime() && user.downgradeTo) {
        downgradeSubscription(user.downgradeTo);
    } else if (user.subscriptionValidUntil.getTime() < new Date().getTime()) {
        buySubscription();
    }

}

export default checkSubscriptionExpired;