import React, { useState, useEffect, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import { useHistory } from "react-router-dom";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

import { ProjectService } from "../services/projectService";
import { RiskService } from "../services/riskService";
import { ParticipantService } from "../services/participantService";

import { RiskContext } from "./contexts/RiskContext";
import { RiskExportContext } from "./contexts/RiskExportContext";
import { SnackbarContext } from "./contexts/SnackbarContext";

import RiskList from "./riskList";
import RiskDetailModal from "./RiskDetails/riskDetail";
import RiskMatrix from "./RiskMatrix/RiskMatrix";
import RiskMatrixFooter from "./RiskMatrix/RiskMatrixFooter";
import RiskExportPage from "./RiskExport/RiskExportPage";

import config from "../config/config";
import riskExportConfig from "./RiskExport/config";
import { RizkiHistory } from "./history/rizkiHistory";
import { Project } from "./project/project";
import { ResponseError, handleError, logError } from "../services/grapqhlResponse";
import { UserContext } from "./contexts/UserContext";

import hash from "object-hash";

export default function ProjectRisks() {
  const history = useHistory<RizkiHistory>();
  let projectId = null;
  if (history.location.state) {
    const state = history.location.state;
    const source = state.source;
    if (source === config.source.projectList) {
      projectId = history.location.state.project.id;
    }
  }

  const [exportData] = useContext(RiskExportContext);
  const [pickedRisk, setPickedRisk] = useContext(RiskContext);
  const [hidden, setHidden] = useState(true);

  const [project, setProject] = useState<Partial<Project>>({});
  const [participants, setParticipants] = useState([]);
  const [projectRisks, setProjectRisks] = useState([]);
  const [userRole, setUserRole] = useState("Project Member");

  const [riskModal, setRiskModal] = useState(false);
  const [riskDetailModalOpen, setRiskDetailModalOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [selectedRisks, setSelectedRisks] = useState([]);
  const [selectIndex, setSelectIndex] = useState();
  const [, notifyUser] = useContext(SnackbarContext);
  const [user] = useContext(UserContext);
  const [, setProjectHash] = useState(null);

  const projectService = new ProjectService();
  const participantService = new ParticipantService();
  const riskService = new RiskService();

  const numberOfRisksPerPage = 18;

  const getRisksByProjectId = (projectId) => {
    riskService
      .getRisksByProjectId(projectId)
      .then((projects) => {
        const newHash = hash(projects);
        setProjectHash((prevHash) => {
          if (prevHash !== newHash) {
            setProjectRisks(projects);
          }
          return newHash;
        });
      })
      .catch((err: ResponseError) => {
        handleError(err, history, config, notifyUser);
      });
  };

  useEffect(() => {
    const getProject = () => {
      getRisksByProjectId(projectId);
    };
    getProject();
  }, [riskModal, showDeleteModal]);

  useEffect(() => {
    let timer = null;
    const triggerAutoRefresh = () => {
      if (!riskModal && !riskDetailModalOpen) {
        timer = setInterval(async () => {
          if (!riskModal && !riskDetailModalOpen) {
            getRisksByProjectId(projectId);
          }
        }, config.autoRefreshInterval);
      }
    };

    triggerAutoRefresh();

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [riskModal, riskDetailModalOpen]);

  useEffect(() => {
    projectRisks.forEach((risk) => {
      if (selectedRisks && selectedRisks.length < 2) {
        if (selectedRisks.length === 1 && selectedRisks[0].id === risk.id) {
          setSelectedRisks([risk]);
        }
      } else if (selectedRisks) {
        const index = selectedRisks && selectedRisks.findIndex((r) => r.id === risk.id);
        if (selectedRisks && index > -1) {
          selectedRisks[index] = risk;
          const newSelectedRisks = [];
          [].push.apply(newSelectedRisks, selectedRisks);
          setSelectedRisks(newSelectedRisks);
        }
      }
    });
  }, [projectRisks]);

  useEffect(() => {
    const setUserRoleInProject = (project, participants) => {
      if (project.projectManager.toString() === user.user_id.toString()) {
        setUserRole("Project Manager");
      } else {
        const participant = participants.filter((participant) => participant.userId === user.user_id.toString());
        participant && participant.length > 0 && setUserRole(participant[0].projectRole);
      }
    };

    Promise.all([projectService.getProjectById(projectId), participantService.participantsByProjectId(projectId)])
      .then(([projectRes, participantRes]) => {
        setProject(projectRes);
        setParticipants(participantRes);
        setUserRoleInProject(projectRes, participantRes);
      })
      .catch((error: ResponseError) => logError(error));

    setPickedRisk([]);
  }, [projectId]);

  useEffect(() => {
    const exportPdf = () => {
      var svgElements = document.body.getElementsByClassName("exportSVG") as HTMLCollectionOf<SVGElement>;
      for (var i = 0; i < svgElements.length; i++) {
        let item = svgElements[i];
        item.setAttribute("width", item.getBoundingClientRect().width.toString());
        item.setAttribute("height", item.getBoundingClientRect().height.toString());
        item.style.width = null;
        item.style.height = null;
      }

      let inputs = [];
      const numberOfPages = Math.ceil(projectRisks.length / numberOfRisksPerPage);
      for (let i = 0; i < numberOfPages; i++) {
        const id = riskExportConfig.exportComponentIdPrefix + (i + 1);
        inputs.push(document.getElementById(id));
      }

      const pdf = new jsPDF(riskExportConfig.jsPdfOptions);
      let genCanvasPromises = [];
      for (let i = 0; i < inputs.length; i++) {
        genCanvasPromises.push(
          html2canvas(inputs[i], { allowTaint: true, useCORS: true }).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            const width = pdf.internal.pageSize.getWidth();
            const height = pdf.internal.pageSize.getHeight();
            pdf.addImage(imgData, "JPEG", 0, 0, width, height);
            if (i < inputs.length - 1) {
              pdf.addPage();
            }
          })
        );
      }
      Promise.all(genCanvasPromises)
        .then((response) => {
          // QUESTION @Baris Should we add date to filename?
          pdf.save(riskExportConfig.pdfFileNamePrefix + project.name + ".pdf");
          setHidden(true);
        })
        .catch((err) => {
          console.log("err", err);
        });
    };

    if (!hidden) {
      setTimeout(() => exportPdf(), 1000);
    }
  }, [hidden]);

  const startExportingPDF = () => {
    setHidden(false);
  };

  return (
    <Box height="95vh" width="100%" display="flex" position="relative" overflow="hidden">
      <Hidden only={["sm", "xs"]}>
        <Grid id="1" item md={9} lg={9}>
          <Box
            bgcolor="#f3f3f3"
            width="100%"
            height="82%"
            display="flex"
            flexWrap="wrap"
            justifyContent="center"
            alignItems="center"
          >
            <RiskMatrix risks={projectRisks} selectedRisks={selectedRisks} includeRiskIdToExport={false} />
          </Box>
          <Box
            bgcolor="whitesmoke"
            width="100%"
            height="18%"
            display="flex"
            justifyContent="center"
            alignItems="flex-end"
          >
            <RiskMatrixFooter
              selectedRisks={selectedRisks}
              riskModal={riskModal}
              project={project}
              projectRisks={projectRisks}
              toPdf={startExportingPDF}
            />
          </Box>
        </Grid>
      </Hidden>

      <Grid id="2" item xs={12} sm={12} md={3} lg={3}>
        <RiskList
          userRole={userRole}
          riskList={projectRisks}
          projectId={project.id}
          selectedRisks={selectedRisks}
          setSelectedRisks={setSelectedRisks}
          setRiskDetailModalOpen={setRiskDetailModalOpen}
          riskModal={riskModal}
          setRiskModal={setRiskModal}
          setSelectIndex={setSelectIndex}
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
        />
      </Grid>

      <RiskDetailModal
        openRiskDetailModal={riskDetailModalOpen}
        setRiskDetailModalOpen={setRiskDetailModalOpen}
        risk={selectedRisks.length === 1 ? selectedRisks[0] : null}
        setSelectIndex={setSelectIndex}
        projectId={project.id}
      />
      {!hidden ? (
        <>
          <RiskExportPage
            //@ts-ignore
            zIndex={-2}
            pickedRiskHistory={!!pickedRisk && pickedRisk.length > 0 ? pickedRisk : exportData.history}
            project={project}
            allRisks={projectRisks}
            selectedRisks={
              !!selectedRisks && selectedRisks.length > 0 ? selectedRisks : exportData.selectedRiskToExport
            }
            exportNote={exportData.note}
            includeRiskIdToExport={exportData.includeRiskIdToExport}
            numberOfRisksPerPage={numberOfRisksPerPage}
          ></RiskExportPage>
        </>
      ) : null}
    </Box>
  );
}
