import React from "react";
import { Box } from "@material-ui/core";
import RiskListNew from "../RiskOverview/RiskListNew";
import Button from "@material-ui/core/Button";

const colorMatrix = [
  ["#57aa5f", "#8fab45", "#bab03e", "#dfaf3d", "#db9238"],
  ["#73a74b", "#9ead41", "#cdb13e", "#dea43a", "#da8836"],
  ["#85a947", "#b3b03f", "#e0b43e", "#dd9939", "#d97e36"],
  ["#95ad44", "#c1b13e", "#dfaa3d", "#db8d37", "#d87335"],
  ["#a7af41", "#d4b23d", "#dda03a", "#d98335", "#dd5b37"],
];

const ShareRiskList = ({ riskList, selectedRisks, setSelectedRisks, setPickedRisk }) => {
  const clearSelection = () => {
    setPickedRisk([]);
    setSelectedRisks([]);
  };

  return (
    <Box height="100%" width="100%" display="flex" flexDirection="column">
      <Box height="100%" width="100%" overflow="auto">
        <RiskListNew riskList={riskList} selectedRisks={selectedRisks} setSelectedRisks={setSelectedRisks} />
      </Box>
      <Box
        bgcolor="whitesmoke"
        height="30%"
        width="100%"
        display="flex"
        flexDirection="column"
        overflow="auto"
        justifyContent="space-between"
      >
        {selectedRisks.length > 0 ? (
          <Box paddingLeft={4} paddingRight={4} p={1}>
            <Button variant="contained" fullWidth onClick={clearSelection} color="primary">
              Clear Selection
            </Button>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default ShareRiskList;
