import { useState } from 'react';
import { TokenProvider } from './tokenProvider';
import { User } from '../components/contexts/user';

export const AuthProvider = () => {

    const tokenProvider = TokenProvider();

    const login = (newTokens) => {
        tokenProvider.setToken(newTokens);
    };

    const logout = () => {
        tokenProvider.setToken(null);
    };

    const authFetch = async (input, init) => {
        const token = await tokenProvider.getToken();

        init = init || {};

        init.headers = {
            ...init.headers,
            Authorization: `Bearer ${token}`,
        };

        return fetch(input, init);
    };

    const getUserInfo = (): User => { return tokenProvider.getUserInfo(); };

    const getUserMail = () => { return tokenProvider.getUserInfo().email; };

    const isSubscriptionExpired = () => {
        return tokenProvider.isSubscriptionExpired();
    }

    const useAuth = () => {
        const [isLogged, setIsLogged] = useState(tokenProvider.isLoggedIn());
        const [isExpired, setIsExpired] = useState(tokenProvider.isSubscriptionExpired());
        /*
        useEffect(() => {
            const listener = (newIsLogged) => {
                setIsLogged(newIsLogged);
            };

            tokenProvider.subscribe(listener);
            return () => {
                tokenProvider.unsubscribe(listener);
            };
        }, []);
        */
        return [isLogged, isExpired];
    };



    return {
        useAuth,
        authFetch,
        login,
        logout,
        getUserInfo,
        getUserMail,
        isSubscriptionExpired,
        // refreshToken
    }
};

export const { useAuth, authFetch, login, logout, getUserInfo, getUserMail, isSubscriptionExpired } = AuthProvider();