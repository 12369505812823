import React, { useContext, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';

import PersonAddIcon from '@material-ui/icons/PersonAdd';
import DeleteIcon from '@material-ui/icons/Delete';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { Control, Controller, useFieldArray } from 'react-hook-form';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core';
import required from '../../utils/form/validators/isRequired';
import { Participant } from './participant';
import { UserContext } from '../contexts/UserContext';
import getErrorFromFieldArray from '../../utils/form/getErrorFromFieldArray';
import getErrorMessage from '../../utils/form/getErrorMessage';
import ConfirmationDialog from '../dialog/confirmationDialog';
import noDuplicates from './noDuplicates';
import { ParticipantService } from '../../services/participantService';
import { createError, logError } from '../../services/grapqhlResponse';

const useStyles = makeStyles({
    root: {
        "&:hover": {
            backgroundColor: "transparent"
        }
    },
    content: {
        marginTop: "1vh"
    },
    infoMessage: {
        marginBottom: "2vh"
    }
});

type Props = {
    projectId: string,
    alreadyAddedParticipants: Array<Participant>,
    parLength?: Number,
    focusEmail?: boolean,
    handleSubmit: any,
    control: Control<any>,
    register: any,
    errors: any
    getValues: any;
}

export default function ParticipantList(
    {
        projectId,
        alreadyAddedParticipants,
        parLength,
        focusEmail = true,
        handleSubmit,
        control,
        register,
        errors,
        getValues,
    }: Props
) {
    const classes = useStyles();
    const { fields, prepend, append, remove, move } = useFieldArray({
        control,
        name: "participants"
    });

    const [user,] = useContext(UserContext);
    const [maximumReached, setMaximumReached] = useState(false);
    const [collaboratorTeam, setcollaboratorTeam] = useState([]);
    const [removeModal, setRemoveModal] = useState({
        show: false,
        index: undefined
    });

    let addMode = false; // can't be a state, change needs to be immediately.
    let initialStateSet;

    const initialState: Participant = {
        key: undefined,
        email: "",
        name: "",
        projectRole: "Project Member",
        status: "new"
    }

    const teamMembersLimitReachedForPrjManager = (projectId: string, participants: Array<any>): Promise<boolean> => {
        return (new ParticipantService).teamMembersLimitReachedForPrjManager(projectId, participants)
            .catch(() => false);
    }

    const isMaximumForProjectReached = (participants: Array<any>): Promise<boolean> => {
        const addedparticipants = participants.filter(par =>
            (par.email !== "" && par.email != undefined) || par.user && par.user.email !== "");

        return teamMembersLimitReachedForPrjManager(projectId, addedparticipants)
            .catch(() => false);
    }

    const setCollaboratorTeam = (projectId: string) => {
        return (new ParticipantService).collaboratorTeam(projectId)
            .then(team => {
                const mails: any[] = (team.reduce((a, t) => (t && a.push(t.email ? t.email : t.user.email), a), []));
                let unique = mails.filter((v, i) => mails.indexOf(v) === i);
                setcollaboratorTeam(unique);
            }).catch(logError);

    }

    useEffect(() => {
        remove();
        setCollaboratorTeam(projectId);
        if (alreadyAddedParticipants != null && alreadyAddedParticipants.length > 0) {
            const result = isMaximumForProjectReached(alreadyAddedParticipants)
                .then(res => {
                    const sort = (a, b) => {
                        return ((a.email || a.user?.email) > (b.email || b.user?.email)) ? 1 : -1;
                    }

                    const participantsFormArray = alreadyAddedParticipants
                        .slice()
                        .sort((a, b) => sort(a, b))
                        .map(participant => ({
                            key: participant.key,
                            email: participant.email || participant.user?.email,
                            name: participant.name || participant.user?.name,
                            projectRole: participant.projectRole,
                            status: 'existing'
                        }));

                    setMaximumReached(res);

                    if (res) {
                        if (initialStateSet) {
                            remove(0);
                            initialStateSet = false;
                        }
                        append(participantsFormArray);
                    } else if (!res && !initialStateSet) {
                        append([initialState, ...participantsFormArray]);
                        initialStateSet = true;
                    } else if (!res && initialStateSet) {
                        append(participantsFormArray);
                    }
                });
        } else if (alreadyAddedParticipants == null) {
            append(initialState);
            initialStateSet = true;
        }
    }, [parLength]);

    useEffect(() => {
        if (fields.length > 0) {
            isMaximumForProjectReached(fields)
                .then(res => setMaximumReached(res));
        }
    }, [fields]);

    const askForDeletingParticipant = (index: number) => {
        setRemoveModal({
            show: true,
            index
        });
    }

    const onDeleteParticipant = () => {
        remove(removeModal.index);
        if (maximumReached) {
            setMaximumReached(false);
            prepend(initialState);
        }
        setRemoveModal({
            show: false,
            index: undefined
        });
    }

    const addParticipant = (id: string) => (data, e) => {
        
        return isMaximumForProjectReached(data.participants)
            .then(res => {
                if (!collaboratorTeam.includes(data.participants[0].email)) {
                    collaboratorTeam.push(data.participants[0].email);
                }
                fields.filter(field => field.id === id).forEach(field => field.status = "add");
                const findLoc = (newParticipant, participants) => {
                    for (let i = 0; i < participants.length; i++) {
                        if (participants[i].email > newParticipant.email)
                            return i;
                    }
                    return participants.length;
                }

                move(0, findLoc(data.participants[0], data.participants.slice(1)));
                return res;
            })
            .then(res => {
                if (!res) {
                    prepend(initialState);
                }
            })
            .catch(logError);
    }

    const userInTheTeam = (email: string, disabled: boolean): boolean => {
        return maximumReached === false
            || maximumReached === true
            || disabled
            || (maximumReached == null && collaboratorTeam.includes(email));
    }

    const getCollaboratorsInTeam = (): string[] => {
        const collaborators = [...collaboratorTeam];
        fields.map(field => {
            if (!collaboratorTeam.includes(field.email) && field.email !== "") {
                collaborators.push(field.email);
            }
        });
        return collaborators;
    }


    return (
        <>
            <ConfirmationDialog
                show={removeModal.show}
                onCancel={() => setRemoveModal({
                    show: false,
                    index: undefined
                })}
                onConfirmation={onDeleteParticipant}
                title={`Remove ${fields[removeModal.index]?.email || ''}?`}
                confirmationQuestion={"Are you sure you want to remove this participant?"}
            />
            <Grid container className={classes.content} spacing={4}>
                {maximumReached === true
                    ? <div className={classes.infoMessage}>
                        The maximum number of team members for the subscription type of this project has been reached.
                        </div>
                    : maximumReached == undefined
                        ? <div className={classes.infoMessage}>
                            The maximum number of team members for the subscription type of this project has been reached. You can now only add users who are already invited to the project manager's team:
                            <br /><br />
                            <div>{getCollaboratorsInTeam().slice(0, getCollaboratorsInTeam().length - 1).map(email => email + ", ")}
                                {getCollaboratorsInTeam()[getCollaboratorsInTeam().length - 1]}</div>
                        </div>
                        : null}

                {
                    fields
                        .map((field, index) => {
                            const disabled = ['existing', 'add'].includes(field.status);
                            return (
                                <Grid container item spacing={2} key={field.id}>
                                    <Grid item xs={3} >
                                        <Controller
                                            as={
                                                <TextField
                                                    key={field.id}
                                                    disabled={disabled}
                                                    id={`participants[${index}].email`}
                                                    label="Email"
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    error={(errors[`participants[${index}].email`] || getErrorFromFieldArray(errors, { key: 'participants', index: index, name: 'email' })) ? true : false}
                                                    helperText={getErrorMessage(errors[`participants[${index}].email`] || getErrorFromFieldArray(errors, { key: 'participants', index: index, name: 'email' }), 'Email', errors)}
                                                />
                                            }
                                            name={`participants[${index}].email`}
                                            defaultValue={field.email}
                                            rules={
                                                {
                                                    pattern: {
                                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                        message: "Invalid email address"
                                                    },
                                                    validate: {
                                                        notSelf: (val) => (val.toLowerCase() !== user.email.toLowerCase() || disabled) || "You are already in the project",
                                                        notAdded: (val) => noDuplicates(val, fields, disabled),
                                                        required: (val) => {
                                                            if (addMode) {
                                                                addMode = false;
                                                                return required("Email address")(val);
                                                            }
                                                            addMode = false;
                                                            const values = getValues();
                                                            const name = values.participants[index].name;
                                                            const role = values.participants[index].projectRole;
                                                            if (name?.trim() !== ""
                                                                || role !== 'Project Member') {
                                                                return required("Email address")(val);
                                                            }
                                                            return true;
                                                        },
                                                        inCollaboratorTeam: (val) => !val || userInTheTeam(val.toLowerCase(), disabled) || "This user is not in the team"
                                                    }
                                                }
                                            }
                                            control={control} />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Controller
                                            as={
                                                <TextField
                                                    key={field.id}
                                                    disabled={disabled}
                                                    id={`participants[${index}].name`}
                                                    label="Name"
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                />
                                            }
                                            name={`participants[${index}].name`}
                                            defaultValue={field.name}
                                            control={control} />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <Controller
                                            as={
                                                <TextField
                                                    key={field.id}
                                                    disabled={disabled}
                                                    id={`participants[${index}].projectRole`}
                                                    select
                                                    label="Role"
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                >
                                                    <MenuItem
                                                        disabled={disabled}
                                                        value={"Project Member"}
                                                        key="ProjectMember">Project Member</MenuItem>
                                                    <MenuItem
                                                        disabled={disabled}
                                                        value={"Project Admin"}
                                                        key="ProjectAdmin">Project Admin</MenuItem>
                                                </TextField>
                                            }
                                            name={`participants[${index}].projectRole`}
                                            defaultValue={field.projectRole}
                                            control={control} />
                                    </Grid>

                                    <Grid container item xs={1} justify="flex-start">
                                        {disabled
                                            ? <IconButton className={classes.root} onClick={() => askForDeletingParticipant(index)}>
                                                {user.email.toLowerCase() === field.email.toLowerCase() ? null : <DeleteIcon id={'deleteIcon' + index} />}
                                            </IconButton>
                                            : <IconButton
                                                className={classes.root} onClick={e => {
                                                    addMode = true;
                                                    try {
                                                        handleSubmit(addParticipant(field.id))(e);
                                                    } catch (e) {
                                                        addMode = false;
                                                    }
                                                }
                                                }>
                                                <PersonAddIcon id={"participant-add-icon"} />
                                            </IconButton>
                                        }
                                    </Grid>

                                </Grid>
                            )
                        }
                        )
                }
            </Grid>
        </>
    );
}
