import React from 'react';
import ConfigLoader from './config/ConfigLoader';
import Home from './Home';
import { UserContextProvider } from './components/contexts/UserContext';

function App() {
    return (
        <UserContextProvider>
            <ConfigLoader ready={() => <Home />} />
        </UserContextProvider>
    );
}

export default App;
