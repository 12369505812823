import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { SubscriptionType } from "./subscriptionType";
import { DateTime } from "luxon";

const useStyles = makeStyles((theme) => ({
  buyButton: {
    minWidth: theme.spacing(17),
  },
  currentPlanTag: {
    color: "#337bff",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 700,
    letterSpacing: "0.15px",
    lineHeight: "24px",
    opacity: 0.87,
  },
}));

type Activation = {
  on: Date;
  to: SubscriptionType;
};

type Props = {
  toSubscriptionType: SubscriptionType;
  currentSubscriptionType: SubscriptionType;
  activation?: Activation;
  label: string;
  loading: boolean;
  onActivate: () => void;
};

const ActivateSubscription = ({
  currentSubscriptionType,
  toSubscriptionType,
  activation,
  label,
  loading,
  onActivate,
}: Props) => {
  const classes = useStyles();

  const planText = (
    toSubscriptionType: SubscriptionType,
    activation?: Activation
  ) => {
    if (activation?.to === toSubscriptionType) {
      return "NEXT PLAN";
    }
    return "CURRENT PLAN";
  };

  const downgradeText = (
    toSubscriptionType: SubscriptionType,
    activation?: Activation
  ) => {
    if (activation?.to === toSubscriptionType) {
      return `as of ${DateTime.fromJSDate(activation.on).toLocaleString()}`;
    }
    return "";
  };

  console.log(
    `${toSubscriptionType} ${currentSubscriptionType} ${activation?.to}`
  );

  const planPart = () => (
    <span>
      <div className={classes.currentPlanTag}>
        {planText(toSubscriptionType, activation)}
      </div>
      <div>{downgradeText(toSubscriptionType, activation)}</div>
    </span>
  );

  const actionButton = (disabled?) => (
    <Button
      disabled={loading || disabled}
      className={classes.buyButton}
      variant="contained"
      color="primary"
      onClick={() => onActivate()}
    >
      {label}
    </Button>
  );

  const body = () => {
    if (
      currentSubscriptionType === toSubscriptionType ||
      activation?.to === toSubscriptionType
    ) {
      return planPart();
    } else if (activation?.to != null) {
      return actionButton(true);
    }

    return actionButton();
  };

  return <>{body()}</>;
};

export default ActivateSubscription;
