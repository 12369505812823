import React, { useState, useContext } from "react";
import { Collaborator } from "./collaborator";
import { CollaboratorList } from "./CollaboratorList";
import { Projects } from "./Projects";
import { UserContext } from "../contexts/UserContext";
import { Typography } from "@material-ui/core";
import { ModalContext } from "../contexts/ModalContext";

type CollaboratorsProps = {
    userId: string;
}

export const CollaboratorPage = ({ userId }: CollaboratorsProps) => {
    const [collaborator, setCollaborator] = useState<Collaborator>();
    const [user,] = useContext(UserContext);
    const [refreshCollaboratorList, setRefreshCollaboratorList] = useState<boolean>(false);
    const [modalProps, setModalProps] = useContext(ModalContext);

    const showProjectsFor = (collaborator: Collaborator) => {
        setCollaborator(collaborator);
    }

    const refreshCollaborators = () => {
        setRefreshCollaboratorList(!refreshCollaboratorList);
    }

    const handleSubscription = () => {
        setModalProps({
            ...modalProps,
            subscriptionModal: {
                show: true,
                tab: 2
            }
        });
    }

    return (
        user.subscriptionType === "BASIC"
            ? <Typography gutterBottom>
                Your current subscription type does not include the feature you want to access.
                    Click <span style={{ color: '#0869ff', cursor: "pointer" }} onClick={handleSubscription}>here </span>
                     for further information on our subscription model and your current plan.
                </Typography>
            : <>
                <CollaboratorList
                    userId={userId}
                    editCollaborator={(collaborator) => showProjectsFor(collaborator)}
                    refreshList={refreshCollaboratorList}
                />
                {collaborator != null
                    ? <Projects
                        projectManagerId={userId}
                        collaborator={collaborator}
                        openDialog={collaborator != null}
                        onCloseDialog={() => setCollaborator(null)}
                        refreshCollaborators={refreshCollaborators}
                    />
                    : null
                }
            </>
    );
}