import React, { useState, useEffect, useContext, useCallback } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { ProjectService } from "../../services/projectService";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import ProjectListRow from "./ProjectListRow";
import RizkiSearchBox from "../commons/RizkiSearchBox";
import RizkiLoadingEffect from "../load/RizkiLoadingEffect";
import { SnackbarContext } from "../contexts/SnackbarContext";
import { RizkiHistory } from "../history/rizkiHistory";
import ConfirmationDialog from "../dialog/confirmationDialog";
import { UserContext } from "../contexts/UserContext";
import { Project } from "../project/project";
import useLoadingEffect from "../load/useLoadingEffect";
import { ResponseError, logError } from "../../services/grapqhlResponse";
import { RefreshContext } from "../contexts/RefreshContext";
import { ModalContext } from "../contexts/ModalContext";

export default function ProjectList(props) {
  const [user] = useContext(UserContext);
  const projectService = new ProjectService();
  const history = useHistory<RizkiHistory>();
  const historyState = history.location.state || {};
  const [projectList, setProjectList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [projectToDelete, setProjectToDelete] = useState(null);
  const [, notifyUser] = useContext(SnackbarContext);
  const [refresh] = useContext(RefreshContext);

  const [modalProps, setModalProps] = useContext(ModalContext);
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();

  const [loading, callActionWith] = useLoadingEffect(
    (data: any) => getProjectForUser(data),
    {
      onSuccess: (input, response) => setProjectListOnSuccess(response),
      onError: (error: ResponseError) => handleError(error),
    }
  );

  useEffect(() => {
    const identifier = setTimeout(() => {
      callActionWith(searchTerm);
    }, 333);

    return () => {
      clearTimeout(identifier);
    };
  }, [searchTerm, refresh.refreshProject, user]);

  useEffect(() => {
    const handleHistoryAction = () => {
      if (historyState && historyState.actionType === "show-error-message") {
        notifyUser({
          show: true,
          type: "error",
          message: historyState.message,
        });
        const state = {};
        history.replace({ ...history.location, state });
      }
    };
    handleHistoryAction();
  }, [historyState]);

  const getProjectForUser = (term: any) => {
    return projectService.getProjectForUser(
      user.user_id,
      { offset: 0, limit: 100 },
      term
    );
  };

  useEffect(() => {
    const show = query.get("show");
    if (show === "PaymentDetails") {
      setModalProps({
        ...modalProps,
        subscriptionModal: {
          show: true,
          tab: 4,
        },
      });
    }
  }, [query]);

  const setProjectListOnSuccess = (response: any) => {
    setProjectList(response.rows);
  };

  const handleError = (error: ResponseError) => {
    logError(error);
    setProjectList([]);
  };

  const handleDeleteProject = () => {
    projectService
      .deleteProject(projectToDelete.id)
      .then(() => {
        setProjectList(projectList.filter((p) => p !== projectToDelete));
        setShowDeleteModal(false);
        notifyUser({
          show: true,
          type: "success",
          message: `Project ${
            projectToDelete && projectToDelete.name ? projectToDelete.name : ""
          } successfully deleted`,
        });
      })
      .catch(() => {
        notifyUser({
          show: true,
          type: "error",
          message: `Project ${
            projectToDelete && projectToDelete.name ? projectToDelete.name : ""
          } couldn't be deleted`,
        });
      });
  };

  const onDelete = (project) => {
    setProjectToDelete(project);
    setShowDeleteModal(true);
  };

  projectList.sort((a, b) => (a.name > b.name ? 1 : -1));
  const projects = projectList.map((project: Project) => {
    return (
      <ProjectListRow key={project.id} project={project} onDelete={onDelete} />
    );
  });

  const goNewProject = () => {
    history.push("/newProject");
  };

  return (
    <div>
      <ConfirmationDialog
        show={showDeleteModal}
        title={"Delete Project"}
        confirmationQuestion={`Are you sure you want to delete the project ${
          projectToDelete?.name || ""
        }?`}
        onCancel={() => setShowDeleteModal(false)}
        onConfirmation={handleDeleteProject}
      />
      <Grid container spacing={2} justify="center" alignItems="center">
        {/*
                // @ts-ignore */}
        <Grid item xs={10} padding={"8px"}>
          <Box
            flexWrap="wrap"
            flexDirection="row"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            p={2}
          >
            <Box paddingTop="20px" flexGrow={1}>
              {projectList && projectList.length > 0 ? (
                <Typography component="h1" variant="h4" align="center">
                  Good to have you back, {user.name}!
                </Typography>
              ) : (
                <Typography component="h1" variant="h4" align="center">
                  Welcome to Rizki, {user.name}!
                </Typography>
              )}
            </Box>
            {/*
                        // @ts-ignore */}
            <Box align="flex-end">
              <RizkiSearchBox setSearchTerm={setSearchTerm}></RizkiSearchBox>
            </Box>
          </Box>
        </Grid>
        <Grid
          id="project-list"
          item
          xs={10}
          container
          justify="center"
          alignItems="center"
        >
          {projects && projects.length > 0 ? (
            projects
          ) : loading ? (
            <RizkiLoadingEffect loading={loading} />
          ) : searchTerm ? (
            "No Projects found for your filter"
          ) : (
            ""
          )}
        </Grid>
        <Grid item md={10}>
          <br></br>
          <br></br>
          <Button
            id="createNewProject"
            fullWidth
            variant="contained"
            color="primary"
            aria-label="contained primary button group"
            onClick={goNewProject}
          >
            CREATE NEW PROJECT
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
