import ApolloClient, { gql } from 'apollo-boost';
import config from '../config/config'
import { createError, onResponse } from './grapqhlResponse';
export class RegisterService {

    private readonly client: ApolloClient<any>;
    private readonly url: string;

    constructor() {
        this.url = config.server.apiUrl;
        this.client = new ApolloClient({
            uri: this.url, onError: error => console.log("GraphQL Error: " + JSON.stringify(error))
        });
    }

    changePassword(id, password) {
        const changePassword = gql`
        mutation {
            changePassword (
                uuid: "${id}",
                password: "${password}")
        }`;

        return this.client.mutate<{ changePassword: any }>({
            mutation: changePassword, errorPolicy: 'all'
        }).then(response =>
            onResponse(
                response,
                data => data.changePassword
            ))
            .catch(createError);
    };


    checkPasswordIdValid(id: string) {
        const checkPasswordIdValid = gql`
        query {
            passwordIdValid (uuid: "${id}")
        }`;

        return this.client.query<{ passwordIdValid: any }>({
            query: checkPasswordIdValid, errorPolicy: 'all'
        }).then(response =>
            onResponse(
                response,
                data => data.passwordIdValid
            ))
            .catch(createError);
    };

    register(name, email, password) {
        const register = gql`
        mutation {
          register (
              name: "${name}",
              email: "${email}",
              password: "${password}"
            ) {
            uuid
            user {
              id
            }
          }
        }`;

        return this.client.mutate<{ register: any }>({ mutation: register, errorPolicy: 'all' })
            .then(response => {
                if (response.errors) {
                    if (response.errors[0].extensions.exception.name === "SequelizeUniqueConstraintError") {
                        throw new Error('The email address is already registered');
                    } else {
                        throw new Error(response.errors[0].message);
                    }
                }

                return {
                    error: false,
                    text: 'We have sent you a confirmation email. Please check your inbox.'
                }
            }).catch((error) => {
                if (typeof error[0] !== 'undefined'
                    && error[0].extensions
                    && error[0].extensions.exception
                    && error[0].extensions.exception.name === "SequelizeUniqueConstraintError") {
                    return createError(new Error('The email address is already registered'));
                }

                return createError(error)
            });
    }

    passwordRecovery(email) {
        const PR = gql`
            mutation {
                pr (email: "${email}") {
                uuid
                }
            }`;

        return this.client.mutate<{ pr: any }>({ mutation: PR, errorPolicy: 'all' })
            .then(response => {
                if (response.errors) {
                    if (response.errors[0].message.substring(0, 20) === 'email does not exist') {
                        throw new Error('This email address could not be found. Register for free if you do not have a RIZKI account');
                    } else {
                        throw new Error(response.errors[0].message);
                    }
                }
                return {
                    error: false,
                    text: 'We have sent you a password recovery email. Please check your inbox.'
                }
            }).catch(createError);
    }
};

