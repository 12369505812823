import { makeStyles } from "@material-ui/core/styles";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import React from "react";
import CustomAvatar from "../Avatar/CustomAvatar";
import RiskDot from "../RiskMatrix/RiskDot";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import { userIdentification } from "../Avatar/userIdentification";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%"
  },
  small: {
    display: "inline-flex",
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  nameCell: {
    maxWidth: "90%",
    minWidth: "20%",
    wordWrap: "break-word",
    fontSize: "10px"
  },
}));

const colorMatrix = [
  ["#57aa5f", "#8fab45", "#bab03e", "#dfaf3d", "#db9238"],
  ["#73a74b", "#9ead41", "#cdb13e", "#dea43a", "#da8836"],
  ["#85a947", "#b3b03f", "#e0b43e", "#dd9939", "#d97e36"],
  ["#95ad44", "#c1b13e", "#dfaa3d", "#db8d37", "#d87335"],
  ["#a7af41", "#d4b23d", "#dda03a", "#d98335", "#dd5b37"],
];

const RiskExprotListNew = ({ riskList, selectedRisks, setSelectedRisks, includeRiskIdToExport }) => {
  const classes = useStyles();

  const isSelected = (risk) =>
    selectedRisks.findIndex((r) => r.id === risk.id) !== -1;

  const selectRisk = (risk) => (event) => {
    event.stopPropagation();
    if (event.ctrlKey) {
      setSelectedRisks((s) => {
        if (isSelected(risk)) {
          return s.filter((r) => r.id !== risk.id);
        }
        return s.concat(risk);
      });
      return;
    }
    if (event.shiftKey) {
      setSelectedRisks((s) => {
        const start =
          s.length > 0 ? riskList.findIndex((r) => r.id === s[0].id) : -1;
        const end = riskList.findIndex((r) => r.id === risk.id);
        return start < end
          ? riskList.slice(start, end + 1)
          : riskList.slice(end, start + 1);
      });
      return;
    }
    setSelectedRisks([risk]);
  };

  return (
    <List
      className={classes.root}
      component="div"
      dense={true}
      subheader={
        <>
          <ListSubheader component="div" id="RiskList">
            Risks
          </ListSubheader>
          <Divider variant="fullWidth" component="li" />
        </>
      }
    >
      {riskList.map((risk, index) => (
        <>
          <ListItem
            button
            onClick={selectRisk(risk)}
            selected={isSelected(risk)}
          >
            <ListItemIcon>
              <RiskDot
                dotId={risk.id}
                color={colorMatrix[risk.probability - 1][risk.impact - 1]}
                value={includeRiskIdToExport ? risk.riskNumber?.toString() : ''}
              />
            </ListItemIcon>
            <ListItemText
              className={classes.nameCell}
              primary={risk.name}
              secondary={
                <>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justify="space-between"
                  >
                    <Grid item>
                      P{risk.probability} / I{risk.impact}
                    </Grid>
                    <Grid item>
                      <CustomAvatar
                        className={classes.small}
                        imageId={risk.user.id}
                        tooltip={userIdentification(risk.user)}
                      />
                    </Grid>
                  </Grid>
                </>
              }
            />
          </ListItem>
          {index < riskList.length - 1 && (
            <Divider variant="fullWidth" component="li" />
          )}
        </>
      ))}
    </List>
  );
};

export default RiskExprotListNew;
