export type Page<T> = PageCount<T> | PageInfinity<T>;

export type PageCount<T> = {
    count: number;
    rows: Array<T>
}

export type PageInfinity<T> = PageBefore<T> | PageNext<T>;

export type PageBefore<T> = {
    nextStartingBefore: string | null;
    rows: Array<T>;
}

export type PageNext<T> = {
    nextStartingAfter: string | null;
    rows: Array<T>;
}

export const emptyPageCount = <T>(): PageCount<T> => {
    return {
        count: 0,
        rows: []
    }
}

export const emptyPageInfinity = <T>(): PageInfinity<T> => {
    return {
        nextStartingAfter: null,
        nextStartingBefore: null,
        rows: []
    }
}

export const emptyPageNext = <T>(): PageNext<T> => {
    return {
        nextStartingAfter: null,
        rows: []
    }
}

export const emptyPageBefore = <T>(): PageBefore<T> => {
    return {
        nextStartingBefore: null,
        rows: []
    }
}

export const isPageCount = <T>(page: Page<T>): page is PageCount<T> =>
    'count' in page

export const isPageInfinity = <T>(page: Page<T>): page is PageInfinity<T> =>
    isPageNext(page) || isPageBefore(page)

export const isPageNext = <T>(page: Page<T>): page is PageNext<T> =>
    'nextStartingAfter' in page || !('count' in page)

export const hasNext = <T>(page: Page<T>): boolean =>
    isPageNext(page) && page.nextStartingAfter != null;

export const isPageBefore = <T>(page: Page<T>): page is PageBefore<T> =>
    'nextStartingBefore' in page || !('count' in page)

export const hasBefore = <T>(page: Page<T>): boolean =>
    isPageBefore(page) && page.nextStartingBefore != null;

export const isNext = <T>(page: Page<T>, pageSize?: number) => {
    if (isPageCount(page)) {
        return page.count > pageSize;
    }

    return isPageInfinity(page) && page.rows.length > 0;
}