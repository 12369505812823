import { ApolloClient, gql } from "apollo-boost";
import { Client } from "../../utils/client";
import { PageCount } from "../pagination/page";
import { PagePosition } from "../pagination/pagePosition";
import { Collaborator } from "./collaborator";

export class CollaboratorService {
    private readonly client: ApolloClient<any>;

    constructor() {
        const c = new Client();
        this.client = c.createApolloClient();
    }

    collaborators(page: PagePosition): Promise<PageCount<Collaborator>> {
        const query = gql`
        {
            collaborators(
                limit: ${page.limit},
                offset: ${page.offset},
            ) {
                count,
                rows {
                    id,
                    name,
                    email,
                    type
                }
            }
        }`;

        return new Promise((resolve, reject) => {
            this.client.query(
                {
                    query,
                    errorPolicy: 'all',
                    fetchPolicy: "no-cache"
                }
            ).then(response => {
                if (response.errors) {
                    reject("Collaborators could not be read because of a technical issue");
                } else {
                    resolve(response.data.collaborators);
                }
            }).catch(() => reject("Collaborators could not be read because a technical issue"))
        }
        );

    }
}