import React, {useContext} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { ModalContext } from '../contexts/ModalContext';
import { withStyles } from '@material-ui/core/styles'

const ModalButton = withStyles({
    root: {
      textTransform: 'none',
    },
  })(Button);


const ErrorModal = () => {
    const [modalProps, setModalProps] = useContext(ModalContext);

    const handleCancel = () => {
        setModalProps({
            ...modalProps,
            errorModal:{
                show:false
            },
            subscriptionModal: {
                show: false
            }
        });
  
    };

    const handleSubscription = () => {
        setModalProps({
            ...modalProps,
            errorModal:{
                show:false
            },
            subscriptionModal: {
                show: true,
                tab: 2
            }
        });
    }

    return (
        <div>
            <Dialog
                open={modalProps.errorModal.show}
                onClose={handleCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Info</DialogTitle>
                <DialogContent dividers>
                    <DialogContentText id="alert-dialog-description">
                        <Typography gutterBottom>
                            Your current subscription type does not include the feature you want to access. Click <span style={{color: '#0869ff', cursor: "pointer"}} onClick={handleSubscription}>here</span> for further information on our subscription model and your current plan. 
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <ModalButton onClick={handleCancel} color="primary" variant="outlined">
                        Cancel
                    </ModalButton>
                    <ModalButton onClick={handleSubscription} color="primary" variant="contained">
                        OK, subscribe
                    </ModalButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ErrorModal;