import { JwtToken } from "./jwt-token";

export const formatAmount = (amount) => {
    if (!amount || amount === undefined) {
       return false;
    }
    amount = amount.toString();
    const index = amount.length - 2;
    const firstPart = amount.substring(0, index);
    const secondPart = amount.substring(index);
    amount = firstPart + "," + secondPart;
    return amount.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
}

export const base64ToUtf8 = (value) => {
    return decodeURIComponent(escape(atob(value)));
}

export const tokenToJson = (token: any): JwtToken | null => {
    if (!token) {
        return null;
    }

    return JSON.parse(base64ToUtf8(token.split('.')[1]));
}

