import TextField from '@material-ui/core/TextField';
import React, { Ref, ChangeEvent, Key } from 'react';
import getErrorMessage from '../../utils/form/getErrorMessage';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import getErrorFromFieldArray from '../../utils/form/getErrorFromFieldArray';
import { InputProps } from '@material-ui/core/Input';
import { FieldErrors, FieldValues } from 'react-hook-form';

type Props = {
    keyProp?: Key | null;
    defaultValue?: any,
    name: string,
    arrayLocation?: { key: string, index: number, name: string } // needs to be used for fieldArrays and error messages
    label: string,
    inputRef?: Ref<any>,
    errors: FieldErrors<FieldValues>,
    required?: boolean,
    autoFocus?: boolean,
    isPassword?: boolean,
    disabled?: boolean,
    inputComponent?: any,
    multiline?: boolean,
    rows?: number,
    inputProps?: InputProps['inputProps'],
    onChangeCallBack?: (prop: any) => (event: any) => void
}

export const InputField = (
    {
        keyProp,
        defaultValue,
        name,
        arrayLocation,
        label,
        inputRef,
        errors,
        required = false,
        autoFocus = false,
        isPassword = false,
        disabled = false,
        inputComponent,
        multiline = false,
        rows,
        onChangeCallBack,
        inputProps = {},
    }: Props) => {

    const [, setPassword] = React.useState('');
    const [showPassword, setShowPassword] = React.useState(false);

    const handleChange = (prop) => (event) => {
        setPassword(event.target.value);
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <TextField
            key={keyProp}
            variant="outlined"
            margin="normal"
            fullWidth
            defaultValue={defaultValue}
            required={required}
            disabled={disabled}
            id={name}
            label={label}
            name={name}
            multiline={multiline}
            rows={rows}
            autoFocus={autoFocus}
            inputRef={inputRef}
            error={(errors[name] || getErrorFromFieldArray(errors, arrayLocation)) ? true : false}
            helperText={getErrorMessage(errors[name] || getErrorFromFieldArray(errors, arrayLocation), label, errors)}

            type={showPassword || !isPassword ? 'text' : "password"}
            onChange={onChangeCallBack
                ? onChangeCallBack(name)
                : isPassword
                    ? handleChange(name)
                    : null}
            inputProps={inputProps}    
            InputProps={{
                inputComponent: inputComponent,
                endAdornment:
                    isPassword ? <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                        : null
            }}
        />
    );
}