import React, { createContext, useState } from 'react';
import { RiskHistory } from '../RiskDetails/riskHistory';

export type RiskHistoryIndexed = RiskHistory & { index: number }

type RiskContextType = [Array<RiskHistoryIndexed>, (n: Array<RiskHistoryIndexed>) => void];
const RiskContext = createContext<RiskContextType>([[], () => []]);

const RiskContextProvider = (props) => {
    const [pickedRisk, setPickedRisk] = useState([]);
    

    return(
        <RiskContext.Provider value={[pickedRisk, setPickedRisk]}>
            {props.children}
        </RiskContext.Provider>
    );
}

export { RiskContext, RiskContextProvider };