import React, { useState, useEffect, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { useHistory } from "react-router-dom";
import AvatarImages from "../Avatar/AvatarImages";
import config from "../../config/config";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { Project } from "../project/project";

const useStyles = makeStyles((theme) => ({
  listItem: {
    backgroundColor: "#ffffff",
    margin: "0.75%",
    padding: "16px",
    borderColor: "#E0E0E0",
    border: "1px solid",
  },
  clickable: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  arrowButton: {
    display: "flex",
    flexDirection: "row-reverse",
  },
  projectName: {
    display: "inline-block",
    overflow: "hidden",
    maxWidth: "10vw",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    alignItems: "center",
    color: "#337bff",
  },
  editPen: {
    color: "#337bff",
    "&:hover": {
        cursor: "pointer",
      },
  },
}));
type Props = {
  project: Project;
  onDelete: any;
};
const ProjectListRow = ({ project, onDelete }: Props) => {
  const classes = useStyles();
  const history = useHistory();

  const userRole = project?.projectRole || "Project Member";

  const redirectToProjectRisks = (project) => () => {
    history.push("/project-risks", {
      project: project,
      source: config.source.projectList,
    });
  };

  const handleDeleteProject = (project) => {
    onDelete(project);
  };

  return (
    <ListItem className={classes.listItem} key={project.id} id={project.name}>
      <Grid container direction="row" item xs={4}>
        {userRole !== "Project Member" ? (
          <Grid container item xs={4} justify="center" alignItems="center">
            <EditIcon
              className={classes.editPen}
              fontSize="small"
              onClick={() => {
                history.push("/edit-project", {
                  ...project,
                  actionType: "update-project",
                  source: "project-list",
                });
              }}
            ></EditIcon>
            {userRole === "Project Manager" ? (
              <DeleteIcon
                className={classes.editPen}
                fontSize="small"
                onClick={() => {
                  handleDeleteProject(project);
                }}
              ></DeleteIcon>
            ) : null}
          </Grid>
        ) : (
          <Grid xs={4} />
        )}
        <Grid item xs={7}>
          <ListItemText
            className={classes.clickable}
            primary={project.name}
            secondary={userRole}
            onClick={() => {
              if (userRole !== "Project Member") {
                history.push("/edit-project", {
                  ...project,
                  actionType: "update-project",
                  source: "project-list",
                });
              }
            }}
          ></ListItemText>
        </Grid>
      </Grid>
      <Grid item xs={9}>
        <ListItemIcon>
          <AvatarImages data={project} />
        </ListItemIcon>
      </Grid>

      <Grid className={classes.arrowButton} item xs={1}>
        <ArrowForwardIosIcon
          id={"goToProject:" + project.name}
          className={classes.clickable}
          onClick={redirectToProjectRisks(project)}
        />
      </Grid>
    </ListItem>
  );
};

export default ProjectListRow;
