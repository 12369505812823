import React, { createContext, useState, useEffect } from 'react';
import { User } from './user';
import { AuthService } from '../../services/authService';
import { getUserInfo } from '../../utils/authenticationProvider';

import config from '../../config/config';

type UserContextType = [User, (u: User) => void];

const emptyUser: User = {
    user_id: null,
    name: null,
    email: null,
    role: null,
    is_admin: null,
    subscriptionType: "BASIC",
    subscriptionValidUntil: null,
    downgradeTo: null,
    triedAlready: null,
    free: true,
    constraints: {}
};

const UserContext = createContext<UserContextType>([emptyUser, () => []]);

const UserContextProvider = (props) => {
    const [user, setUser] = useState<User>(getUserInfo());

    useEffect(() => {
        const getUserData = () => {
            new AuthService().refreshToken()
                .then(userInfo => {
                    setUser(userInfo);
                }).catch(err => {
                    console.log("Token couldnt be refreshed: " + err.message)
                });
        }

        setInterval(() => {
            getUserData();
        }, config.jwtUpdateInterval);
    }, []);

    return (
        <UserContext.Provider value={[user, setUser]}>
            {props.children}
        </UserContext.Provider>
    );
}

export { UserContext, UserContextProvider };