import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';
import { ModalContext } from '../contexts/ModalContext';
import { SnackbarContext } from '../contexts/SnackbarContext';
import { SubscriptionWorkflowContext } from './SubscriptionWorkflowContext';
import stepToModal from './stepToModal';
import useLoadingEffect from '../load/useLoadingEffect';
import { TokenProvider } from '../../utils/tokenProvider';
import { UserContext } from '../contexts/UserContext';
import SubscriptionService from '../../services/subscriptionService';

const DowngradeToFreeModal = () => {
    const [, setUser] = useContext(UserContext);
    const [modalProps, setModalProps] = useContext(ModalContext);
    const [workflow, setWorkflow] = useContext(SubscriptionWorkflowContext);
    const [, notifyUser] = useContext(SnackbarContext);

    const updateTokenContext = (token) => {
        const tokenProvider = TokenProvider();
        tokenProvider.setToken(token);
        setUser(tokenProvider.getUserInfo());
    }

    const [loading, callActionWith] = useLoadingEffect(
        (to: any) => new SubscriptionService().downgradeSubscription(to),
        {
            onSuccess: (arg, token) => {
                updateTokenContext(token);
                notifyUser({
                    show: true,
                    type: 'success',
                    message: 'Subsription has been successfully downgraded!'
                });
                setModalProps({
                    ...modalProps,
                    downgradeToFreeModal: {
                        show: false,
                    }
                });
            },
            onError: error => {
                notifyUser({
                    show: true,
                    type: 'error',
                    message: 'Could not downgrade subscription!'
                })
            }
        });

    const downgrade = () => {
        callActionWith(modalProps.downgradeToFreeModal.downgradeTo);
    }

    const goBack = () => {
        const nextStep = workflow.step - 1;
        setWorkflow({
            ...workflow,
            step: nextStep,
        });
        setModalProps({
            ...modalProps,
            downgradeToFreeModal: {
                show: false,
            },
            [stepToModal(workflow.steps[nextStep])]: {
                ...modalProps[stepToModal(workflow.steps[nextStep])],
                show: true,
                tab: 2
            },
        });
    }

    return (
        <div>
            <Dialog
                open={modalProps.downgradeToFreeModal.show}
                onClose={() => { }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>
                    Downgrade to {modalProps.downgradeToFreeModal.downgradeTo}
                </DialogTitle>
                <DialogContent dividers>
                    <DialogContentText id="alert-dialog-description">
                        <Typography gutterBottom>
                            Downgrades your subscription to {modalProps.downgradeToFreeModal.downgradeTo}.
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} onClick={() => { goBack() }} color="primary" variant="outlined">
                        Back
                    </Button>
                    <Button disabled={loading} onClick={() => { downgrade() }} color="primary" variant="contained">
                        Downgrade
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default DowngradeToFreeModal;
