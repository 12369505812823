import React, { useState, useEffect } from 'react'
import { Grid, Typography, makeStyles, Container } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({

    label: {
        opacity: '0.7',
        textTransform: 'uppercase',
        fontSize: '0.9vh',
        lineHeight: '1.5vh',
        color: "#7B7B7B",
        textAlign: "right"
    },
    text: {
        color: "#313131",
        fontFamily: "Roboto",
        fontSize: '1.2vh',
        lineHeight: '1.5vh',
        opacity: '1.2vh',
        textAlign: "left"

    },
    grid: {
        marginBottom: "10px",
        overflow: "hidden",

    },
    mainGrid: {
        marginTop: "40px"
    }

}));

export default function RiskDescription({ risk, selectIndex }) {
    const classes = useStyles();

    return (
        <Container id={"riskDetailsTab"}>
            <Grid container spacing={2}>
                <Grid container item xs={12} sm={6} spacing={3}>
                    <Grid item xs={6} className={classes.grid}>
                        <Typography variant="subtitle1" className={classes.label}>
                            Risk Owner
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.grid}>
                        <Typography variant="subtitle1" className={classes.text} >
                            {risk.user.name}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.grid}>
                        <Typography variant="subtitle1" className={classes.label}>
                            Name
                    </Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.grid} >
                        <Typography variant="subtitle1" className={classes.text} >
                            {risk.name}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.grid}>
                        <Typography variant="subtitle1" className={classes.label}>
                            Short description
                    </Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.grid}>
                        <Typography variant="subtitle1" className={classes.text}>
                            {risk.shortDescription}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.grid}>
                        <Typography variant="subtitle1" className={classes.label}>
                            Detailed Description
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.grid}>
                        <Typography variant="subtitle1" className={classes.text} >
                            {risk.detailedDescription}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.grid}>
                        <Typography variant="subtitle1" className={classes.label}>
                            Owner Voting
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.grid}>
                        <Typography variant="subtitle1" className={classes.text}>
                            P{risk.probability} / I{risk.impact}
                        </Typography>
                    </Grid>
                </Grid>
                
                <Grid container item xs={12} sm={6} spacing={3} >
                    <Grid item xs={6} className={classes.grid}>
                        <Typography variant="subtitle1" className={classes.label}>
                            Risk Number
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.grid}>
                        <Typography variant="subtitle1" align="center" className={classes.text} >
                            {risk.riskNumber}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.grid}>
                        <Typography variant="subtitle1" className={classes.label}>
                            Risk Cost
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.grid}>
                        <Typography variant="subtitle1" className={classes.text}>
                            {risk.cost} {risk.currency}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.grid}>
                        <Typography variant="subtitle1" className={classes.label}>
                            countermeasure
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.grid}>
                        <Typography variant="subtitle1" className={classes.text}>
                            {risk.countermeasure}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.grid}>
                        <Typography variant="subtitle1" className={classes.label} >
                            Risk Type
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.grid}>
                        <Typography variant="subtitle1" className={classes.text}>
                            {risk.riskType === 1 ? "Potential" : "Already occurred"}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.grid}>
                        <Typography variant="subtitle1" className={classes.label}>
                            Trend
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.grid}>
                        <Typography variant="subtitle1" className={classes.text}>
                            {risk.trend === 1 ? "Ascending" : "Falling"}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}