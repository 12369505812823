import { Client } from '../utils/client';
import { ApolloClient, gql } from 'apollo-boost';
import { onResponse, createError } from './grapqhlResponse';

export class RiskVotesService {

  client(): ApolloClient<any> {
    return new Client().createApolloClient();
  }

  getRiskVotesByRiskId(riskId) {
    const getRiskVotesByRiskId = gql`
        query {
          votesByRiskId(riskId: "${riskId}") {
              comment
              id
              impact
              probability
              riskId
              userId
              user {
                name
              }
            }
          }`;

    return this.client().query<{ votesByRiskId: any }>({
      query: getRiskVotesByRiskId,
      errorPolicy: 'all'
    }).then(response =>
      onResponse(
        response,
        data => data.votesByRiskId
      )
    ).catch(createError);
  }

  saveOrUpdateRiskVote(values, riskId, projectId) {
    if (values.riskVoteId !== 0) {
      return this.updateRiskVote(values, projectId);
    }
    return this.saveRiskVote(values, riskId, projectId);
  }

  updateRiskVote(values, projectId) {
    const updateRiskVote = gql`
        mutation {
          updateVote(
              comment: """${values.comment}""",
              probability: ${values.probability},
              impact:${values.impact},
              id:"${values.riskVoteId}",
              projectId: "${projectId}"
            )
          {
            id
          }
        }`;
    return this.client().mutate<{ updateVote: any }>({
      mutation: updateRiskVote,
      errorPolicy: 'all'
    }).then(response =>
      onResponse(
        response,
        data => data.updateVote
      )
    ).catch(createError);
  }

  saveRiskVote(values, riskId, projectId) {
    const createRiskVote = gql`
        mutation {
          createVote(
              riskId: "${riskId}",
              comment: """${values.comment}""",
              impact: ${values.impact},
              probability: ${values.probability}
              projectId: "${projectId}"
            )
            {
              id
            }
        }`;
    return this.client().mutate<{ createVote: any }>({
      mutation: createRiskVote,
      errorPolicy: 'all'
    })
      .then(response =>
        onResponse(
          response,
          data => data.createVote
        )
      ).catch(createError);
  }
}
