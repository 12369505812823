import React, { useState, useContext, useEffect } from "react";
import { makeStyles, Button, Box } from "@material-ui/core";
import RiskModal from "./Risk/RiskModal";
import { RiskContext } from "./contexts/RiskContext";
import { ModalContext } from "./contexts/ModalContext";
import { RiskService } from "../services/riskService";
import ConfirmationDialog from "./dialog/confirmationDialog";
import { logError } from "../services/grapqhlResponse";
import RiskListNew from "./RiskOverview/RiskListNew";

const useStyles = makeStyles((theme) => ({
  root: {},
  buttonStyle: {
    display: "inline-block",
  },
}));

const colorMatrix = [
  ["#57aa5f", "#8fab45", "#bab03e", "#dfaf3d", "#db9238"],
  ["#73a74b", "#9ead41", "#cdb13e", "#dea43a", "#da8836"],
  ["#85a947", "#b3b03f", "#e0b43e", "#dd9939", "#d97e36"],
  ["#95ad44", "#c1b13e", "#dfaa3d", "#db8d37", "#d87335"],
  ["#a7af41", "#d4b23d", "#dda03a", "#d98335", "#dd5b37"],
];

const RiskList = ({
  userRole,
  riskList,
  projectId,
  setSelectedRisks,
  selectedRisks,
  setRiskDetailModalOpen,
  riskModal,
  setRiskModal,
  setSelectIndex,
  showDeleteModal,
  setShowDeleteModal,
}) => {
  const classes = useStyles();

  const [isUserManagerOrAdmin, setIsUserManagerOrAdmin] = useState(false);
  const [, setPickedRisk] = useContext(RiskContext);
  const [modalProps, setModalProps] = useContext(ModalContext);

  const riskService = new RiskService();

  useEffect(() => {
    const isManagerOrAdmin =
      userRole === "Project Manager" || userRole === "Project Admin";
    setIsUserManagerOrAdmin(isManagerOrAdmin);
  }, [userRole]);

  const isSingleSelect = () => selectedRisks.length === 1;

  const viewRiskDetail = () => {
    if (isSingleSelect()) {
      for (let i = 0; i < riskList.length; i++) {
        if (riskList[i].id === selectedRisks[0].id) {
          setSelectIndex(i + 1);
          setRiskDetailModalOpen(true);
          setModalProps({
            ...modalProps,
            riskDetailModal: {
              show: true,
              risk: riskList[i],
              selectIndex: i + 1,
            },
          });
        }
      }
    }
  };

  const editRisk = () => {
    if (isSingleSelect() && projectId) {
      setRiskModal(true);
    }
  };

  const deleteRisk = () => {
    if (isSingleSelect()) {
      riskService
        .deleteRisk(selectedRisks[0].id, projectId)
        .then(() => {
          clearSelection();
          setShowDeleteModal(false);
        })
        .catch((err) => logError(err));
    }
  };

  const handleShowDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const addRisk = () => {
    if (projectId) {
      clearSelection();
      setRiskModal(true);
    }
  };

  const clearSelection = () => {
    setPickedRisk([]);
    setSelectedRisks([]);
  };

  return (
    <Box height="100%" width="100%" display="flex" flexDirection="column">
      <Box height="70%" width="100%" overflow="auto">
        <RiskListNew
          riskList={riskList}
          selectedRisks={selectedRisks}
          setSelectedRisks={setSelectedRisks}
        />
      </Box>
      <Box
        bgcolor="whitesmoke"
        height="30%"
        width="100%"
        display="flex"
        flexDirection="column"
        overflow="auto"
        justifyContent="space-between"
      >
        {selectedRisks.length > 0 ? (
          <Box paddingLeft={4} paddingRight={4} p={1}>
            <Button
              variant="contained"
              fullWidth
              onClick={clearSelection}
              color="primary"
            >
              Clear Selection
            </Button>
          </Box>
        ) : null}
        {isSingleSelect() ? (
          <Box paddingLeft={4} paddingRight={4} p={1}>
            <Button
              variant="contained"
              fullWidth
              onClick={viewRiskDetail}
              color="primary"
            >
              View risk details
            </Button>
          </Box>
        ) : null}
        {isUserManagerOrAdmin && isSingleSelect() ? (
          <Box paddingLeft={4} paddingRight={4} p={1}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={editRisk}
            >
              Edit risk
            </Button>
          </Box>
        ) : null}
        {isUserManagerOrAdmin && isSingleSelect() ? (
          <Box paddingLeft={4} paddingRight={4} p={1}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleShowDeleteModal}
            >
              Delete risk
            </Button>
          </Box>
        ) : null}
        {isUserManagerOrAdmin ? (
          <Box paddingLeft={4} paddingRight={4} p={1}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={addRisk}
            >
              Add Risk
            </Button>
          </Box>
        ) : null}
      </Box>
      <RiskModal
        projectId={projectId}
        riskId={isSingleSelect() ? selectedRisks[0].id : null}
        isFirstRisk={riskList.length > 0}
        riskModal={riskModal}
        setRiskModal={setRiskModal}
      />
      <ConfirmationDialog
        show={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        onConfirmation={deleteRisk}
        title={"Delete Risk"}
        confirmationQuestion={"Are you sure to delete the risk?"}
        confirmationButtonText={"Delete"}
      />
    </Box>
  );
};

export default RiskList;
