import React, { useRef, useContext, useState, useEffect } from "react";
import { Grid, Typography, makeStyles, Button, DialogTitle, useMediaQuery, useTheme } from "@material-ui/core";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import RiskDescription from "./riskDescription";
import RiskVotes from "./riskVotes";
import RiskHistoryList from "./RiskHistoryRisk";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { ModalContext } from "../contexts/ModalContext";
import { RiskService } from "../../services/riskService";

import moment from "moment";
import { handleError, logError } from "../../services/grapqhlResponse";
import { UserContext } from "../contexts/UserContext";
import { isRiskHistory } from "./riskHistory";
import { isConstructorDeclaration } from "typescript";

const useStyles = makeStyles((theme) => ({
  dialogSize: {
    width: "70%",
    minWidth: "70%",
    minHeight: "55vh",
  },
  appTab: {
    "&$appTab": {
      backgroundColor: "transparent !important",
      color: "black",
      display: "inline-block",
    },
  },
  tabList: {
    "&$tabList": {
      display: "inline-block !important",
    },
  },
  riskName: {
    display: "inline-block",
    overflow: "hidden",
    maxWidth: "200px",
    float: "left",
    margin: "auto",
    padding: "1%",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    alignItems: "center",
  },
  date: {
    display: "inline-block",
    overflow: "hidden",
    maxWidth: "200px",
    float: "right",
    margin: "auto",
    padding: "1%",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    alignItems: "center",
  },
}));

export default function RiskDetail({ setSelectIndex, risk, openRiskDetailModal, projectId, setRiskDetailModalOpen }) {
  const classes = useStyles();
  const riskService = new RiskService();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [user] = useContext(UserContext);
  const [value, setValue] = useState("1");
  const [modalProps, setModalProps] = useContext(ModalContext);

  const riskFromModal = isRiskHistory(modalProps?.riskDetailModal?.risk)
    ? {
        ...modalProps?.riskDetailModal?.risk?.risk,
        user: {
            name: modalProps?.riskDetailModal?.risk.user.name
        }
    }
    : modalProps?.riskDetailModal?.risk;

  console.log("riskFrom", riskFromModal);

  useEffect(() => {
    const addViewedUser = () => {
      const tempRisk = modalProps.riskDetailModal.risk;
      console.debug("Adding Viewed User ", user.user_id, tempRisk.id);
      const override = false;
      riskService.addViewedUser(tempRisk.id, override).catch((error) => {
        logError(error);
        tempRisk.changed = false;
      });
    };

    if ((risk && openRiskDetailModal) || modalProps.riskDetailModal.show) {
      addViewedUser();
    }
  }, [openRiskDetailModal, modalProps.riskDetailModal]);

  const changeTab = (event, newValue) => {
    if (newValue === "3") {
      if (user.subscriptionType === "BASIC" || user.subscriptionType === "BUSINESS") {
        setModalProps({
          ...modalProps,
          errorModal: {
            show: true,
          },
        });
      } else {
        setValue(newValue);
      }
    } else {
      if (user.subscriptionType === "BASIC") {
        setModalProps({
          ...modalProps,
          errorModal: {
            show: true,
          },
        });
      } else {
        setValue(newValue);
      }
    }
  };

  const handleClose = (event, reason) => {
    setRiskDetailModalOpen(false);

    if (reason === "backdropClick") {
      return;
    }

    setModalProps({
      ...modalProps,
      riskDetailModal: {
        show: false,
        risk: modalProps.riskDetailModal.risk,
      },
    });
    setValue("1");
    setSelectIndex();
  };

  const saveRef = useRef();

  const header = (
    <>
      <Typography className={classes.riskName}>{riskFromModal ? riskFromModal.name : ""}</Typography>
      {modalProps.riskDetailModal.history ? (
        <Typography className={classes.date}>
          {moment(modalProps.riskDetailModal.history.createdAt).format("YYYY/MM/DD - HH:MM")}
        </Typography>
      ) : null}
      {!modalProps.riskDetailModal.history ? (
        <TabList
          onChange={changeTab}
          centered
          TabIndicatorProps={{ id: "tab-indicator" }}
          aria-label="simple tabs example"
        >
          <Tab label="Description" value="1" />
          <Tab label="Voting" value="2" />
          <Tab label="History" value="3" />
        </TabList>
      ) : null}
    </>
  );

  const body = (
    <Grid container justify="center">
      <Grid container justify="center">
        <TabPanel value="1">
          {riskFromModal ? (
            <RiskDescription
              risk={riskFromModal}
              selectIndex={modalProps.riskDetailModal.selectIndex}
            />
          ) : null}
        </TabPanel>
        {modalProps.riskDetailModal.risk && !modalProps.riskDetailModal.history ? (
          <TabPanel value="2">
            {/*
             // @ts-ignore */}
            <RiskVotes risk={riskFromModal} ref={saveRef} projectId={projectId} />
          </TabPanel>
        ) : null}
        {riskFromModal && !modalProps.riskDetailModal.history ? (
          <TabPanel value="3">
            <RiskHistoryList riskId={riskFromModal && riskFromModal.id} />
          </TabPanel>
        ) : null}
      </Grid>
    </Grid>
  );

  return (
    <Dialog
      classes={{ paper: classes.dialogSize }}
      fullScreen={fullScreen}
      open={modalProps.riskDetailModal.show}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      maxWidth="lg"
    >
      <TabContext value={value}>
        <DialogTitle style={{ paddingBottom: "0px" }}>{header}</DialogTitle>
        <DialogContent dividers>{body}</DialogContent>
      </TabContext>
      <DialogActions>
        {/*
         // @ts-ignore */}
        <Button onClick={handleClose} variant="outlined" color="primary">
          Cancel
        </Button>
        {value === "2" ? (
          <Button
            onClick={() => {
              // @ts-ignore
              saveRef.current?.saveRiskVote();
            }}
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
}
