import { WorkflowItem } from "./workflow";

const stepMap = new Map<WorkflowItem, string>(
    [
        ['SUBSCRIPTION', 'subscriptionModal'],
        ['FREE', 'downgradeToFreeModal'],
        ['DOWNGRADE', 'downgradeModal'],
        ['PROJECT', 'subscriptionProjectSelectModal'],
        ['PARTICIPANTS', 'subscriptionParticipantSelectModal'],
        ['INVOICE_ADDRESS', 'invoiceDetailModal'],
        ['PAYMENT_METHOD', 'paymentModal'],
        ['CONFIRM', 'downgradeConfirmModal']
    ]
);

const stepToModal = (step: WorkflowItem) => {
    return stepMap.get(step);
}

export default stepToModal;