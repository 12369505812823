import React from 'react';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useForm } from 'react-hook-form';
import { RegisterService } from '../services/registerService';
import Copyright from './copyright';
import { Logo } from './logo/logo';
import { ResponseError, logError } from '../services/grapqhlResponse';
import { InputField } from './forms/inputField';
import RizkiLoadingEffect from './load/RizkiLoadingEffect';
import useLoadingEffect from './load/useLoadingEffect';
import fieldsMatch from '../utils/form/validators/fieldsMatch';



const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function Registration() {
    const classes = useStyles();

    const [name, setName] = React.useState('');
    const [, setEmail] = React.useState('');
    const [password1, setPassword1] = React.useState('');
    const [password2, setPassword2] = React.useState('');
    const [agreeToTerms, setAgreeToTerms] = React.useState(false);
    const [, setAcceptMarketing] = React.useState(false);

    const [loading, callActionWith] = useLoadingEffect(
        (data: any) => handleRegistration(data),
        {
            onSuccess: () => notifyOnRegisterSuccess(),
            onError: (error: ResponseError) => handleError(error)
        });

    const [message, setMessage] = React.useState({ error: false, text: '' });
    const { register, handleSubmit, errors, getValues} = useForm();

    const handleRegistration = (data: any) => {
        return new RegisterService().register(data.name, data.email, data.password1);
    };

    const notifyOnRegisterSuccess = () => {
        setMessage({
            error: false,
            text: 'We have sent you a confirmation email. Please check your inbox.'
        });
    };

    const handleError = (error: ResponseError) => {
        setMessage({ error: true, text: error.message });
        logError(error);
    };

    const onSubmit = (data) => {
        if (!agreeToTerms) {
            setMessage({ error: true, text: "You have to accept the Terms in order to use the service." });
        }
        else {
            callActionWith(data);
        }
    }

    const handleChange = (prop) => (event) => {
        if (prop === 'password1') setPassword1(event.target.value);
        if (prop === 'password2') setPassword2(event.target.value);
        if (prop === 'email') setEmail(event.target.value);
        if (prop === 'name') setName(event.target.value);
    };

    const handleAcceptTerms = (event) => {
        setAgreeToTerms(event.target.checked);
    }

    const handleExtraEmail = (event) => {
        setAcceptMarketing(event.target.checked);
    }

    return (
        <Container component="main" maxWidth="xs">
            <div className={classes.paper}>
                <Logo />
                <Typography component="h1" variant="h5">
                    Register
                </Typography>
                <Typography style={{ color: message.error ? 'red' : 'green' }}>
                    <br />
                    {message.text}
                </Typography>
                <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
                    <Grid container spacing={2}>
                        <InputField
                            name="name"
                            required={true}
                            label="First- and Lastname"
                            autoFocus={true}
                            inputRef={register({
                                required: true,
                                pattern: {
                                    value: /^[^\\\"/´`]*$/,
                                    message: 'A name cannot contain these symbols: \\"/´`'
                                }
                            })}
                            errors={errors}
                        />

                        <InputField
                            required={true}
                            label="Email address"
                            name="email"
                            errors={errors}
                            inputRef={register({
                                required: true,
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: "Invalid email address"
                                }
                            })}
                        />

                        <InputField
                            required={true}
                            name="password1"
                            label="Password"
                            isPassword={true}
                            errors={errors}
                            inputRef={register({
                                required: true,
                                pattern: {
                                    value: /^(?=.*\d)(?=.*[A-Za-z])[A-Za-z\d$%&§]{8,20}$/,
                                    message: 'The password must contain 8 - 20 characters and at least one letter and one number. Allowed special characters are  $, %, &, §.'
                                }
                            })}
                            onChangeCallBack={n => handleChange(n)}
                        />

                        <InputField
                            required={true}
                            name="password2"
                            label="Confirm Password"
                            isPassword={true}
                            errors={errors}
                            inputRef={register({
                                required: true,
                                validate: {
                                    passwordsMatch: () =>
                                        fieldsMatch(getValues("password1"), getValues("password2"))
                                        || "Passwords don't match",
                                    validPassword1: () => !errors.password1
                                },
                            })}
                            onChangeCallBack={n => handleChange(n)}
                        />
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        value="acceptTerms"
                                        color="primary"
                                        onChange={handleAcceptTerms}
                                    />
                                }
                                label={
                                    <span>I have read and agree to the <a href="https://www.rizki.at/terms-and-conditions" target="_blank" rel = "noopener noreferrer">terms and conditions</a> and the <a href="https://www.rizki.at/privacy-policy" target="_blank" rel = "noopener noreferrer">privacy policy</a>. *</span>
                                } />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        value="allowExtraEmails"
                                        color="primary"
                                        onChange={handleExtraEmail}
                                    />
                                }
                                label="I want to receive inspiration, marketing promotions and updates via email." />
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit} >
                        Sign Up
                    </Button>
                    <Grid container justify="flex-end">
                        <Grid item>
                            <Link href="/login" variant="body2">
                                Already have an account? Sign in
                            </Link>
                        </Grid>
                    </Grid>
                </form>
                <RizkiLoadingEffect withBackdrop={true} loading={loading} />
            </div>
            <Box mt={5}>
                <Copyright />
            </Box>
        </Container>
    );
}