import ApolloClient from 'apollo-boost';
import config from '../config/config'
import { logout } from './authenticationProvider';

export class Client {

  private getConnectionInfo(): [string, string] {
    const url = config.server.apiUrl;
    let token = localStorage.getItem('userToken');
    if (token) {
      token = token.replace(/['"]+/g, '');
    }
    return [url, token];
  }

  createApolloClient = (): ApolloClient<any> => {
    const [url, token] = this.getConnectionInfo();

    const client = new ApolloClient({
      request: (operation) => {
        operation.setContext({
          headers: {
            authorization: token ? `Bearer ${token}` : ''
          }
        })
      },
      uri: url, onError: (error) => { // check error types like { graphQLErrors, networkError, operation, forward } => for error handling improvements
        console.error("GraphQL Error: " + JSON.stringify(error));

        const { graphQLErrors } = error;
        if (graphQLErrors && graphQLErrors[0].extensions.code === "UNAUTHENTICATED") {
          logout();
          window.location.href = config.server.frontendHost;
        }
      }
    })

    return client;
  };
}