import React, { createContext, useState } from 'react';
import { UserNotification } from './userNotification';

type SnackbarConextType = [UserNotification, (n: UserNotification) => void];
const emptyNotification: UserNotification = {
    show: false,
    type: '',
    message: ''
};
const SnackbarContext = createContext<SnackbarConextType>([emptyNotification, () => []]);

const SnackbarContextProvider = ({children}) => {
    const [settings, setSettings] = useState<UserNotification>(emptyNotification);

    return (
        <SnackbarContext.Provider value={[settings, setSettings]}>
            {children}
        </SnackbarContext.Provider>
    );
}

export { SnackbarContext, SnackbarContextProvider };