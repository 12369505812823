import React, { useState, useContext } from 'react'
import { Grid, Button, Dialog, DialogContent, DialogActions } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { UserService } from '../../services/userService';

import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import imageType from 'image-type';
import EditAvatar from './editAvatar';

import { SnackbarContext } from '../contexts/SnackbarContext';
import { logError } from '../../services/grapqhlResponse';

const maxFileSize = 5 * 1024 * 1024;
const allowedImageTypes = ['image/gif', 'image/jpeg', 'image/png']

function rand() {
    return Math.round(Math.random() * 20) - 10;
}

export default function UploadAvatar(
    {
        openUploadAvatarModal,
        setUploadAvatarModalOpen,
        handleReloadImage,
        user
    }) {

    const [, notifyUser] = useContext(SnackbarContext);

    const userService = new UserService();

    const [croppedImage, setCroppedImage] = useState(null);
    const [uploadedImage, setUploadedImage] = useState(null);

    const handleSaveAvatar = () => {
        userService.addAvatarPhoto(croppedImage)
            .then(() => {
                setUploadedImage(null);
                notifyUser({
                    show: true,
                    type: "success",
                    message: "Avatar uploaded successfully"
                });

                setUploadAvatarModalOpen(false);
                setCroppedImage(null);
                handleReloadImage();
            }).catch(err => {
                logError(err);
                setUploadedImage(null);
                notifyUser({
                    show: true,
                    type: "error",
                    message: "Avatar couldn't be uploaded"
                });

                setUploadAvatarModalOpen(false);
                setCroppedImage(null);
            })
    }

    const clearAvatar = () => {
        setCroppedImage(null);
    }

    const onBeforeFileLoad = async elem => {
        const image = elem.target.files[0];
        if (image.size > maxFileSize) {
            elem.target.value = "";
            notifyUser({
                show: true,
                type: "error",
                message: "Size of the Avatar Image should not be greater than 5MB"
            });
            return;
        }

        if (!allowedImageTypes.includes(image.type)) {
            elem.target.value = "";
            notifyUser({
                show: true,
                type: "error",
                message: "Upload images only with type gif, png, jpg"
            });
            return;
        }

        elem.persist();
        const fileType = await typeOfFile(image);
        if (fileType === null || !allowedImageTypes.includes(fileType.mime)) {
            elem.target.value = "";
            notifyUser({
                show: true,
                type: "error",
                message: "Upload images only with type gif, png, jpg"
            });
            return;
        }
        setUploadedImage(image);
    }

    const typeOfFile = (file: File): Promise<imageType.ImageTypeResult> => {
        return new Promise((resolve, reject) => {
            const read = new FileReader();

            read.readAsArrayBuffer(file);

            read.onerror = () => {
                read.abort();
                resolve(null);
            }

            read.onloadend = function () {
                if (read.result instanceof ArrayBuffer) {
                    resolve(imageType(new Uint8Array(read.result)));
                } else {
                    reject("result is not an ArrayBuffer");
                }
            }
        });
    }

    const handleClose = (event) => {
        // TODO Hans check if reson part of the even
        /*if (reason === 'backdropClick') {
            return;
        }*/
        clearAvatar();
        setUploadAvatarModalOpen(false);
    };

    return (
        <Dialog
            open={openUploadAvatarModal}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <DialogContent>
                <MuiThemeProvider>
                    {/* 
                    // @ts-ignore */}
                    <Grid style={{ height: "100%" }} display="flex" justify="center">
                        <Box width="100%" height="90%" marginTop="3%" display="flex" flexDirection="row" justifyContent="center" >
                            <Grid container style={{ textAlign: "center", justifyContent: "center" }}>
                                <Grid item xs={12} style={{ textAlign: "center", marginBottom: "25px" }}>
                                    <label htmlFor="contained-button-file">
                                        <input
                                            style={{ display: 'none' }}
                                            id="contained-button-file"
                                            name="upload-photo"
                                            type="file"
                                            onChange={onBeforeFileLoad}
                                        />
                                        <Button color="primary" component="span">Upload Avatar</Button>
                                    </label>
                                </Grid>
                                <Grid item xs={12} style={{ textAlign: "center", justifyContent: "center" }}>
                                    <EditAvatar
                                        uploadedImage={uploadedImage}
                                        setCroppedImage={setCroppedImage}>
                                    </EditAvatar>
                                </Grid>
                            </Grid>
                        </Box>
                        <br></br>
                    </Grid>
                </MuiThemeProvider>
            </DialogContent>
            <DialogActions>
                <Button color="primary" variant="outlined" onClick={handleClose}>CANCEL</Button>
                <Button color="primary" variant="contained" onClick={handleSaveAvatar}>SAVE AVATAR</Button>
            </DialogActions>
        </Dialog>
    );
}