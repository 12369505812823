import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useLocation, useHistory } from 'react-router-dom';
import Copyright from './copyright';
import config from '../config/config';
import { ColorTypes } from './materialUi/colorTypes';
import { Logo } from './logo/logo';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function RegisterConfirmation() {
    const classes = useStyles();

    const [text, setText] = React.useState('');
    const [color, setColor] = React.useState<ColorTypes>('initial');

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const history = useHistory();


    useEffect(() => {
        let url = config.server.backendUrl + '/api/confirm/' + params.get('type') + '/' + params.get('id');

        if (params.get('type') === 'r') {
            fetch(url).then(response => {
                if (response.status === 200) {
                    setColor('textPrimary');
                    if (params.get('type') === 'r') setText('Welcome to Rizki. Your Registration is completed. Please Login.');
                } else {
                    setText('Oops! Something went wrong. Please contact support@rizki.at');
                    setColor('error');
                }
            }).catch(error => {
                console.log(error);
                setText('Oops! Something went wrong. Please contact support@rizki.at');
                setColor('error');
            });
        }
    }, []);

    return (
        <>
            <Container component="main" maxWidth="xs">
                <div className={classes.paper}>
                    <Logo />
                    <Typography component="h1" variant="h5" color={color}>
                        {text}
                    </Typography>
                </div>
                <div>
                    <br></br>
                    <br></br>
                    <Button variant="outlined" href="/login">
                        Goto Login
                    </Button>
                </div>
                <Box mt={5}>
                    <Copyright />
                </Box>
            </Container>
        </>
    );

}