import React, { useState, useContext, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { UserService } from '../../services/userService';
import { ModalContext } from '../contexts/ModalContext';
import { invoiceName, invoiceCompanyName, invoiceAddress, invoiceZip, invoiceCity, invoiceVatNumber } from '../../models/invoiceDetails'
import { logError } from '../../services/grapqhlResponse';
import { UserContext } from '../contexts/UserContext';
import { SubscriptionWorkflowContext } from '../Subscription/SubscriptionWorkflowContext';
import stepToModal from '../Subscription/stepToModal';

const useStyles = makeStyles(theme => ({
    root: {
        width: "400px",
        paddingLeft: "7%",
        paddingRight: "7%",
        paddingTop: "7%",
        paddingBottom: "5%",
    },
    dialogContent: {
        display: "flex"
    },
    textStyle: {
        color: "#cc3300",
        fontFamily: "Roboto",
        fontSize: "14px",
        letterSpacing: "1.5px",
        lineHeight: "16px",
        opacity: "0.87",
        textAlign: "center"
    },
    textFieldStyle: {
        width: "100%",
        color: "#7B7B7B",
        fontFamily: "Roboto",
        fontSize: "16px",
        letterSpacing: "-0.15px",
        lineHeight: "24px",
        textAlign: "left"
        // margin: "6px",
    },
}));

const countries = [
    {
        value: "AT",
        label: "Austria"
    },
    {
        value: "DE",
        label: "Germany"
    }
];

const InvoiceDetailModal = () => {
    const classes = useStyles();

    const [modalProps, setModalProps] = useContext(ModalContext);
    const [user,] = useContext(UserContext);
    const [isClicked, setClicked] = useState(false);
    const userService = new UserService();
    const [workflow, setWorkflow] = useContext(SubscriptionWorkflowContext);

    const initialValues = {
        userId: user.user_id,
        name: "",
        companyName: "",
        address: "",
        zip: "",
        city: "",
        country: "AT",
        vatNumber: ""
    }

    const [values, setValues] = useState(initialValues);

    useEffect(() => {
        const getDetails = () => {
            userService.getUserById()
                .then(response => setValues({ ...initialValues, ...response.invoiceDetail }))
                .catch(logError);
        }
        getDetails();
    }, [])

    const areFieldsValid = () => {
        return invoiceName.valid(values.name) &&
            values &&
            invoiceAddress.valid(values.address) &&
            invoiceZip.valid(values.zip) &&
            invoiceCity.valid(values.city)
    }

    const handleSave = () => {
        setClicked(true);
        if (areFieldsValid()) {
            userService.saveUserInvoiceDetails(values)
                .then(() => {
                    const nextStep = workflow.step + 1;
                    setWorkflow({
                        ...workflow,
                        step: nextStep
                    });
                    setModalProps({
                        ...modalProps,
                        invoiceDetailModal: {
                            show: false
                        },
                        [stepToModal(workflow.steps[nextStep])]: {
                            show: true,
                        },
                    });
                }).catch(logError);
        }
    };

    const handleCancel = () => {
        const nextStep = workflow.step - 1;
        setWorkflow({
            ...workflow,
            step: nextStep,
        });
        setModalProps({
            ...modalProps,
            invoiceDetailModal: {
                show: false
            },
            [stepToModal(workflow.steps[nextStep])]: {
                ...modalProps[stepToModal(workflow.steps[nextStep])],
                show: true,
                tab: 2
            },
        });
    };

    const handleChange = event => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });

    }

    return (
        <div>
            <Dialog
                open={modalProps.invoiceDetailModal.show}
                onClose={handleCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="lg"
            >
                <DialogContent className={classes.dialogContent} dividers>
                    <div className={classes.root}>
                        <Grid container direction="column" spacing={1}>
                            <Grid item xs={12} >
                                <Typography className={classes.textStyle} component="h4">
                                    Please fill up the missing fields to proceed!
                                </Typography>
                                <br />
                            </Grid>
                            <Grid item xs={12} >
                                <TextField
                                    className={classes.textFieldStyle}
                                    required
                                    fullWidth
                                    id="name"
                                    name="name"
                                    label="Name"
                                    variant="outlined"
                                    value={values.name}
                                    onChange={handleChange}
                                    helperText={`${values.name.length}/100 Characters`}
                                    error={!invoiceName.valid(values.name) && isClicked}
                                    inputProps={{
                                        maxLength: 100
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} >
                                <TextField
                                    className={classes.textFieldStyle}
                                    fullWidth
                                    id="company-name"
                                    name="companyName"
                                    label="Company Name"
                                    variant="outlined"
                                    multiline
                                    rows={2}
                                    value={values.companyName}
                                    onChange={handleChange}
                                    helperText={`${values.companyName?.length}/200 Characters`}
                                    inputProps={{
                                        maxLength: 200
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} >
                                <TextField
                                    className={classes.textFieldStyle}
                                    fullWidth
                                    required
                                    id="address"
                                    name="address"
                                    label="Address"
                                    variant="outlined"
                                    multiline
                                    rows={2}
                                    value={values.address}
                                    onChange={handleChange}
                                    helperText={`${values.address.length}/200 Characters`}
                                    error={!invoiceAddress.valid(values.address) && isClicked}
                                    inputProps={{
                                        maxLength: 200
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} >
                                <TextField
                                    className={classes.textFieldStyle}
                                    fullWidth
                                    required
                                    id="zip"
                                    name="zip"
                                    label="Zip Code"
                                    variant="outlined"
                                    value={values.zip}
                                    onChange={handleChange}
                                    helperText={`${values.zip.length}/15 Characters`}
                                    error={!invoiceZip.valid(values.zip) && isClicked}
                                    inputProps={{
                                        maxLength: 15
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} >
                                <TextField
                                    className={classes.textFieldStyle}
                                    fullWidth
                                    required
                                    id="city"
                                    name="city"
                                    label="City"
                                    variant="outlined"
                                    value={values.city}
                                    onChange={handleChange}
                                    helperText={`${values.city.length}/100 Characters`}
                                    error={!invoiceCity.valid(values.city) && isClicked}
                                    inputProps={{
                                        maxLength: 100
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    className={classes.textFieldStyle}
                                    fullWidth
                                    required
                                    id="country"
                                    name="country"
                                    label="Country"
                                    select
                                    variant="outlined"
                                    value={values.country}
                                    onChange={handleChange}
                                    helperText={` `}
                                >
                                    {countries.map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} >
                                <TextField
                                    className={classes.textFieldStyle}
                                    fullWidth
                                    id="vatNumber"
                                    name="vatNumber"
                                    label="VAT Number"
                                    variant="outlined"
                                    value={values.vatNumber}
                                    onChange={handleChange}
                                    helperText={`${values.vatNumber.length}/20 Characters`}
                                    inputProps={{
                                        maxLength: 20
                                    }}
                                />
                            </Grid>

                        </Grid>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} color="primary" variant="outlined">
                        Cancel
                    </Button>
                    <Button onClick={handleSave} color="primary" variant="contained">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default InvoiceDetailModal;