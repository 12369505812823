import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';
import { ModalContext } from '../contexts/ModalContext';
import { SubscriptionWorkflowContext } from './SubscriptionWorkflowContext';
import stepToModal from './stepToModal';
import useLoadingEffect from '../load/useLoadingEffect';
import SubscriptionService from '../../services/subscriptionService';
import { ResponseError } from '../../services/grapqhlResponse';
import { UserContext } from '../contexts/UserContext';
import { TokenProvider } from '../../utils/tokenProvider';
import RizkiLoadingEffect from '../load/RizkiLoadingEffect';
import { SnackbarContext } from '../contexts/SnackbarContext';

const DowngradeConfirmModal = () => {
    const [modalProps, setModalProps] = useContext(ModalContext);
    const [workflow, setWorkflow] = useContext(SubscriptionWorkflowContext);
    const [, setUser] = useContext(UserContext);
    const [, notifyUser] = useContext(SnackbarContext);

    const updateTokenContext = (token) => {
        const tokenProvider = TokenProvider();
        tokenProvider.setToken(token);
        setUser(tokenProvider.getUserInfo());
    }

    const [loading, callActionWith] = useLoadingEffect<{ projects: Array<string>, participants: Array<string> }, string>(
        data => new SubscriptionService().confirmDowngradeSubscription(data.projects, data.participants),
        {
            onSuccess: (arg, result) => {
                updateTokenContext(result);
                setModalProps({
                    ...modalProps,
                    downgradeConfirmModal: {
                        show: false,
                    },
                    subscriptionExpiredModal: {
                        show: false,
                    }
                });
            },
            onError: (error: ResponseError) => notifyUser({
                show: true,
                type: 'error',
                message: "Subscription could not be confirmed successfully."
            })
        });

    const goBack = () => {
        const nextStep = workflow.step - 1;
        setWorkflow({
            ...workflow,
            step: nextStep,
        });
        setModalProps({
            ...modalProps,
            downgradeConfirmModal: {
                show: false,
            },
            [stepToModal(workflow.steps[nextStep])]: {
                ...modalProps[stepToModal(workflow.steps[nextStep])],
                show: true,
                tab: 2
            }
        });
    }

    const downgrade = () => {
        callActionWith({ projects: workflow.projects, participants: workflow.participants });
    }

    return (
        <div>
            <Dialog
                open={modalProps.downgradeConfirmModal.show}
                onClose={() => { }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>
                    Downgrade to {workflow?.to}
                </DialogTitle>
                <DialogContent dividers>
                    <DialogContentText id="alert-dialog-description">
                        <Typography gutterBottom>
                            Please confirm the downgrade to {workflow?.to}.
                        </Typography>
                    </DialogContentText>
                    <div style={{
                        position: 'absolute', left: '50%', top: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}>
                        <RizkiLoadingEffect loading={loading} />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} onClick={() => { goBack() }} color="primary" variant="outlined">
                        Back
                    </Button>
                    <Button disabled={loading} onClick={() => { downgrade() }} color="primary" variant="contained">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default DowngradeConfirmModal;