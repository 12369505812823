import { Client } from '../utils/client';
import { ApolloClient, gql } from 'apollo-boost';
import config from '../config/config'
import { onResponse, createError } from './grapqhlResponse';
import { TokenProvider } from '../utils/tokenProvider';

type InvoiceValues = {
	userId: string;
	name: string;
	companyName: string;
	address: string;
	zip: string;
	city: string;
	country: string;
	vatNumber: string;
}

export class UserService {

	private readonly tokenProvider = TokenProvider();

	//TODO @Baris - discuss this with Hans. Moving it to utils?
	private getConnectionInfo(): string {
		let token = localStorage.getItem('userToken');
		if (token) {
			token = token.replace(/['"]+/g, '');
		}
		return token;
	}

	client(): ApolloClient<any> {
		return new Client().createApolloClient();
	}

	getUserByEmail(email) {
		const getUserByMailQuery = gql`
            query {
              userByEmail(email: "${email}") {
                id,
                name,
                email
              }
            }`;

		return this.client().query<{ userByEmail: any }>({
			query: getUserByMailQuery,
			errorPolicy: 'all',
			fetchPolicy: "no-cache"
		}).then(response =>
			onResponse(
				response,
				data => data.userByEmail
			)
		).catch(createError);
	}

	getUserById() {
		const getUserByIDQuery = gql`
        query {
          	userById {
				name
				password
				photoUrl
				email
				invoiceDetail {
					id
					name
					companyName
					address
					zip
					city
					country
					vatNumber
				}
          	}
        }`;

		return this.client().query<{ userById: any }>({
			query: getUserByIDQuery,
			errorPolicy: 'all',
			fetchPolicy: "no-cache"
		}).then(response =>
			onResponse(
				response,
				data => data.userById
			)
		).catch(createError);
	}

	getInvoiceDetails() {
		const getUserByIDQuery = gql`
        query {
			getInvoiceDetails
        }`;

		return this.client().query<{ getInvoiceDetails: any }>({
			query: getUserByIDQuery,
			errorPolicy: 'all',
			fetchPolicy: "no-cache"
		}).then(response =>
			onResponse(
				response,
				data => data.getInvoiceDetails
			)
		).catch(createError);
	}

	fetchUserPaymentMethod() {
		const getUserPaymentMethodQuery = gql`
        query {
          	userPaymentMethod
        }`;

		return this.client().query<{ userPaymentMethod: any }>({
			query: getUserPaymentMethodQuery,
			errorPolicy: 'all',
		}).then(response =>
			onResponse(
				response,
				data => data.userPaymentMethod
			)
		).catch(createError);
	}

	updateProfilWithPassword(
		data: { userName: string, oldPassword: string, newPassword: string, photoUrl: string }) {
		const updatUserMutation = gql`
        mutation {
			updateProfilWithPassword(
				name: "${data.userName}",
				oldPassword: "${data.oldPassword}",
				newPassword: "${data.newPassword}",
				photoUrl: "${data.photoUrl}"
          )
        }`;

		return this.client().mutate<{ updateProfilWithPassword: any }>({
			mutation: updatUserMutation,
			errorPolicy: 'all',
		}).then(response => {
			onResponse(
				response,
				data => data.updateProfilWithPassword
			)
		}).catch(createError);
	}

	updateProfilWithOutPassword(data) {
		const updatUserMutation = gql`
        mutation {
			updateProfilWithOutPassword(
				name: "${data.userName}",
				photoUrl: "${data.photoUrl}"
			)
		  }`;

		return this.client().mutate<{ updateProfilWithOutPassword: any }>({
			mutation: updatUserMutation,
			errorPolicy: 'all',
		}).then(response => {
			onResponse(
				response,
				data => data.updateProfilWithOutPassword
			)
		}).catch(createError);
	}


	updatePaymentDetails(paymentMethod, token, name) {
		const updatePaymentDetails = gql`
        mutation {
			updatePaymentDetails(
				paymentMethod: "${paymentMethod}",
				token: "${token}",
				name: "${name}"
			)
		  }`;

		return this.client().mutate<{ updatePaymentDetails: any }>({
			mutation: updatePaymentDetails,
			errorPolicy: 'all',
		}).then(response =>
			onResponse(
				response,
				data => data.updatePaymentDetails
			)
		).catch(createError);
	}

	addAvatarPhoto(data) {
		return new Promise((resolve, reject) => {
			const token = this.getConnectionInfo();
			const formData = new FormData();
			data.toBlob(blob => {
				formData.append("file", blob);

				fetch(config.server.backendUrl + '/api/image', {
					method: 'POST',
					headers: {
						authorization: token ? `Bearer ${token}` : ''
					},
					body: formData
				})
					.then(rawResponse => rawResponse.blob())
					.then(blob => resolve(window.URL.createObjectURL(blob)))
					.catch(error => reject(createError(error)));
			});
		})
	}

	getAvatarPhoto(userId) {
		const token = this.getConnectionInfo();
		return fetch(config.server.backendUrl + '/api/image?id=' + userId, {
			method: 'GET',
			headers: {
				authorization: token ? `Bearer ${token}` : ''
			},
		})
			.then(rawResponse => rawResponse.blob())
			.then(blob => window.URL.createObjectURL(blob))
			.catch(createError);
	}

	saveUserInvoiceDetails(values: InvoiceValues) {
		const newValues = {};
		for (const [key, value] of Object.entries(values)) {

			newValues[key] = value.toString().trim();
		}

		const graphQLConvertion = JSON.stringify(newValues).replace(/"([^(")"]+)":/g, "$1:");
		const save = gql`
            mutation {
                updateUserInvoiceDetail(
                    values: ${graphQLConvertion},
                )
            }
        `;
		return this.client().mutate<{ updateUserInvoiceDetail: any }>({
			mutation: save,
			errorPolicy: 'all',
		}).then(response =>
			onResponse(
				response,
				data => data.updateUserInvoiceDetail
			)
		).catch(createError);
	}
}
