const validateInputString = (value: string, onSuccess, onError) => {
    const format = /^[ \\ \" /´´ ``]$/;

    if (!value || value.trim() === "" || format.test(value)) {
        onError();
    }
    else {
        onSuccess();
    }
};

export default validateInputString;