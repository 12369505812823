import { tokenToJson } from "./utils";
import { AuthService } from "../services/authService";
import { User } from "../components/contexts/user";

export const TokenProvider = () => {

    const keyWord = 'userToken'
    const getTokenFromStorage: any = () => localStorage.getItem('userToken') || null;

    const getExpirationDate = jwtToken => {
        const jwt = tokenToJson(jwtToken);

        // multiply by 1000 to convert seconds into milliseconds
        return (jwt && jwt.exp && jwt.exp * 1000) || null;
    };

    const getUserInfo = (): User => {
        const tokenAsJson = tokenToJson(getTokenFromStorage());
        if (tokenAsJson) {
            return {
                ...tokenAsJson,
                subscriptionValidUntil: tokenAsJson.subscriptionValidUntil ? new Date(tokenAsJson.subscriptionValidUntil) : undefined
            };
        }

        return undefined;
    }

    const isExpired = (exp) => {
        if (!exp) {
            return false;
        }
        return Date.now() > exp;
    };

    const getToken = async () => {
        if (isExpired(getExpirationDate(getTokenFromStorage()))) {
            const updatedToken = await fetch('/update-token', {
                method: 'POST',
                body: getTokenFromStorage().refreshToken
            })
                .then(r => r.json());

            setToken(updatedToken);
        }

        return getTokenFromStorage();
    };

    const isLoggedIn = () => {
        if (!getTokenFromStorage()) {
            return false;
        }
        else if (isExpired(getExpirationDate(getTokenFromStorage()))) {
            console.log("userToken is expired");
            return false;
        }
        else return true;
    };

    const isSubscriptionExpired = () => {
        if (!getTokenFromStorage()) {
            return false;
        }
        const jwt = tokenToJson(getTokenFromStorage());
        if (jwt == null) {
            return false;
        }
        const isSubscriptionExpired = jwt && jwt.subscriptionValidUntil ? Date.parse(jwt.subscriptionValidUntil) < Date.now() : false;

        return isSubscriptionExpired || jwt.subscriptionType === 'DOWNGRADE';
    }

    const setToken = (token) => {
        if (token) {
            localStorage.setItem(keyWord, JSON.stringify(token));
        } else {
            localStorage.removeItem(keyWord);
        }
    };

    return {
        getToken,
        isLoggedIn,
        setToken,
        getUserInfo,
        isSubscriptionExpired
    };
};