import React, { useContext } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import { makeStyles } from "@material-ui/core/styles";
import { ModalContext } from "../contexts/ModalContext";
import { SubscriptionType } from "./subscriptionType";
import { UserContext } from "../contexts/UserContext";
import SubscriptionService from "../../services/subscriptionService";
import useLoadingEffect from "../load/useLoadingEffect";
import { ResponseError } from "../../services/grapqhlResponse";
import { SnackbarContext } from "../contexts/SnackbarContext";
import { SubscriptionWorkflowContext } from "./SubscriptionWorkflowContext";
import stepToModal from "./stepToModal";
import ActivateSubscription from "./ActivateSubscription";
import Workflow, { WorkflowItem } from "./workflow";

const useStyles = makeStyles((theme) => ({
  subsbox: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(2),
      width: theme.spacing(30),
      height: theme.spacing(60),
      "@media only screen and (max-height: 760px)": {
        height: theme.spacing(45),
      },
    },
  },
  header: {
    display: "flex",
    alignItems: "center",
    height: "100px",
    width: "99%",
    "@media only screen and (max-height: 760px)": {
      height: "80px",
    },
  },
  body: {
    height: "210px",
    width: "99%",
    "@media only screen and (max-height: 760px)": {
      height: "150px",
    },
  },
  price: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "75px",
    width: "99%",
    textAlign: "center",
    "@media only screen and (max-height: 760px)": {
      height: "60px",
    },
  },
  footer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "75px",
    width: "99%",
    textAlign: "center",
    "@media only screen and (max-height: 760px)": {
      height: "60px",
    },
  },
  currency: {
    fontFamily: "Roboto",
    fontSize: "40px",
    "&:before": {
      content: '"€"',
      fontSize: "16px",
      verticalAlign: "top",
    },
  },
  buyButton: {
    minWidth: theme.spacing(17),
  },
  faetues: {
    display: "block",
    paddingLeft: "10%",
    color: "#000000",
    fontFamily: "Roboto",
    fontSize: "12px",
    letterSpacing: "0.4px",
    lineHeight: "24px",
    opacity: 0.87,
  },
  priceTag: {
    color: "#000000",
    fontFamily: "Roboto",
    fontSize: "16px",
    letterSpacing: "0.15px",
    lineHeight: "24px",
    opacity: 0.87,
  },
  headerTag: {
    paddingLeft: "10%",
    fontFamily: "Roboto",
    fontSize: "25px",
  },
  currentPlanTag: {
    color: "#337bff",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 700,
    letterSpacing: "0.15px",
    lineHeight: "24px",
    opacity: 0.87,
  },
}));

type Props = {
  setTrial;
  setTrialButtonDisabled: (loading: boolean) => void;
};

const SubscriptionModalContent = ({
  setTrial,
  setTrialButtonDisabled,
}: Props) => {
  const classes = useStyles();
  const [user] = useContext(UserContext);
  const subscriptionType = user.subscriptionType;
  const [modalProps, setModalProps] = useContext(ModalContext);
  const [, notifyUser] = useContext(SnackbarContext);
  const [workflow, setWorkflow] = useContext(SubscriptionWorkflowContext);

  const getWorkflowSteps = (workflow: Workflow): Array<WorkflowItem> => {
    console.log("steps", workflow);
    if (workflow.plan === "FREE" && workflow.steps.length === 0) {
      return ["SUBSCRIPTION", "FREE"];
    } else if (workflow.plan === "FREE" && workflow.steps.length > 0) {
      return ["SUBSCRIPTION", ...workflow.steps, "CONFIRM"];
    }
    return ["SUBSCRIPTION", ...workflow.steps];
  };

  const [loading, callActionWith] = useLoadingEffect(
    (type: SubscriptionType) =>
      new SubscriptionService().getSubscriptionWorkflow(type),
    {
      onSuccess: (type, workflow) => {
        const steps: Array<WorkflowItem> = getWorkflowSteps(workflow);
        setWorkflow({
          to: type,
          type: workflow.type,
          steps: steps,
          step: 1,
          projects: [],
          participants: [],
        });
        setModalProps({
          ...modalProps,
          subscriptionModal: {
            show: false,
          },
          [stepToModal(steps[1])]: {
            show: true,
            downgradeTo: type,
          },
        });
      },
      onError: () =>
        notifyUser({
          show: true,
          type: "error",
          message: "Could not open Subscription Change",
        }),
    },
    setTrialButtonDisabled
  );

  const onBuy = (type: SubscriptionType) => () => {
    callActionWith(type);
  };

  return (
    <Grid container justify="center">
      <Grid item lg={3}>
        <div className={classes.subsbox} data-type="basic">
          <Paper variant="outlined">
            <div className={classes.header}>
              <span className={classes.headerTag}>Basic</span>
              {subscriptionType === "BASIC" ? (
                <CheckCircleOutlinedIcon
                  style={{
                    color: "#337bff",
                    marginLeft: "10%",
                    marginTop: "2%",
                  }}
                />
              ) : null}
            </div>
            <div className={classes.body}>
              <span className={classes.faetues}>1 Project</span>
              <span className={classes.faetues}>3 Team Members</span>
            </div>
            <div className={classes.price}>
              <span className={classes.priceTag}>Free forever</span>
            </div>
            <div className={classes.footer}>
              <ActivateSubscription
                currentSubscriptionType={subscriptionType}
                toSubscriptionType="BASIC"
                label="Free"
                activation={{
                  on: user.subscriptionValidUntil,
                  to: user.downgradeTo,
                }}
                loading={loading}
                onActivate={onBuy("BASIC")}
              />
            </div>
          </Paper>
        </div>
      </Grid>
      <Grid item lg={3}>
        <div className={classes.subsbox} data-type="business">
          <Paper variant="outlined">
            <div className={classes.header}>
              <span className={classes.headerTag}>Business</span>
              {subscriptionType === "BUSINESS" ? (
                <CheckCircleOutlinedIcon
                  style={{
                    color: "#337bff",
                    marginLeft: "10%",
                    marginTop: "2%",
                  }}
                />
              ) : null}
            </div>
            <div className={classes.body}>
              <span className={classes.faetues}>5 Projects</span>
              <span className={classes.faetues}>10 Team Members</span>
              <span className={classes.faetues}>Voting</span>
            </div>
            <div className={classes.price}>
              <span className={classes.currency}>9&nbsp;</span>
              <span className={classes.priceTag} style={{ paddingTop: "7%" }}>
                /month
              </span>
            </div>
            <div className={classes.footer}>
              <ActivateSubscription
                currentSubscriptionType={subscriptionType}
                toSubscriptionType="BUSINESS"
                label={subscriptionType !== "PREMIUM" ? "BUY" : "Downgrade"}
                activation={{
                  on: user.subscriptionValidUntil,
                  to: user.downgradeTo,
                }}
                loading={loading}
                onActivate={onBuy("BUSINESS")}
              />
            </div>
          </Paper>
        </div>
      </Grid>
      <Grid item lg={3}>
        <div className={classes.subsbox} data-type="premium">
          <Paper variant="outlined">
            <div className={classes.header}>
              <span className={classes.headerTag}>Premium</span>
              {subscriptionType === "PREMIUM" ||
              subscriptionType === "TRIAL" ? (
                <CheckCircleOutlinedIcon
                  style={{
                    color: "#337bff",
                    marginLeft: "10%",
                    marginTop: "2%",
                  }}
                />
              ) : null}
            </div>
            <div className={classes.body}>
              <span className={classes.faetues}>Unlimited Projects</span>
              <span className={classes.faetues}>Unlimited Team members</span>
              <span className={classes.faetues}>Voting</span>
              <span className={classes.faetues}>Export / Share</span>
              <span className={classes.faetues}>History</span>
            </div>
            <div className={classes.price}>
              <span className={classes.currency}>29&nbsp;</span>
              <span className={classes.priceTag} style={{ paddingTop: "7%" }}>
                /month
              </span>
            </div>
            <div className={classes.footer}>
              <ActivateSubscription
                currentSubscriptionType={subscriptionType}
                toSubscriptionType="PREMIUM"
                label="BUY"
                activation={{
                  on: user.subscriptionValidUntil,
                  to: user.downgradeTo,
                }}
                loading={loading}
                onActivate={onBuy("PREMIUM")}
              />
            </div>
          </Paper>
        </div>
      </Grid>
      <Grid item lg={3}>
        <div className={classes.subsbox} data-type="enterprise">
          <Paper variant="outlined">
            <div className={classes.header}>
              <span className={classes.headerTag}>Enterprise</span>
              {subscriptionType === "ENTERPRISE" ? (
                <CheckCircleOutlinedIcon
                  style={{
                    color: "#337bff",
                    marginLeft: "10%",
                    marginTop: "2%",
                  }}
                />
              ) : null}
            </div>
            <div className={classes.body}>
              <span className={classes.faetues}>
                Request your personal offer now
              </span>
            </div>
            <div className={classes.price}></div>
            <div className={classes.footer}>
              {subscriptionType === "ENTERPRISE" ? (
                <span className={classes.currentPlanTag}>CURRENT PLAN</span>
              ) : (
                <Button
                  disabled={loading}
                  className={classes.buyButton}
                  variant="contained"
                  color="primary"
                  href={
                    "mailto:office@rizki.com?subject=Enterprise%20subscription"
                  }
                >
                  CONTACT US
                </Button>
              )}
            </div>
          </Paper>
        </div>
      </Grid>
    </Grid>
  );
};

export default SubscriptionModalContent;
