import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { green, red, grey } from '@material-ui/core/colors';
import Avatar from '@material-ui/core/Avatar';
import { UserService } from '../../services/userService';
import RizkiTooltip from '../commons/RizkiTooltip';
import { abbreviationOfName } from './abbreviationOfName';
import { ClassNameProps } from '../materialUi/classNameProps';
import { logError } from '../../services/grapqhlResponse';

const useStyles = makeStyles((theme) => ({
    registered: {
        color: grey[900],
        borderColor: green[500],
        borderStyle: 'solid',
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    invited: {
        color: grey[900],
        borderColor: red[500],
        borderStyle: 'solid',
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    avatarSize: {
        width: theme.spacing(15),
        height: theme.spacing(15),
    },
}));

interface Properties {
    imageId, tooltip?, registered?, imageSrc?, avatarStyle?, reloadImage?
}

const CustomAvatar = (
    {
        imageId,
        tooltip,
        registered,
        imageSrc,
        avatarStyle,
        reloadImage
    }: Properties & ClassNameProps) => {
    const classes = useStyles();
    const [url, setUrl] = useState('');
    const userService = new UserService();

    useEffect(() => { 
        const getAvatarImage = () => {
            if (imageId) {
                userService.getAvatarPhoto(imageId)
                    .then(url => {
                        if (url) {
                            setUrl(url);
                        }
                    })
                    .catch(logError);
            } else if (imageSrc) {
                setUrl(imageSrc);
            } else {
                setUrl(undefined)
            }
        }

        getAvatarImage();
    }, [imageSrc, reloadImage]);

    return (
        <RizkiTooltip title={tooltip} arrow>
            <Avatar
                aria-label={`${registered === undefined ? '' : registered ? 'registered' : 'invited'}`}
                className={
                    `${registered === undefined
                        ? ''
                        : registered
                            ? classes.registered
                            : classes.invited} ${avatarStyle}`} alt={tooltip} src={url}>
                {abbreviationOfName(tooltip)}
            </Avatar>
        </RizkiTooltip>
    );
}

export default CustomAvatar;