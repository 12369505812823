import { setRef } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { ProjectService } from '../../services/projectService';
import RizkiTable, { RoleDropDown, Row, Rows } from "../commons/RizkiTable"
import { RefreshContext, RefreshContextType } from '../contexts/RefreshContext';
import { SnackbarContext } from '../contexts/SnackbarContext';
import ConfirmationDialog from '../dialog/confirmationDialog';
import { emptyPageCount, PageCount } from '../pagination/page';
import { PagePosition } from '../pagination/pagePosition';
import { Project } from '../project/project';
import { Collaborator } from './collaborator';

type ProjectListProps = {
    projectManagerId: string;
    collaborator: Collaborator;
    refreshCollaborators: () => void;
    saveChangedRow: (changedRow: RoleDropDown) => void;
    changedRows: RoleDropDown[];
}

export const ProjectList = (
    {
        projectManagerId,
        collaborator,
        refreshCollaborators,
        saveChangedRow,
        changedRows
    }: ProjectListProps
) => {
    const [projects, setProjects] = useState<PageCount<Project>>(emptyPageCount());
    const [page, setPage] = useState<PagePosition>({ limit: 10, offset: 0 });
    const [refreshProject, setRefreshProject] = useState<boolean>(false);
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
    const [rowToBeDeleted, setRowToBeDeleted] = useState<Row[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [, notifyUser] = useContext(SnackbarContext);
    const [refresh, setRefresh] = useContext<RefreshContextType>(RefreshContext);

    useEffect(() => {
        setLoading(true);
        const service = new ProjectService();
        if (collaborator.type === 'USER') {
            service.projectsAsCollboratorByUserId(projectManagerId, collaborator.id, page)
                .then(result => {
                    setProjects(result);
                })
                .catch(() => emptyPageCount())
                .finally(() => setLoading(false));
        } else {
            service.projectsAsCollboratorForInvitee(projectManagerId, collaborator.email, page)
                .then(result => setProjects(result))
                .catch(() => emptyPageCount())
                .finally(() => setLoading(false));
        }
    }, [collaborator, page, refreshProject]);

    const rows: Rows = projects.rows.map(row => (
        [
            { value: row.name, type: 'string' },
            { value: row.projectRole, type: 'roleDropDown', project: row.id, roleType: collaborator.type, email: collaborator.email, userId: collaborator.id, callback: saveChangedRow },
            { value: row.id, type: 'string', hidden: true }
        ]
    ));

    const reshreshProject = () => {
        setRefreshProject(!refreshProject);
        refreshCollaborators();
        setRefresh({...refresh, refreshProject: !refresh.refreshProject});
    }

    const deleteParticipantFromProject = (row: Row[]) => {
        setLoading(true);
        setShowConfirmation(false);
        const service = new ProjectService();
        service.deleteParticipantFromProject(collaborator.id, row[2].value, collaborator.type)
            .then(() => {
                reshreshProject();
                setRowToBeDeleted([]);
            })
            .catch(() => setLoading(false));
    }

    const askForDeleteParticipantFromProject = (row: Row[]) => {
        setRowToBeDeleted(row);
        setShowConfirmation(true);
    }

    const changePage = (pagePosition: PagePosition) => {
        if (changedRows.length === 0) {
            setPage(pagePosition);
        } else {
            notifyUser({
                show: true,
                type: "error",
                message: "Save changes to switch pages"
            });
        }
    }

    return (
        <>
            <RizkiTable
                headers={['PROJECT NAME', 'ROLE']}
                data={{ count: projects.count, rows: rows }}
                loading={loading}
                queryNewPage={changePage}
                noDataMessage='No Projects exist'
                actions={[
                    { action: 'delete', callback: (row) => askForDeleteParticipantFromProject(row) }
                ]}
            />
            <ConfirmationDialog
                show={showConfirmation}
                onCancel={() => setShowConfirmation(false)}
                onConfirmation={() => deleteParticipantFromProject(rowToBeDeleted)}
                title={'Remove project'}
                confirmationQuestion={`Are you sure to remove project ${rowToBeDeleted.length > 0 ? rowToBeDeleted[0].value : ''} `}
            />
        </>
    )
}