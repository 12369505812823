import React, { useState, useEffect, useContext } from 'react'
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import { Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { UserService } from '../../services/userService';
import { invoiceName, invoiceCompanyName, invoiceAddress, invoiceZip, invoiceCity, invoiceVatNumber } from '../../models/invoiceDetails'
import { ModalContext } from '../contexts/ModalContext';
import { SnackbarContext } from '../contexts/SnackbarContext';
import { logError } from '../../services/grapqhlResponse';
import { UserContext } from '../contexts/UserContext';
import { InputField } from '../forms/inputField';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
        },
        width: "40%",
    },
    textStyle: {
        color: "#7B7B7B",
        fontFamily: "Roboto",
        fontSize: "10px",
        letterSpacing: "1.5px",
        lineHeight: "16px",
        opacity: "0.87",
        textAlign: "center"
    },
    textFieldStyle: {
        width: "100%",
        color: "#7B7B7B",
        fontFamily: "Roboto",
        fontSize: "16px",
        letterSpacing: "-0.15px",
        lineHeight: "24px",
        textAlign: "left"
    },
    formGrid: {
        textAlign: "center",
        width: "90%",
        margin: "5%"
    },
    buttonGrid: {
        width: "100%",
    },
    buttonAvatarStyle: {
        color: "#0869FF",
        fontFamily: "Roboto",
        fontSize: "14px",
        letterSpacing: "0.75px",
        lineHeight: "17px",
        textAlign: "center"
    },
    input: {
        display: 'none',
    },

}));

function hasChanged(obj1, obj2) {
    var result = false;
    for (let key in obj1) {
        if (obj2[key] != obj1[key]) {
            result = true;
        }
    }
    return result;
}

const countries = [
    {
        value: "AT",
        label: "Austria"
    },
    {
        value: "DE",
        label: "Germany"
    }
];

const PaymentDetails = ({ onClosePopup, saveProfile, setSaveProfile }) => {



    const userService = new UserService();
    const classes = useStyles();
    const [user,] = useContext(UserContext);
    const userID = user.user_id;
    const [isClicked, setClicked] = useState(false);
    const [modalProps, setModalProps] = useContext(ModalContext);
    const [, notifyUser] = useContext(SnackbarContext);

    const initialValues = {
        userId: user.user_id,
        name: "",
        paymentPeriod: "1",
        price: "0",
        cardNumber: "",
        cardHolder: "",
        validUntil: "",
        date: "",
        cvc: "",
        acceptTerms: false
    }

    let initialInvoiceValues = {
        userId: user.user_id,
        name: "",
        companyName: "",
        address: "",
        zip: "",
        city: "",
        country: "AT",
        vatNumber: ""
    }

    const [values, setValues] = useState(initialValues);
    const [invoiceValues, setInvoiceValues] = useState(initialInvoiceValues);


    useEffect(() => {
        if (userID) {
            const getUserById = () => {
                userService.getInvoiceDetails()
                    .then(response => {
                        const address = response.response.address || {};
                        const metadata = response.response.metadata || {};
                        const tempValues = {
                            name: metadata.customerName || "",
                            companyName: metadata.companyName || "",
                            address: (address.line1 || "") + (address.line2 ? " " + address.line2 : ""),
                            zip: address.postal_code || "",
                            city: address.city || "",
                            country: address.country || "",
                            vatNumber: metadata.vatNumber || "",
                            userId: userID
                        };
                        initialInvoiceValues = tempValues;
                        setInvoiceValues(tempValues);
                    }).catch(logError)
                    .then(() => userService.getUserById())
                    .then(response => {
                        const tempValues = { ...response.invoiceDetail };
                        tempValues.userId = initialValues.userId;

                        for (const [key, value] of Object.entries(tempValues)) {
                            if (value != null) {
                                initialInvoiceValues[key] = value;
                            }
                        }

                        setInvoiceValues({ ...initialInvoiceValues });
                    }).catch(logError);
            }
            getUserById();
        }
    }, []);

    useEffect(() => {
        const fetchUserPaymentMethod = () => {
            userService.fetchUserPaymentMethod()
                .then(response => {
                    const cardHolder = response.response.name;
                    const cardNumber = "**** **** **** " + response.response.last4;
                    const cardExp = response.response.exp_month + '/' + response.response.exp_year.toString().slice(-2);
                    setValues({ ...values, cardHolder, cardNumber, date: cardExp });
                }).catch(logError);
        }
        fetchUserPaymentMethod();

    }, [modalProps.creditCardDetailsModal]);

    useEffect(() => {
        const saveChanges = () => {
            if (saveProfile) {
                if (hasChanged(initialInvoiceValues, invoiceValues)) {
                    setSaveProfile(false);
                    setClicked(true);
                    if (areInvoiceFieldsValid()) {
                        setClicked(false);
                        userService.saveUserInvoiceDetails(invoiceValues)
                            .then(() => {
                                notifyUser({
                                    show: true,
                                    type: "success",
                                    message: "Invoice Details are saved successfully"
                                });
                            }).catch(err => {
                                logError(err);
                                notifyUser({
                                    show: true,
                                    type: "error",
                                    message: "Invoice Details could not be saved"
                                });
                            })
                    }
                }
                else {
                    setSaveProfile(false);
                    setClicked(false);
                }
            }
        }
        saveChanges();
    }, [saveProfile]);

    const areInvoiceFieldsValid = () => {
        return invoiceName.valid(invoiceValues.name) &&
            invoiceAddress.valid(invoiceValues.address) &&
            invoiceZip.valid(invoiceValues.zip) &&
            invoiceCity.valid(invoiceValues.city)
    }

    const handleInvoiceChange = event => {
        setInvoiceValues({
            ...invoiceValues,
            [event.target.name]: event.target.value,
        });
    }

    const handleCreditCardDetails = () => {
        setModalProps({
            ...modalProps,
            creditCardDetailsModal: {
                show: true
            }
        });
    }

    return (
        // @ts-ignore
        <div style={{ textAlign: "-webkit-center" }}>
            <Box width="100%" marginTop="3%" display="flex" flexDirection="row" justifyContent="space-between">
                <Box width="30%" >
                    <Grid container className={classes.formGrid} spacing={2}>

                        <Grid item xs={12} >
                            <Typography className={classes.textStyle} component="h4">
                                <br />
                                    PERSONAL INFORMATION
                             </Typography>
                        </Grid>
                        <br /><br /><br /><br />
                        <Grid item xs={12} >
                            <TextField
                                className={classes.textFieldStyle}
                                required
                                id="name"
                                name="name"
                                label="Name"
                                variant="outlined"
                                value={invoiceValues.name}
                                onChange={handleInvoiceChange}
                                helperText={`${invoiceValues.name.length}/100 Characters`}
                                error={!invoiceName.valid(invoiceValues.name) && isClicked}
                                inputProps={{
                                    maxLength: 100,
                                    className: classes.textFieldStyle
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} >
                            <TextField
                                className={classes.textFieldStyle}
                                required
                                id="address"
                                name="address"
                                label="Address"
                                variant="outlined"
                                multiline
                                rows={2}
                                value={invoiceValues.address}
                                onChange={handleInvoiceChange}
                                helperText={`${invoiceValues.address.length}/200 Characters`}
                                error={!invoiceAddress.valid(invoiceValues.address) && isClicked}
                                inputProps={{
                                    maxLength: 200,
                                    className: classes.textFieldStyle
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} >
                            <TextField
                                className={classes.textFieldStyle}
                                required
                                id="zip"
                                name="zip"
                                label="Zip Code"
                                variant="outlined"
                                value={invoiceValues.zip}
                                onChange={handleInvoiceChange}
                                helperText={`${invoiceValues.zip.length}/15 Characters`}
                                error={!invoiceZip.valid(invoiceValues.zip) && isClicked}
                                inputProps={{
                                    maxLength: 15,
                                    className: classes.textFieldStyle
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} >
                            <TextField
                                className={classes.textFieldStyle}
                                required
                                id="city"
                                name="city"
                                label="City"
                                variant="outlined"
                                value={invoiceValues.city}
                                onChange={handleInvoiceChange}
                                helperText={`${invoiceValues.city.length}/100 Characters`}
                                error={!invoiceCity.valid(invoiceValues.city) && isClicked}
                                inputProps={{
                                    maxLength: 100,
                                    className: classes.textFieldStyle
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className={classes.textFieldStyle}
                                required
                                id="country"
                                name="country"
                                label="Country"
                                select
                                variant="outlined"
                                value={invoiceValues.country}
                                onChange={handleInvoiceChange}
                                helperText={` `}
                                inputProps={{
                                    className: classes.textFieldStyle
                                }}
                            >
                                {countries.map(option => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>

                    </Grid>
                </Box>
                <Box width="30%"  >
                    <Grid container className={classes.formGrid} spacing={2}>

                        <Grid item xs={12} >
                            <Typography className={classes.textStyle} component="h4">
                                <br />
                                    COMPANY INFORMATION
                             </Typography>
                        </Grid>
                        <br /><br /><br /><br />
                        <Grid item xs={12} >
                            <TextField
                                className={classes.textFieldStyle}
                                id="company-name"
                                name="companyName"
                                label="Company Name"
                                variant="outlined"
                                multiline
                                rows={2}
                                value={invoiceValues.companyName}
                                onChange={handleInvoiceChange}
                                helperText={`${invoiceValues.companyName.length}/200 Characters`}
                                error={!invoiceCompanyName.valid(invoiceValues.companyName)}
                                inputProps={{
                                    maxLength: 200,
                                    className: classes.textFieldStyle
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} >
                            <TextField
                                className={classes.textFieldStyle}
                                id="vatNumber"
                                name="vatNumber"
                                label="VAT Number"
                                variant="outlined"
                                value={invoiceValues.vatNumber}
                                onChange={handleInvoiceChange}
                                helperText={`${invoiceValues.vatNumber.length}/20 Characters`}
                                error={!invoiceVatNumber.valid(invoiceValues.vatNumber)}
                                inputProps={{
                                    maxLength: 20,
                                    className: classes.textFieldStyle
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>

                <Box width="30%" >
                    <Grid container className={classes.formGrid} spacing={2}>

                        <Grid item xs={12} >
                            <Typography className={classes.textStyle} component="h4">
                                <br />
                                CREDIT CARD INFORMATION
                             </Typography>
                        </Grid>
                        <br /><br /><br /><br />
                        <Grid item xs={12} >
                            <TextField
                                disabled
                                className={classes.textFieldStyle}
                                required
                                id="cardHolder"
                                name="cardHolder"
                                label="Card Holder Name"
                                variant="outlined"
                                value={values.cardHolder}
                                inputProps={{
                                    maxLength: 100,
                                    className: classes.textFieldStyle
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} >
                            <TextField
                                disabled
                                className={classes.textFieldStyle}
                                required
                                id="cardNumber"
                                name="cardNumber"
                                label="Card Number"
                                variant="outlined"
                                value={values.cardNumber}
                                inputProps={{
                                    maxLength: 100,
                                    className: classes.textFieldStyle
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} >
                            <TextField
                                disabled
                                className={classes.textFieldStyle}
                                required
                                id="expirationDate"
                                name="expirationDate"
                                label="Expiration Date"
                                variant="outlined"
                                value={values.date}
                                inputProps={{
                                    maxLength: 100,
                                    className: classes.textFieldStyle
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button color="primary" variant="contained" style={{ float: "right" }} onClick={handleCreditCardDetails}>
                                Change Method
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </div>
    )
}
export default PaymentDetails;