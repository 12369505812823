import React, { useState, useEffect, useContext } from 'react'
import { useTheme, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ShareRiskService } from '../../services/shareRiskService';
import config from '../../config/config';
import { logError } from '../../services/grapqhlResponse';
import { UserContext } from '../contexts/UserContext';


const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: "50vh",
        width: "50vw"
    },
    content: {
        display: "flex",
        justifyContent: "center"
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    closeButtonRoot: {
        margin: 0,
        padding: theme.spacing(2),
    },
    title: {
        color: "#000000",
        fontFamily: "Roboto",
        fontSize: "14px",
        letterSpacing: "0.15px",
        lineHeight: "24px",
        opacity: 0.87,
        textAlign: "left"
    },
    disableLink: {
        pointerEvents: "none",
    },
    urlText: {
        color: "#313131",
        fontFamily: "Roboto",
        fontSize: "14px",
        letterSpacing: "0.25px",
        textAlign: "left",
        lineHeight: "20px",
        padding: "10px",
        paddingLeft: "20px"
    },
    copyButton: {
        color: "#0869FF",
        fontFamily: "Roboto",
        fontSize: "14px",
        letterSpacing: "0.75px",
        opacity: "0.87",
        textAlign: "center",
        lineHeight: "20px",
        padding: "10px",
        cursor: "pointer"
    },
    linkCopiedMessage: {
        color: "#2BAD56",
        fontFamily: "Roboto",
        fontSize: "13px",
        letterSpacing: "0.15px",
        opacity: "0.87",
        textAlign: "center",
        lineHeight: "20px",
        padding: "10px",
    }
}));

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(2.5),
        color: theme.palette.grey[500],
    },
});


export default function ShareRiskMatrixModal(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [user,] = useContext(UserContext);
    const shareRiskService = new ShareRiskService();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [linkCopied, setLinkCopied] = useState(false);
    const [inlineLinkCopied, setInlineLinkCopied] = useState(false);
    const [shareLink, setShareLink] = useState("");
    const [inlineLink, setInlineLink] = useState('');

    const projectId = props.project.id;
    const projectName = props.project.name;

    useEffect(() => {
        if (["PREMIUM", "TRIAL"].includes(user.subscriptionType)) {
            const userID = user.user_id;
            if (userID && projectId) {
                const getShareIdByUserIdAndProjectId = () => {
                    shareRiskService.getShareIdByUserIdAndProjectId(projectId, projectName)
                        .then(response => {
                            const shareId = (response && response.shareId) || '';
                            const url = config.server.frontendHost + '/share/' + shareId;
                            setShareLink(url);
                            setInlineLink(`<iframe width="1120" height="650" src="${url}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`);
                        }).catch(err => logError(err));

                }
                getShareIdByUserIdAndProjectId();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.shareRiskMatrixModal])




    const handleClose = () => {
        props.setShareRiskMatrixModal(false);
    };

    const body = (
        <Box className={classes.root} width="100%" height="80%" display="flex" justifyContent="center" alignItems="center" flexDirection="column">
            <Box bgcolor="white" width="80%" height="40%" display="flex" flexDirection="column" justifyContent="center" alignContent="center">
                <Paper style={{ width: "100%", height: "100%" }} variant="outlined" >
                </Paper>
            </Box>

            <br></br><br></br>

            <Box bgcolor="white" width="80%" display="flex" flexDirection="column" justifyContent="center" alignContent="center">
                <Paper style={{ width: "100%", height: "100%" }} variant="outlined">
                    {/* 
                    // @ts-ignore */}
                    <Grid container display="flex" direction="row"  >
                        <Grid item xs={8}>
                            <Typography className={classes.urlText} >
                                {shareLink}
                            </Typography>
                        </Grid>

                        <Grid container item xs={2} alignItems="center">
                            {linkCopied ?
                                <Typography className={classes.linkCopiedMessage} >
                                    Link Copied!
                                </Typography> : null
                            }
                        </Grid>
                        <Grid container item xs={2} alignItems="center">
                            <CopyToClipboard text={shareLink}
                                onCopy={() => setLinkCopied(true)}>
                                <Typography className={classes.copyButton} >
                                    COPY
                                </Typography>
                            </CopyToClipboard>
                        </Grid>

                    </Grid>
                </Paper>

            </Box>
            <br></br><br></br>

            <Box bgcolor="white" width="80%" display="flex" flexDirection="column" justifyContent="center" alignContent="center">
                <Paper style={{ width: "100%", height: "100%" }} variant="outlined">
                    {/* 
                    // @ts-ignore */}
                    <Grid container display="flex" direction="row" >
                        <Grid item xs={8}>
                            <Typography className={classes.urlText} >
                                {inlineLink}
                            </Typography>
                        </Grid>

                        <Grid container item xs={2} alignItems="center">
                            {inlineLinkCopied ?
                                <Typography className={classes.linkCopiedMessage} >
                                    Link Copied!
                                </Typography> : null
                            }
                        </Grid>

                        <Grid container item xs={2} alignItems="center">
                            <CopyToClipboard text={inlineLink}
                                onCopy={() => setInlineLinkCopied(true)}>
                                <Typography className={classes.copyButton} >
                                    COPY
                                </Typography>
                            </CopyToClipboard>
                        </Grid>

                    </Grid>
                </Paper>

            </Box>

        </Box>
    )

    // @ts-ignore
    const DialogTitle = withStyles(styles)((props) => {
        // @ts-ignore
        const { children, classes, onClose, ...other } = props;
        return (
            <MuiDialogTitle disableTypography className={classes.closeButtonRoot} {...other}>
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                    <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </MuiDialogTitle>
        );
    });

    return (
        <Dialog
            fullScreen={fullScreen}
            open={props.shareRiskMatrixModal}
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            maxWidth="xl"
        >
            <DialogTitle
                //  @ts-ignore
                className={classes.title}
                onClose={handleClose}>
                Share Project Link
            </DialogTitle>

            <DialogContent dividers className={classes.content}>
                <div className={classes.root}>
                    {body}
                </div>
            </DialogContent>

        </Dialog>
    );
}