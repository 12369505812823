import { Configuration } from './configuration';

const config: Configuration = {
    server: {
        environment: "local",
        backendUrl: "http://localhost:4000",
        frontendHost: "http://localhost",
        apiUrl: "http://localhost:4000/graphql"
    },
    stripe: {
        key: "pk_test_51HYqqUKGH9RiIeWjRgRGHNwp9j3k4mUf8ReZoK2gnm6OJTvdcADv8vLeaDNIbxWb2UvcH5YFQpH40ZMgM5S1dS7t004d1Ol2J2"
    },
    source: {
        projectList: "project-list",
        redirectToInvoice: "invoices",
        redirectToPayment: "payment-details",
        projectRisk: "project-risk",
        addRisk: "add-risk",
        riskList: "risk-list",
        passwordRecovery: "password-recovery"
    },
    autoRefreshInterval: 15000,
    jwtUpdateInterval : 25 * 60000
};
export default config;

export function load(): Promise<Configuration> {
    return fetch('/config.json')
        .then(result => result.json())
        .then(jsonConfig => {
            const mappedConfig = mapConfig(jsonConfig, config);
            return config;
        }).catch(err => {
            console.error('Error while loading the config ', err);
            console.error('Loading default values: ', config);
            return config;
        });
}

const jsonConfig = load();

function mapConfig(config: Configuration, currentConfig: Configuration) {
    currentConfig.server.environment = config.server.environment;
    currentConfig.server.backendUrl = config.server.backendUrl;
    currentConfig.server.frontendHost = config.server.frontendHost;
    currentConfig.server.apiUrl = config.server.apiUrl;
    currentConfig.stripe.key = config.stripe.key;
}
