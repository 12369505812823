import React, { createContext, useState } from 'react';
import { RizkiExport } from './rizkExport';

type RiskExportContextType = [RizkiExport, (e: RizkiExport) => void];
const emptyRizkiExport: RizkiExport = {
    note: '',
    includeRiskIdToExport: false,
    selectedRiskToExport: [],
    history: []
};

const RiskExportContext = createContext<RiskExportContextType>([emptyRizkiExport, () => []]);

const RiskExportContextProvider = (props) => {
    const [exportData, setExportData] = useState(emptyRizkiExport);

    return (
        <RiskExportContext.Provider value={[exportData, setExportData]}>
            {props.children}
        </RiskExportContext.Provider>
    );
}

export { RiskExportContext, RiskExportContextProvider };