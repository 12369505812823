import { createMuiTheme } from '@material-ui/core/styles';

const rizkiTheme = createMuiTheme({
  palette: {
    background: {
      default: "#F3F3F3"
    },
    primary: {
      main: '#0869ff',
      contrastText: '#fff',
    },
  },
  overrides: {
    MuiTableRow: {
      footer: {
        left: 0,
        bottom: 0, // <-- KEY
        zIndex: 2,
        position: 'sticky'
      }
    },
    MuiToolbar: {
      root: {
        boxShadow: "0 0 30px 0 rgba(0, 0, 0, 0.1)"
      },
      regular: {
        '@media (min-width: 600px)': {
          minHeight: '50px'
        }
      },
      gutters: {
        '@media (min-width: 600px)': {
          paddingRight: "5px",
        }
      }
    },
    MuiAppBar: {
      root: {
        height: '50px',

      },
      colorPrimary: {
        backgroundColor: '#fff'
      }
    },
    MuiAvatar: {
      root: {
        width: "24px",
        height: "24px",
        fontSize: "13px",
        color: "black"
      }
    },
    MuiIconButton: {
      root: {
        padding: "0"
      }
    },
    MuiTab: {
      root: {
        fontSize: "14px",
        fontFamily: "Roboto",
        opacity: 0.87

      },
      textColorPrimary: {
        color: "#000000"
      }
    },
    // @ts-ignore
    Typography: {
      button: {
        textTransform: 'none'
      }
    }
  }
});

export default rizkiTheme;