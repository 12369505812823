import { isNotEmpty, notLongerThan } from './validators';

export const invoiceName = {
    valid: (value) => {
        return isNotEmpty(value) && notLongerThan(value, 100)
    }
}

export const invoiceCompanyName = {
    valid: (value) => {
        return notLongerThan(value, 200)
    }
}

export const invoiceAddress = {
    valid: (value) => {
        return isNotEmpty(value) && notLongerThan(value, 200)
    }
}

export const invoiceZip = {
    valid: (value) => {
        return isNotEmpty(value) && notLongerThan(value, 15)
    }
}

export const invoiceCity = {
    valid: (value) => {
        return isNotEmpty(value) && notLongerThan(value, 100)
    }
}

export const invoiceVatNumber = {
    valid: (value) => {
        return notLongerThan(value, 20)
    }
}
