import { isEmpty } from "../utils/isEmpty";

export const isNotEmpty = (value) => {
    if (typeof value === 'string') {
        return !isEmpty(value.trim());
    }
    return !isEmpty(value);
}

export const notLongerThan = (value, limit) => {
    if (value === undefined || value === null || typeof value !== 'string') {
        return false;
    }
    return value.length <= limit;
}

const isFloat = (val) => {
    let floatRegex = /^-?\d+(?:[.,]\d{1,2}?)?$/;
    if (!floatRegex.test(val))
        return false;

    val = parseFloat(val);
    if (isNaN(val))
        return false;
    return true;
}

const isInt = (val) => {
    let intRegex = /^-?\d+$/;
    if (!intRegex.test(val))
        return false;

    let intVal = parseInt(val, 10);
    return parseFloat(val) === intVal && !isNaN(intVal);
}

export const isANumber = (value) => {
    return (isFloat(value) || isInt(value));
}

export const isGreaterThanZero = (value) => {
    let val;
    if (isInt(value)) {
        val = parseInt(value, 10);
        if (Number.isNaN(val) || val === undefined || val === null) {
            return false;
        }
        return val > 0;
    }
    else if (isFloat(value)) {
        val = parseFloat(value);
        if (Number.isNaN(val) || val === undefined || val === null) {
            return false;
        }

        return val > 0.0;
    }
    else {
        return false;
    }
}