import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import { useContext } from "react";
import Moment from "moment";
import { ModalContext } from "../contexts/ModalContext";
import { isRiskHistory } from "../RiskDetails/riskHistory";

const calcVoteDistance = (radius) => Math.sqrt(Math.pow(radius, 2) / 2);
const bubbleSizes = [32, 46, 60, 74];
const voteDistance = bubbleSizes.map(calcVoteDistance);

type RiskMatrixSvgType = {
  matrixData: any;
  selectedRisks: any;
  pickedRisk?: Array<any>;
  includeRiskIdToExport?: boolean;
  animation?: boolean;
};

const RiskMatrixSvg = ({
  matrixData,
  selectedRisks,
  pickedRisk = [],
  includeRiskIdToExport,
  animation = false,
}: RiskMatrixSvgType) => {
  const [modalProps, setModalProps] = useContext(ModalContext);

  const clickOnRiskDot = (risk, selectIndex) => {
    setModalProps({
      ...modalProps,
      riskDetailModal: {
        show: true,
        risk: risk,
        selectIndex: selectIndex + 1,
        history: risk.activity ? risk : null,
      },
    });
  };

  const cellWidth = 400;
  const cellHeight = 300;
  const cellXLimit = 4;
  const voteSize = 16;
  const maximumRisksPerSquare = 12;
  const textSize = 20;

  const xAxisContent = [
    "Impact",
    "VERY LOW",
    "LOW",
    "MEDIUM",
    "HIGH",
    "VERY HIGH",
  ];
  const yAxisContent = [
    "Probability",
    "VERY LOW",
    "LOW",
    "MEDIUM",
    "HIGH",
    "VERY HIGH",
  ];
  const colorData = [
    [null, "#a7af41", "#d4b23d", "#dda03a", "#d98335", "#dd5b37"],
    [null, "#95ad44", "#c1b13e", "#dfaa3d", "#db8d37", "#d87335"],
    [null, "#85a947", "#b3b03f", "#e0b43e", "#dd9939", "#d97e36"],
    [null, "#73a74b", "#9ead41", "#cdb13e", "#dea43a", "#da8836"],
    [null, "#57aa5f", "#8fab45", "#bab03e", "#dfaf3d", "#db9238"],
    [null, null, null, null, null, null],
  ];

  const svgWidth =
    (matrixData.length > 0 ? matrixData.length - 1 : 5) * cellWidth + 170;
  const svgHeight =
    (matrixData.length > 0 ? matrixData[0].length - 1 : 5) * cellHeight + 150;

  const ref = useRef();

  const getRiskType = (d: any): number => {
    if (d.risk == null) {
      return 1;
    } 
    const riskType = d.risk.risk ? d.risk.risk.riskType : d.risk.riskType;

    return riskType;
  };

  const isAnimation = (d: any): boolean => {
    if (d.risk == null) {
      return false;
    }
    return d.risk.risk ? d.risk.risk.changed : d.risk.changed;
  }

  useEffect(() => {
    const matrix = d3
      .select(ref.current)
      .attr("id", "svg")
      .attr("viewBox", `-30, -15, ${svgWidth}, ${svgHeight}`)
      .attr("fill", "white");

    const drawGrid = (matrixContent) => {
      const rows = matrix
        .select("#gridArea")
        .selectAll(".row")
        .data(matrixContent)
        .join("g")
        .attr("class", "row")
        .attr("id", function (d, i) {
          return i;
        });

      const columns = rows
        .selectAll(".square")
        .data(function (d: any) {
          return d;
        })
        .join("rect")
        .attr("class", "square")
        .attr("x", function (d, i) {
          return i * cellWidth + 100;
        })
        .attr("y", function (d, i) {
          //@ts-ignore
          return this.parentNode.id * cellHeight;
        })
        .attr("width", cellWidth)
        .attr("height", cellHeight)
        .attr("id", function (d, i) {
          //@ts-ignore
          return `${this.parentNode.id} ${i}`;
        })
        .attr("fill", "white")
        .attr("stroke", "grey")
        .attr("stroke-width", "0.25px");
    };

    const drawXAxis = (axisContent) => {
      const xScale = d3
        .scaleLinear()
        .domain([0, axisContent.length - 2])
        .range([0, (axisContent.length - 2) * cellWidth]);

      const x_axis = d3
        //@ts-ignore
        .axisBottom()
        .scale(xScale)
        .ticks(axisContent.length - 2)
        .tickFormat((x: number) => `${axisContent[x + 1]}`);

      matrix
        .select("#xAxisArea")
        .attr(
          "transform",
          `translate(${cellWidth / 2 + 100}, ${
            cellHeight * (axisContent.length - 1) + 10
          })`
        )
        .style("font", `${textSize}px arial`)
        .call(x_axis)
        .call((g) => g.select(".domain").remove())
        .call((g) => g.selectAll("line").remove())
        .select("#xAxisLabel")
        .attr("fill", "black")
        .attr("font-weight", "bolder")
        .attr(
          "transform",
          `translate(${
            (axisContent.length - 1) * cellWidth - cellWidth / 2
          }, 17)`
        )
        .text(axisContent[0]);
    };

    const drawYAxis = (axisContent) => {
      const yScale = d3
        .scaleLinear()
        .domain([0, axisContent.length - 2])
        .range([(axisContent.length - 2) * cellHeight, 0]);

      const y_axis = d3
        //@ts-ignore
        .axisLeft()
        .scale(yScale)
        .ticks(axisContent.length - 2)
        .tickFormat((x: number) => `${axisContent[x + 1]}`);

      matrix
        .select("#yAxisArea")
        .attr("transform", `translate(${100 - 10}, ${cellHeight / 2})`)
        .style("font", `${textSize}px arial`)
        .call(y_axis)
        .call((g) => g.select(".domain").remove())
        .call((g) => g.selectAll("line").remove())
        .select("#yAxisLabel")
        .attr("fill", "black")
        .attr("font-weight", "bolder")
        .attr("transform", `translate(-8, ${-cellHeight / 2})`)
        .text(axisContent[0]);

      matrix.selectAll(".tick").attr("color", "grey");
    };

    const getXPositionForCircle = (node, index) => {
      index = index % cellXLimit;
      let children = Number.parseInt(
        node.parentNode.parentNode.getAttribute("riskCount")
      );
      children = children > cellXLimit ? cellXLimit : children;
      return (index * cellWidth) / (children + 1) + cellWidth / (children + 1);
    };

    const getYPositionForCircle = (node, index) => {
      const rows = Math.ceil(
        Number.parseInt(node.parentNode.parentNode.getAttribute("riskCount")) /
          cellXLimit
      );
      const row = Math.floor(index / cellXLimit);
      return (row * cellHeight) / (rows + 1) + cellHeight / (rows + 1);
    };

    const getRadiusForCircle = (risk) => {
      const cost = risk.risk.cost ? risk.risk.cost : risk.risk.risk.cost;
      return cost <= 10000
        ? bubbleSizes[0]
        : cost <= 25000
        ? bubbleSizes[1]
        : cost <= 50000
        ? bubbleSizes[2]
        : cost > 50000
        ? bubbleSizes[3]
        : bubbleSizes[0];
    };

    const getDistance = (risk) => {
      const cost = risk.risk.cost ? risk.risk.cost : risk.risk.risk.cost;
      return cost <= 10000
        ? voteDistance[0]
        : cost <= 25000
        ? voteDistance[1]
        : cost <= 50000
        ? voteDistance[2]
        : cost > 50000
        ? voteDistance[3]
        : voteDistance[0];
    };

    const drawBubbles = (matrixContent, matrixColor) => {
      matrix
        .select("#bubbleArea")
        .selectAll(".bubbleRow")
        .data(matrixContent)
        .join("g")
        .attr("class", "bubbleRow")
        .attr("id", function (d, i) {
          return i;
        })
        .attr("transform", function (d, i) {
          return `translate(${100}, ${i * cellHeight})`;
        })
        .selectAll(".bubbleSquare")
        .data(function (d: any) {
          return d;
        })
        .join("g")
        .attr("class", "bubbleSquare")
        .attr("transform", function (d, i) {
          return `translate(${i * cellWidth}, 0)`;
        })
        .attr("riskCount", function (d: any) {
          if (d == null) {
            return 0;
          } else {
            return d.length > maximumRisksPerSquare
              ? maximumRisksPerSquare
              : d.length;
          }
        })
        .attr("id", function (d, i) {
          return i;
        })
        .selectAll(".bubbleCircle")
        .data(function (d: any) {
          if (d == null) {
            return [];
          } else {
            return d.slice(0, maximumRisksPerSquare);
          }
        })
        .join(function (d) {
          const enter = d.append("g");
          
          enter
            .attr("id", (d: any) => d.risk.name)
            .attr("data-probability", (d: any) => d.risk.probability)
            .attr("data-impact", (d: any) => d.risk.impact);

          enter
            .append("circle")
            .attr("class", "bubbleCircle")
            .attr("id", function (d: any, i) {
              const createdAt = d.risk.createdAt ? d.risk.createdAt : i;
              const id = Moment(createdAt).format("DDMM");
              return "C" + id;
            })
            .attr("cx", function (d, i) {
              return getXPositionForCircle(this, i);
            })
            .attr("cy", function (d, i) {
              return getYPositionForCircle(this, i);
            })
            .attr("r", function (d) {
              return getRadiusForCircle(d);
            })
            .attr("fill", function (d, i) {
              return matrixColor[
                //@ts-ignore
                this.parentNode.parentNode.parentNode.id
                //@ts-ignore
              ][this.parentNode.parentNode.id];
            })
            .attr("stroke", function (d: any) {
              return getRiskType(d) == 2 ? "red" : "";
            })
            .attr("fill-opacity", "0.75");

          enter
            .append("text")
            .attr("fill", "white")
            .attr("x", function (d, i) {
              return getXPositionForCircle(this, i);
            })
            .attr("y", function (d, i) {
              return getYPositionForCircle(this, i);
            })
            .attr("text-anchor", "middle")
            .attr("alignment-baseline", "central")
            .attr("font-size", "23px")
            .text(function (d: any) {
              return includeRiskIdToExport == null ||
                includeRiskIdToExport === false
                ? ""
                : d.riskNumber;
            });

          enter
            .append("circle")
            .attr("class", "voteNumberCircle")
            .attr("id", function (d: any, i) {
              const createdAt = d.risk.createdAt ? d.risk.createdAt : i;
              const id = Moment(createdAt).format("DDMM");
              return "V" + id;
            })
            .attr("cx", function (d, i) {
              return getXPositionForCircle(this, i) + getDistance(d);
            })
            .attr("cy", function (d, i) {
              return getYPositionForCircle(this, i) - getDistance(d);
            })
            .attr("r", function (d) {
              return voteSize;
            })
            .attr("fill", function (d, i) {
              return matrixColor[
                //@ts-ignore
                this.parentNode.parentNode.parentNode.id
                //@ts-ignore
              ][this.parentNode.parentNode.id];
            })
            .attr("stroke", function (d: any) {
              return d.risk.votesCount > 0 ? "white" : "none";
            })
            .attr("stroke-width", 4)
            .attr("fill-opacity", function (d: any) {
              return d.risk.votesCount > 0 ? "1" : "0";
            });

          enter
            .append("text")
            .attr("fill", "white")
            .attr("x", function (d, i) {
              return getXPositionForCircle(this, i) + getDistance(d);
            })
            .attr("y", function (d, i) {
              return getYPositionForCircle(this, i) - getDistance(d);
            })
            .attr("text-anchor", "middle")
            .attr("alignment-baseline", "central")
            .attr("font-size", "16px")
            .text(function (d: any) {
              return d.risk.votesCount > 0 ? d.risk.votesCount : "";
            });

          function riskChanged() {
            enter
              .filter((d: any) => {
                return isAnimation(d);
              })
              .transition()
              .duration(1000)
              .ease(d3.easeLinear)
              .attr("transform", function () {
                return `translate(0, ${-30})`;
              })
              .transition()
              .duration(1000)
              .ease(d3.easeBounce)
              .attr("transform", function () {
                return `translate(0, 0)`;
              })
              .on("end", riskChanged);
          }
          if (animation && selectedRisks.length === 0) {
            riskChanged();
          }

          return enter;
        })
        .on("click", function (event, d: any) {
          clickOnRiskDot(d.risk, d.index);
        })
        .selectAll("title")
        .data(function (d: any) {
          return [d.risk.risk ? d.risk.risk : d.risk];
        })
        .join("title")
        .text(function (d) {
          return `Name: ${
            d.name
          }\nRisk Costs: ${d.cost}\nRisk Type: ${d.riskType === 1 ? "Potential" : "Already occurred"}\nTrend: ${d.trend === 1 ? "Ascending" : "Falling"}\nVotes: ${d.votesCount}`;
        });
    };

    const drawOpaqueBubbles = (matrixContent) => {
      matrix
        .select("#opaqueBubbleArea")
        .selectAll(".bubbleRow")
        .data(matrixContent)
        .join("g")
        .attr("class", "bubbleRow")
        .attr("id", function (d, i) {
          return i;
        })
        .attr("transform", function (d, i) {
          return `translate(${100}, ${i * cellHeight})`;
        })
        .selectAll(".bubbleSquare")
        .data(function (d: any) {
          return d;
        })
        .join("g")
        .attr("class", "bubbleSquare")
        .attr("transform", function (d, i) {
          return `translate(${i * cellWidth}, 0)`;
        })
        .attr("riskCount", function (d: any) {
          if (d == null) {
            return 0;
          } else {
            return d.length > maximumRisksPerSquare
              ? maximumRisksPerSquare
              : d.length;
          }
        })
        .attr("id", function (d, i) {
          return i;
        })
        .selectAll(".bubbleCircle")
        .data(function (d: any) {
          if (d == null) {
            return [];
          } else {
            return d.slice(0, maximumRisksPerSquare);
          }
        })
        .join(function (d) {
          let enter = d.append("g");

          enter
            .append("circle")
            .attr("class", "bubbleCircle")
            .attr("cx", function (d, i) {
              return getXPositionForCircle(this, i);
            })
            .attr("cy", function (d, i) {
              return getYPositionForCircle(this, i);
            })
            .attr("r", function (d) {
              return getRadiusForCircle(d);
            })
            .attr("fill", "white")
            .attr("stroke", "white")
            .attr("fill-opacity", "1");
          return enter;
        });
    };

    const getTranslateOfTransform = (transform: string) => {
      return transform
        .substring(transform.indexOf("(") + 1, transform.indexOf(")"))
        .split(",");
    };

    const getTranslatetCirclePosition = (circle) => {
      const firstParent = circle.select(function () {
        return this.parentNode.parentNode;
      });
      const secondParent = firstParent.select(function () {
        return this.parentNode;
      });

      const firstParentTranslate = getTranslateOfTransform(
        firstParent.attr("transform")
      );
      const secondParentTranslate = getTranslateOfTransform(
        secondParent.attr("transform")
      );

      return [
        Number.parseInt(firstParentTranslate[0]) +
          Number.parseInt(secondParentTranslate[0]) +
          Number.parseInt(circle.attr("cx")),
        Number.parseInt(firstParentTranslate[1]) +
          Number.parseInt(secondParentTranslate[1]) +
          Number.parseInt(circle.attr("cy")),
      ];
    };

    const drawArrows = () => {
      const rules = [];

      if (pickedRisk.length > 0) {
        for (let index = 0; index < pickedRisk.length - 1; index++) {
          rules.push([pickedRisk[index], pickedRisk[index + 1]]);
        }
      }

      let links = rules.map(function (rule) {
        return {
          source: "C" + Moment(rule[0].createdAt).format("DDMM"),
          target: "C" + Moment(rule[1].createdAt).format("DDMM"),
        };
      });

      if (selectedRisks != null && selectedRisks.length > 1) {
        links = [];
      }

      const arrowPoints: Array<[number, number]> = [
        [0, 0],
        [0, 20],
        [20, 10],
      ];

      matrix
        .select("#defs")
        .selectAll(".arrow")
        .data(links)
        .join("marker")
        .attr("class", "arrow")
        .attr("id", function (d) {
          return "L" + d.source;
        })
        .attr("viewBox", "0 0 30 30")
        .attr("refX", function (d) {
          const circle = matrix.select(`#${d.target}`);
          return Number.parseInt(circle.attr("r")) + 20;
        })
        .attr("refY", 10)
        .attr("markerUnits", "strokeWidth")
        .attr("markerWidth", 30)
        .attr("markerHeight", 30)
        .attr("orient", "auto")
        .append("path")
        .attr("d", d3.line()(arrowPoints))
        .style("fill", "black");

      matrix
        .select("#arrowArea")
        .selectAll(".link")
        .data(links)
        .join("line")
        .attr("class", "link")
        .style("stroke-width", 1)
        .style("stroke", "black")
        .attr("marker-end", function (d) {
          return `url(#L${d.source})`;
        })
        .attr("x1", function (d) {
          const circle = matrix.select(`#${d.source}`);
          return getTranslatetCirclePosition(circle)[0];
        })
        .attr("y1", function (d) {
          const circle = matrix.select(`#${d.source}`);
          return getTranslatetCirclePosition(circle)[1];
        })
        .attr("x2", function (d) {
          const circle = matrix.select(`#${d.target}`);
          return getTranslatetCirclePosition(circle)[0];
        })
        .attr("y2", function (d) {
          const circle = matrix.select(`#${d.target}`);
          return getTranslatetCirclePosition(circle)[1];
        });
    };

    const drawMatrix = () => {
      const matrixContent = matrixData
        .slice(0, 5)
        .map((arr) => arr.slice(1, 6));
      const matrixColor = colorData.slice(0, 5).map((arr) => arr.slice(1, 6));

      drawGrid(matrixContent);
      drawXAxis(xAxisContent);
      drawYAxis(yAxisContent);
      drawOpaqueBubbles([]);
      drawBubbles([], matrixColor);
      drawOpaqueBubbles(matrixContent);
      drawBubbles(matrixContent, matrixColor);
      drawArrows();
    };

    drawMatrix();
  }, [matrixData]);

  return (
    <svg ref={ref} className={includeRiskIdToExport == null ? "" : "exportSVG"}>
      <g id="gridArea"></g>
      <g id="xAxisArea">
        <text id="xAxisLabel"></text>
      </g>
      <g id="yAxisArea">
        <text id="yAxisLabel"></text>
      </g>
      <g id="arrowArea">
        <defs id="defs"></defs>
      </g>
      <g id="opaqueBubbleArea"></g>
      <g id="bubbleArea"></g>
    </svg>
  );
};

export default RiskMatrixSvg;
