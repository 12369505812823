import ApolloClient, { gql } from 'apollo-boost';
import config from '../config/config'
import { TokenProvider } from '../utils/tokenProvider';
import { Client } from '../utils/client';
import { createError, onResponse } from './grapqhlResponse';
import { User } from '../components/contexts/user';

export class AuthService {

  private readonly client: ApolloClient<any>;
  private readonly url: string;
  private readonly tokenProvider = TokenProvider();

  constructor() {
    this.url = config.server.apiUrl;
    this.client = new ApolloClient({
      uri: this.url, onError: error => console.log("GraphQL Error: " + JSON.stringify(error))
    });
  }

  authorizedClient(): ApolloClient<any> {
    return new Client().createApolloClient();
  }

  toUserInfo(userInfo): User {
    return {
      ...userInfo,
      subscriptionValidUntil: userInfo.subscriptionValidUntil ? new Date(userInfo.subscriptionValidUntil) : undefined
    }
  }

  refreshToken() {
    const refreshT = gql`
            mutation {
              refreshToken
            }
        `;
    return this.authorizedClient().mutate<{ refreshToken: any }>({
      mutation: refreshT,
      errorPolicy: 'all',
      fetchPolicy: "no-cache"
    })
      .then(response =>
        onResponse(response, () => {
          this.tokenProvider.setToken(response.data.refreshToken);
          return this.toUserInfo(this.tokenProvider.getUserInfo());
        })
      ).catch(createError);
  }

  authenticate(email: string, password: string) {
    const authenticate = gql`
            mutation {
              authenticate(email: "${email}", password: "${password}")
            }
        `;

    return this.client.mutate<{ authenticate: any }>({ mutation: authenticate, errorPolicy: 'all' })
      .then(response =>
        onResponse(response, () => {
          this.tokenProvider.setToken(response.data.authenticate);
          return this.toUserInfo(this.tokenProvider.getUserInfo());
        })
      ).catch(createError);
  }
}