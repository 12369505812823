import React, { useState, useEffect, useContext } from 'react'
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { UserService } from '../services/userService';
import CustomAvatar from './Avatar/CustomAvatar';
import UploadAvatar from './Avatar/uploadAvatar';
import { userIdentification } from './Avatar/userIdentification';
import { SnackbarContext } from './contexts/SnackbarContext';
import { UserContext } from './contexts/UserContext';
import { AuthService } from '../services/authService';
import { handleError, logError } from '../services/grapqhlResponse';
import { useForm } from 'react-hook-form';
import { InputField } from './forms/inputField';
import fieldsMatch from '../utils/form/validators/fieldsMatch';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
        },
        width: "40%",
    },
    textStyle: {
        color: "#7B7B7B",
        fontFamily: "Roboto",
        fontSize: "10px",
        letterSpacing: "1.5px",
        lineHeight: "16px",
        opacity: "0.87",
        textAlign: "center"
    },
    textFieldStyle: {
        width: "100%",
        color: "#7B7B7B",
        fontFamily: "Roboto",
        fontSize: "16px",
        letterSpacing: "-0.15px",
        lineHeight: "24px",
        textAlign: "left"
        // margin: "6px",
    },
    formGrid: {
        //padding: "3%",
        maxWidth: "inherit%",
        textAlign: "center",
    },
    buttonGrid: {
        width: "100%",
    },
    buttonAvatarStyle: {
        color: "#0869FF",
        fontFamily: "Roboto",
        fontSize: "14px",
        letterSpacing: "0.75px",
        lineHeight: "17px",
        textAlign: "center",
        width: '80%',
        height: '48px'
    },
    avatarImageStyle: {
        display: 'flex'
    },
    avatarSize: {
        width: theme.spacing(15),
        height: theme.spacing(15),
        fontSize: "40px"
    },
    input: {
        display: 'none',
    },

}));

const EditProfile = ({ onClosePopup, saveProfile, setSaveProfile }) => {

    const [user, setUser] = useContext(UserContext);
    const userService = new UserService();
    const classes = useStyles();
    const userID = user.user_id;

    const [photoUrl, setPhotoUrl] = useState("");

    const [uploadAvatarModalOpen, setUploadAvatarModalOpen] = useState(false);
    const [reloadImage, setReloadImage] = useState(false)
    const [, setClicked] = useState(false);
    const { register, handleSubmit, errors, getValues } = useForm();
    const [message, setMessage] = React.useState({ error: false, text: '' });

    useEffect(() => {
        if (userID) {
            const getUserById = () => {
                userService.getUserById()
                    .then(response => {
                        setPhotoUrl(response.photoUrl)
                    }).then(() => refreshToken()).catch(logError);
            }
            getUserById();
        }
    }, [])

    useEffect(() => {
        const saveChanges = () => {
            if (saveProfile) {
                setSaveProfile(false);
                setClicked(false);
            }
        }
        saveChanges();

    }, [saveProfile]);

    const [, notifyUser] = useContext(SnackbarContext);

    const handleReloadImage = () => {
        setReloadImage(!reloadImage);
    }

    const handleOpenUploadAvatarModal = () => {
        setUploadAvatarModalOpen(true);
    }

    const refreshToken = () => {
        return new AuthService().refreshToken().then(u => {
            setUser({ ...u });
        }).catch(err => {
            notifyUser({
                show: true,
                type: "error",
                message: `Token couldnt be refreshed.`
            });
        });
    }

    const onSubmit = (data) => {
        if (isNullOrEmpty(data.password1)) {
            const temp: { userName?: string, photoUrl?: string } = {
                userName: data.name,
                photoUrl: photoUrl
            }

            return userService.updateProfilWithOutPassword(temp)
                .then(() => notifyUser({
                    show: true,
                    type: "success",
                    message: "Saved Successfully"
                })).then(() => refreshToken()).catch(logError);

        }

        return verifyPassword(data.currentPassword)
            .then(response => {
                if (response) {
                    setMessage({ error: true, text: "" });
                    return userService.updateProfilWithPassword(
                        {
                            userName: data.name,
                            photoUrl: photoUrl,
                            oldPassword: data.currentPassword,
                            newPassword: data.password1
                        }
                    )
                        .then(() => notifyUser({
                            show: true,
                            type: "success",
                            message: "Saved Successfully"
                        }))
                        .then(() => refreshToken())
                        .catch(error => notifyUser({
                            show: true,
                            type: "error",
                            message: error.message
                        }));
                }

                setMessage({ error: true, text: "The current password is wrong" });
            }).catch(logError);
    };


    const verifyPassword = (password: string): Promise<boolean> => {
        return new Promise<boolean>(resolve => resolve(!isNullOrEmpty(password)));
    };

    const notOnlyWhiteSpaces = (value: string) => {
        if (value == null || value === "") {
            return true;
        }

        if (value.trim() === "") {
            return false
        }

        return true;
    };

    const checkIfPasswordsAreEmpty = () => {
        if (!isNullOrEmpty(getValues("password1")) &&
            !isNullOrEmpty(getValues("password2")) &&
            isNullOrEmpty(getValues("currentPassword"))) {
            return false;
        }

        return true;
    };

    const isNullOrEmpty = (value: string) => {
        if (value == null || value.trim() === "") {
            return true;
        }
        return false;
    };


    return (
        // @ts-ignore
        <div style={{ textAlign: "-webkit-center" }}>
            <form id='profile-form' onSubmit={handleSubmit(onSubmit)} noValidate>
                <Box width="90%" marginTop="3%" display="flex" flexDirection="row" justifyContent="center" >
                    <Box width="33%">
                        <Grid container className={classes.formGrid} spacing={2}>
                            <Grid item xs={12} >
                                <Typography className={classes.textStyle} component="h4">
                                    <br />
                                    PERSONAL INFORMATION
                             </Typography>
                            </Grid>
                            <br /><br /><br /><br />
                            <Grid item xs={12}>
                                <InputField
                                    defaultValue={user.name}
                                    name="name"
                                    label={"Name"}
                                    inputRef={register({
                                        maxLength: 50,
                                        validate: {
                                            onlyWhiteSpaces: () => notOnlyWhiteSpaces(getValues("name")) || "A name cannot be empty"
                                        },
                                        pattern: {
                                            value: /^[^\\\"/´`]*$/,
                                            message: 'A name cannot contain these symbols: \\"/´`'
                                        }
                                    })}
                                    errors={errors}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    disabled
                                    className={classes.textFieldStyle}
                                    id="emailAdress"
                                    label="Email Address"
                                    variant="outlined"
                                    value={user.email}
                                />
                            </Grid>
                        </Grid>
                        <span className={classes.textFieldStyle}>
                            <br /><br /><br />
                        To change the email address please contact <a href={"mailto:support@rizki.at"}>support</a>.
                        </span>
                    </Box>
                    <Box width="33%">
                        <br /> <br /> <br /><br />
                        {/* 
                    // @ts-ignore */}
                        <Grid container justifyContent="center" display="flex">
                            <Grid item xs={12} >
                                <div className={classes.avatarImageStyle} style={{ justifyContent: "center" }} >
                                    <CustomAvatar
                                        imageId={userID}
                                        avatarStyle={classes.avatarSize}
                                        reloadImage={reloadImage}
                                        tooltip={userIdentification({ email: user.email, name: user.name })} />
                                </div>
                            </Grid>

                            <Grid item xs={12} style={{ textAlign: "center" }}>
                                <label htmlFor="contained-button-file">
                                    <Button className={classes.buttonAvatarStyle} fullWidth
                                        onClick={handleOpenUploadAvatarModal}>UPLOAD AVATAR</Button>
                                </label>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <UploadAvatar
                                openUploadAvatarModal={uploadAvatarModalOpen}
                                setUploadAvatarModalOpen={setUploadAvatarModalOpen}
                                handleReloadImage={handleReloadImage}
                                user={{ id: user.user_id, email: user.email, name: user.name }} />
                        </Grid>

                    </Box>
                    <Box width="33%" >
                        <Grid container className={classes.formGrid} spacing={2}>
                            <Grid item xs={12} >
                                <Typography className={classes.textStyle} component="h4">
                                    <br />
                                   PASSWORD CHANGE
                             </Typography>
                                <Typography style={{ color: message.error ? 'red' : 'green' }}>
                                    <br />
                                    {message.text}
                                </Typography>
                            </Grid>
                            <br /><br /><br /><br />
                            <Grid item xs={12}>
                                <InputField
                                    name="currentPassword"
                                    label="Current Password"
                                    isPassword={true}
                                    errors={errors}
                                    inputRef={register({
                                        maxLength: 100,
                                        validate: {
                                            passwordRequired: () => checkIfPasswordsAreEmpty() || "Current password required"
                                        },
                                        pattern: {
                                            value: /^[^\\\"/´`]*$/,
                                            message: 'A password cannot contain these symbols: \\"´/`'
                                        }
                                    })}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputField
                                    name="password1"
                                    label="New Password"
                                    isPassword={true}
                                    errors={errors}
                                    inputRef={register({
                                        maxLength: 50,
                                        validate: {
                                            passwordDifferentFromOld: () =>
                                                (isNullOrEmpty(getValues("password1")) ||
                                                    !fieldsMatch(getValues("password1"), getValues("currentPassword")))
                                                || "New password cannot be same as current"
                                        },
                                        pattern: {
                                            value: /^(?=.*\d)(?=.*[A-Za-z])[A-Za-z\d$%&§]{8,20}$/,
                                            message: 'The password must contain 8 - 20 characters and at least one letter and one number. Allowed special characters are  $, %, &, §.'
                                        }
                                    })}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputField
                                    name="password2"
                                    label="Confirm Password"
                                    isPassword={true}
                                    errors={errors}
                                    inputRef={register({
                                        maxLength: 50,
                                        validate: {
                                            passwordsMatch: () =>
                                                fieldsMatch(getValues("password1"), getValues("password2"))
                                                || "New passwords don't match"
                                        },
                                        pattern: {
                                            value: /^(?=.*\d)(?=.*[A-Za-z])[A-Za-z\d$%&§]{8,20}$/,
                                            message: 'The password must contain 8 - 20 characters and at least one letter and one number. Allowed special characters are  $, %, &, §.'
                                        }
                                    })}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </form>
        </div>
    )
}
export default EditProfile;