import React, { useState, useContext, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import SubscriptionService from "../../services/subscriptionService";
import { UserService } from "../../services/userService";
import { ModalContext } from "../contexts/ModalContext";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import "./styles.css";
import { SnackbarContext } from "../contexts/SnackbarContext";
import { PaymentInfo } from "./paymentInfo";
import { ResponseError, logError } from "../../services/grapqhlResponse";
import { UserContext } from "../contexts/UserContext";
import useLoadingEffect from "../load/useLoadingEffect";
import RizkiLoadingEffect from "../load/RizkiLoadingEffect";
import { TokenProvider } from "../../utils/tokenProvider";
import { useForm } from "react-hook-form";
import { InputField } from "../forms/inputField";
import required from "../../utils/form/validators/isRequired";
import { SubscriptionWorkflowContext } from "../Subscription/SubscriptionWorkflowContext";
import { SubscriptionType } from "../Subscription/subscriptionType";
import stepToModal from "../Subscription/stepToModal";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {
    width: "400px",
    paddingLeft: "7%",
    paddingRight: "7%",
    paddingTop: "7%",
    paddingBottom: "5%",
  },
  dialogContent: {
    display: "flex",
  },
  price: {
    display: "flex",
    height: "45px",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid rgba(204, 204, 204, 1)",
    color: "#000000",
    fontFamily: "Roboto",
    fontSize: "18px",
    letterSpacing: "0.4px",
    lineHeight: "24px",
    opacity: 0.87,
  },
  errorMessage: {
    display: "flex",
    height: "45px",
    justifyContent: "center",
    alignItems: "center",
    color: "#f70909de",
    fontFamily: "Roboto",
    fontSize: "16px",
    letterSpacing: "0.4px",
    lineHeight: "24px",
    opacity: 0.87,
  },
  textFieldStyle: {
    width: "100%",
    color: "#7B7B7B",
    textAlign: "left",
    fontSize: "18px",
    letterSpacing: "0.4px",
    lineHeight: "30px",
    fontFamily: "Roboto",
  },
}));

const useOptions = () => {
  return {
    style: {
      base: {
        fontSize: "18px",
        color: "#000000",
        letterSpacing: "0.4px",
        lineHeight: "30px",
        fontFamily: "Roboto",
        "::placeholder": {
          fontSize: "18px",
          color: "#aab7c4",
          lineHeight: "30px",
        },
      },
      invalid: {
        color: "#f70909de",
      },
    },
  };
};

const StripeModal = () => {
  const classes = useStyles();
  const options = useOptions();
  const tokenProvider = TokenProvider();

  const initialValues = {
    paymentPeriod: "1",
    price: "0",
    acceptTerms: false,
  };

  const [user, setUser] = useContext(UserContext);
  const [modalProps, setModalProps] = useContext(ModalContext);
  const [values, setValues] = useState(initialValues);
  const [amounts, setAmounts] = useState<{ montly?: any; yearly?: any }>({});
  const [isClicked, setClicked] = useState(false);
  const [missing, setMissing] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [, notifyUser] = useContext(SnackbarContext);
  const [paymentInProgress, setPaymentInProgress] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState<Partial<PaymentInfo>>({});
  const [cardElement, setCardElement] = useState(undefined);
  const [cardComplete, setCardComplete] = useState(false);
  const [cardDateComplete, setCardDateComplete] = useState(false);
  const [cvcComplete, setCVCComplete] = useState(false);
  const { register, handleSubmit, errors } = useForm();
  const [isPeriodMonthly, setIsPeriodMonthly] = useState(true);
  const [premiumPartialPrice, setPremiumPartialPrice] = useState(0);
  const [workflow, setWorkflow] = useContext(SubscriptionWorkflowContext);

  const subscriptionService = new SubscriptionService();
  const userService = new UserService();

  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if (workflow == null) {
      return;
    }

    const getAmounts = (isPeriodMonthly?: boolean) => {
      if (workflow.to) {
        subscriptionService
          .getPaymentAmounts(workflow.to)
          .then((response) => {
            setAmounts(response);
            setValues({
              ...values,
              ["price"]:
                isPeriodMonthly == null || isPeriodMonthly
                  ? response.montly
                  : response.yearly,
            });
          })
          .catch(logError);
      }
    };

    const fetchUserPaymentMethod = () => {
      userService
        .fetchUserPaymentMethod()
        .then((response) => {
          const cardNumber = "**** **** **** " + response.response.last4;
          const cardExp =
            response.response.exp_month +
            "/" +
            response.response.exp_year.toString().slice(-2);
          setPaymentInfo({
            cardNumber,
            date: cardExp,
            name: response.response.name,
          });
          setCardComplete(true);
          setCardDateComplete(true);
          setCVCComplete(true);
        })
        .catch(logError);
    };

    const getPartialPremiumPrice = () => {
      return subscriptionService
        .getUpcomingInvoiceDetails(workflow.to)
        .then((response) => {
          setIsPeriodMonthly(response.isPeriodMonthly);
          setPremiumPartialPrice(response.partialPrice);
          return response.isPeriodMonthly;
        });
    };

    fetchUserPaymentMethod();

    if (user.subscriptionType === "BUSINESS") {
      getPartialPremiumPrice()
        .then((isPeriodMonthly) => getAmounts(isPeriodMonthly))
        .catch(logError);
    } else if (user.subscriptionType === "PREMIUM") {
        getPartialPremiumPrice()
        .then((isPeriodMonthly) => getAmounts(isPeriodMonthly))
        .catch(logError);
    } else {
      getAmounts();
    }
  }, [workflow]);

  const [loadingOnChange, callChangeSubscriptionActionWith] = useLoadingEffect(
    (data: any) => changeSubscription(data),
    {
      onSuccess: () => onSuccessfulAboChange(),
      onError: (error: ResponseError) => handleError(error),
    }
  );

  const [
    loadingOnDowngrade,
    callDowngradeSubscriptionActionWith,
  ] = useLoadingEffect((data: any) => downgradeSubscription(data), {
    onSuccess: () => onSuccessfulAboChange(),
    onError: (error: ResponseError) => handleError(error),
  });

  const onSuccessfulAboChange = () => {
    setValues(initialValues);
    setClicked(false);
    setPaymentInProgress(false);
    notifyUser({
      show: true,
      type: "success",
      message: "Payment Successful",
    });

    setModalProps({
      ...modalProps,
      paymentModal: {
        show: false,
      },
      subscriptionExpiredModal: {
        show: false,
      },
    });
  };

  const handleError = (error: ResponseError) => {
    setErrorMessage(error);
    setPaymentInProgress(false);
    logError(error);
  };

  const updateTokenContext = (token) => {
    tokenProvider.setToken(token);
    setUser(tokenProvider.getUserInfo());
  };

  const changeSubscription = (period) => {
    return subscriptionService
      .changeSubscription(workflow.to, period)
      .then((token) => {
        updateTokenContext(token);
      });
  };

  const downgradeSubscription = (to: SubscriptionType) => {
    return subscriptionService.downgradeSubscription(to).then((token) => {
      updateTokenContext(token);
    });
  };

  const [
    loadingPaymentData,
    callCreatePaymentMethodActionWith,
  ] = useLoadingEffect((data: any) => createPaymentData(data), {
    onSuccess: () => onSuccessfulAboChange(),
    onError: (error: ResponseError) => {
      setClicked(false);
      handleError(error);
    },
  });

  const createPaymentData = (data) => {
    const period = data.period;
    const cardName = data["CardholderName"];

    return new Promise((resolve, reject) => {
      if (cardElement == null) {
        resolve(subscriptionService.updateCreditCardName(cardName));
      } else {
        resolve(
          stripe
            .createPaymentMethod({
              type: "card",
              card: cardElement,
            })
            .then((paymentMethod) => paymentMethod.paymentMethod.id)
            .then((paymentMethodId: string) => {
              setPaymentInProgress(true);
              setErrorMessage(null);
              return stripe
                .createToken(cardElement, { name: cardName })
                .then(({ token }) => {
                  return [token, paymentMethodId];
                });
            })
            .then(([token, paymentMethodId]: [any, string]) => {
              return userService
                .updatePaymentDetails(paymentMethodId, token.id, cardName)
                .then(() => paymentMethodId);
            })
        );
      }
    })
      .then((paymentMethodId: string) => {
        return subscriptionService
          .createSubscription({
            plan: workflow.to,
            period,
            paymentId: paymentMethodId,
            selectedProjects: workflow.steps.includes("PROJECT")
              ? workflow.projects
              : undefined,
            selectedParticipants: workflow.steps.includes("PARTICIPANTS")
              ? workflow.participants
              : undefined,
          })
          .then((token) => [token, paymentMethodId]);
      })
      .then(([token, paymentMethodId]) => {
        updateTokenContext(token);
        return paymentMethodId;
      })
      .catch((error) => {
        setClicked(false);
        setErrorMessage(error.message);
      });
  };

  const handlePayment = (data: any) => {
    if (!stripe || !elements) {
      return;
    }

    setClicked(true);
    if (!values.acceptTerms) {
      setMissing(!missing);
      setClicked(false);
      return;
    }

    if (values.acceptTerms && !isClicked) {
      const period = values.paymentPeriod === "1" ? "monthly" : "yearly";
      const cardelement = elements.getElement(CardNumberElement);
      setCardElement(cardelement);

      if (
        paymentInfo &&
        paymentInfo.cardNumber &&
        paymentInfo.date &&
        paymentInfo.name
      ) {
        if (workflow.type === "DOWNGRADE") {
          callDowngradeSubscriptionActionWith(workflow.to);
        } else {
          callChangeSubscriptionActionWith(period);
        }
      } else {
        callCreatePaymentMethodActionWith({ period, ...data });
      }
    }
  };

  const handleCancel = () => {
    setValues(initialValues);
    setClicked(false);
    const nextStep = workflow.step - 1;
    setWorkflow({
      ...workflow,
      step: nextStep,
    });
    setModalProps({
      ...modalProps,
      paymentModal: {
        show: false,
      },
      [stepToModal(workflow.steps[nextStep])]: {
        ...modalProps[stepToModal(workflow.steps[nextStep])],
        show: true,
        tab: 2,
      },
    });
  };

  const handleChange = (event) => {
    if (event.target.name === "paymentPeriod") {
      setValues({
        ...values,
        ["price"]: event.target.value === "1" ? amounts.montly : amounts.yearly,
        [event.target.name]: event.target.value,
      });
    } else {
      setValues({
        ...values,
        [event.target.name]:
          event.target.name === "acceptTerms"
            ? event.target.checked
            : event.target.value,
      });
    }
  };

  const handleCardNumberChange = (event) => {
    setCardComplete(event.complete);
  };

  const handleCardDateChange = (event) => {
    setCardDateComplete(event.complete);
  };

  const handleCVCChange = (event) => {
    setCVCComplete(event.complete);
  };

  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const hideAboPeriod = (): boolean => {
    const trialEnd = user.subscriptionValidUntil?.getTime();

    let hidden: boolean;

    if (trialEnd <= new Date().getTime()) {
      hidden = false;
    } else {
      user.subscriptionType === "BUSINESS" ||
      user.subscriptionType === "PREMIUM"
        ? (hidden = true)
        : (hidden = false);
    }

    return hidden;
  };

  return (
    <div>
      <form className={classes.form} noValidate>
        <Dialog
          id="stripeModal"
          open={modalProps.paymentModal.show}
          onClose={handleCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="lg"
        >
          <DialogContent className={classes.dialogContent} dividers>
            <div className={classes.root}>
              <Grid container direction="column" spacing={1}>
                <Grid item xs={12}>
                  {errorMessage ? (
                    <span className={classes.errorMessage}>
                      {errorMessage?.message}
                    </span>
                  ) : null}
                </Grid>
                <Grid hidden={hideAboPeriod()} item xs={12}>
                  <RadioGroup
                    row
                    aria-label="position"
                    name="position"
                    defaultValue="top"
                  >
                    <FormControlLabel
                      disabled={
                        loadingOnChange ||
                        loadingPaymentData ||
                        loadingOnDowngrade
                      }
                      name="paymentPeriod"
                      checked={values.paymentPeriod === "1"}
                      value={1}
                      control={<Radio color="primary" />}
                      label="Monthly"
                      labelPlacement="end"
                      onChange={handleChange}
                    />
                    <FormControlLabel
                      disabled={
                        loadingOnChange ||
                        loadingPaymentData ||
                        loadingOnDowngrade
                      }
                      name="paymentPeriod"
                      checked={values.paymentPeriod === "2"}
                      value={2}
                      control={<Radio color="primary" />}
                      label="Yearly"
                      labelPlacement="end"
                      onChange={handleChange}
                    />
                  </RadioGroup>
                </Grid>
                <Grid item xs={12}>
                  <span className={classes.price}>
                    {workflow?.to
                      ? capitalize(workflow.to) + " Plan : " + values.price
                      : 0}{" "}
                    €
                  </span>
                </Grid>
                {user.subscriptionType === "BUSINESS" ? (
                  <Grid
                    item
                    xs={12}
                    style={{
                      color: "#0869ff",
                      fontFamily: "Roboto",
                      textAlign: "center",
                    }}
                  >
                    Note: The price for the rest of this{" "}
                    {isPeriodMonthly ? "month" : "year"} is{" "}
                    {premiumPartialPrice / 100} €
                  </Grid>
                ) : null}

                <Grid item xs={12}>
                  <InputField
                    required={true}
                    name="CardholderName"
                    label="Card holder name"
                    disabled={!paymentInfo.name ? false : true}
                    defaultValue={paymentInfo.name}
                    errors={errors}
                    inputRef={register({
                      required: true,
                      validate: {
                        required: required("Cardholder name"),
                      },
                    })}
                    inputProps={{
                      maxLength: 100,
                      className: classes.textFieldStyle,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  {!paymentInfo.date && !paymentInfo.cardNumber ? (
                    <CardNumberElement
                      options={options}
                      id="cardNumber"
                      onChange={handleCardNumberChange}
                    />
                  ) : (
                    <TextField
                      disabled
                      className={classes.textFieldStyle}
                      id="cardNumber"
                      name="cardNumber"
                      label="Card Number"
                      variant="outlined"
                      value={paymentInfo.cardNumber}
                    />
                  )}
                </Grid>
                <Grid container item spacing={1}>
                  <Grid item xs={6}>
                    {!paymentInfo.date && !paymentInfo.cardNumber ? (
                      <CardExpiryElement
                        options={options}
                        onChange={handleCardDateChange}
                      />
                    ) : (
                      <TextField
                        disabled
                        className={classes.textFieldStyle}
                        id="expirationDate"
                        name="expirationDate"
                        label="Expiration Date"
                        variant="outlined"
                        value={paymentInfo.date}
                      />
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    {!paymentInfo.date && !paymentInfo.cardNumber ? (
                      <CardCvcElement
                        options={options}
                        onChange={handleCVCChange}
                      />
                    ) : null}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        required={true}
                        disabled={
                          loadingOnChange ||
                          loadingPaymentData ||
                          loadingOnDowngrade
                        }
                        checked={values.acceptTerms}
                        onChange={handleChange}
                        name="acceptTerms"
                        color="primary"
                      />
                    }
                    label={
                      <span>
                        <a
                          href="https://www.rizki.at/terms-and-conditions"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Accept Terms
                        </a>{" "}
                        *
                      </span>
                    }
                  />
                </Grid>
              </Grid>
            </div>
          </DialogContent>
          <div
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <RizkiLoadingEffect
              loading={
                loadingOnChange || loadingPaymentData || loadingOnDowngrade
              }
            />
          </div>

          <DialogActions>
            <Button onClick={handleCancel} color="primary" variant="outlined">
              Cancel
            </Button>
            <Button
              onClick={handleSubmit(handlePayment)}
              disabled={
                (!stripe && !paymentInProgress) ||
                loadingOnChange ||
                loadingPaymentData ||
                !values.acceptTerms ||
                !cardComplete ||
                !cardDateComplete ||
                !cvcComplete ||
                loadingOnDowngrade
              }
              color="primary"
              variant="contained"
            >
              PAY
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
};

export default StripeModal;
