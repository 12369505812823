import {
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
    withStyles
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import React from 'react';

const ModalButton = withStyles({
    root: {
        textTransform: 'none',
    },
})(Button);

type ConfirmatationDialogProps = {
    show: boolean,
    title: string,
    confirmationQuestion: string,
    cancelButtonText?: string,
    confirmationButtonText?: string;
    onCancel: () => void,
    onConfirmation: () => void,
}

const ConfirmationDialog = ({
    show,
    title,
    confirmationQuestion,
    cancelButtonText = 'Cancel',
    confirmationButtonText = 'Yes',
    onCancel,
    onConfirmation
}: ConfirmatationDialogProps) => {

    return (
        <Dialog
            open={show}
            onClose={onCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent dividers>
                <DialogContentText id="alert-dialog-description">
                    <Typography gutterBottom>
                        {confirmationQuestion}
                    </Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <ModalButton onClick={onCancel} color="primary" variant="outlined">
                    {cancelButtonText}
                </ModalButton>
                <ModalButton onClick={onConfirmation} color="primary" variant="contained">
                    {confirmationButtonText}
                </ModalButton>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmationDialog;