import { jsPDFOptions } from "jspdf";

const config = {
    jsPdfOptions: {
        orientation: "landscape",
        unit: "mm",
        format: "a4"
    } as jsPDFOptions,
    pdfFileNamePrefix: "RIZKI_risk_matrix_",
    exportComponentIdPrefix: "export-pdf-id-",
    pageDimentions: { // a4 size
        widthInPx: 2000,
        heightInPx: 2000 / 1.4 // 1.4 is the ratio of A4 paper
    },
    riskDotIdPrefix: "export-risk-dot-",
    colorMatrix: [ // TOOD @Baris remove this
        [null, "#a7af41", "#d4b23d", "#dda03a", "#d98335", "#dd5b37"],
        [null, "#95ad44", "#c1b13e", "#dfaa3d", "#db8d37", "#d87335"],
        [null, "#85a947", "#b3b03f", "#e0b43e", "#dd9939", "#d97e36"],
        [null, "#73a74b", "#9ead41", "#cdb13e", "#dea43a", "#da8836"],
        [null, "#57aa5f", "#8fab45", "#bab03e", "#dfaf3d", "#db9238"],
        [null, null, null, null, null, null],
    ],
    colorMatrixWithoutTitles: [
        ["#a7af41", "#d4b23d", "#dda03a", "#d98335", "#dd5b37"],
        ["#95ad44", "#c1b13e", "#dfaa3d", "#db8d37", "#d87335"],
        ["#85a947", "#b3b03f", "#e0b43e", "#dd9939", "#d97e36"],
        ["#73a74b", "#9ead41", "#cdb13e", "#dea43a", "#da8836"],
        ["#57aa5f", "#8fab45", "#bab03e", "#dfaf3d", "#db9238"]
    ],
    colorMatrixWithoutTitlesMirrored: [
        ["#57aa5f", "#8fab45", "#bab03e", "#dfaf3d", "#db9238"],
        ["#73a74b", "#9ead41", "#cdb13e", "#dea43a", "#da8836"],
        ["#85a947", "#b3b03f", "#e0b43e", "#dd9939", "#d97e36"],
        ["#95ad44", "#c1b13e", "#dfaa3d", "#db8d37", "#d87335"],
        ["#a7af41", "#d4b23d", "#dda03a", "#d98335", "#dd5b37"]
    ],
    probabilityColumnNames: ["VERY HIGH", "HIGH", "MEDIUM", "LOW", "VERY LOW"],
    impactRowNames: ["VERY LOW", "LOW", "MEDIUM", "HIGH", "VERY HIGH"]
}

export default config;