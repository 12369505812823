import React, { useContext } from "react";
import Box from "@material-ui/core/Box";
import Moment from "moment";
import { RiskContext } from "../contexts/RiskContext";
import RiskMatrixSvg from "./RiskMatrixSvg";

let initialMatrix = [
  [null, null, null, null, null, null],
  [null, null, null, null, null, null],
  [null, null, null, null, null, null],
  [null, null, null, null, null, null],
  [null, null, null, null, null, null],
  [null, null, null, null, null, null],
];

const calculateMatrixData = (data) => {
  let temp = initialMatrix.map((arr) => {
    return arr.slice();
  });
  if (data && data.length > 0) {
    data.map((risk, index) => {
      let p = risk.changes
        ? -(risk.risk.probability - 5)
        : -(risk.probability - 5);
      let i = risk.changes ? risk.risk.impact : risk.impact;
      if (temp[p][i] === null) {
        temp[p][i] = [];
      }

      risk.changes
        ? temp[p][i].push({
          risk: risk,
          name: Moment(risk.createdAt).format("DD/MM"),
          index: index,
          riskType: "1",
        })
        : temp[p][i].push({
          risk: risk,
          name: risk.name,
          count: risk.votesCount,
          index: index,
          animation: risk.changed,
          riskType: risk.riskType,
        });
    });
  }
  return temp;
};

const RiskMatrix = ({ risks, selectedRisks, includeRiskIdToExport }) => {
  const [pickedRisk] = useContext(RiskContext);
  let matrix = calculateMatrixData(risks);
  if (selectedRisks && selectedRisks.length > 0) {
    if (selectedRisks.length === 1) {
      matrix = calculateMatrixData(pickedRisk);
    } else {
      matrix = calculateMatrixData(selectedRisks);
    }
  }
  return (
    <Box
      id="riskMatrix"
      width="90%"
      height="100%"
      maxHeight="1000px"
      bgcolor="white"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignContent="center"
      padding= "1.5%"
    >
      <RiskMatrixSvg
        matrixData={matrix}
        selectedRisks={selectedRisks}
        pickedRisk={pickedRisk}
        animation={true}
      />
    </Box>
  );
};

export default RiskMatrix;
