import React, { createContext, useState } from 'react';
import { SubscriptionType } from './subscriptionType';
import Workflow, { WorkflowItem, WorkflowType } from './workflow';

type SubscriptionWorkflowContextType = [SubscriptionWorkflow, (n: SubscriptionWorkflow) => void];
type SubscriptionWorkflow = {
    steps: Array<WorkflowItem>,
    type: WorkflowType,
    step: number,
    to: SubscriptionType,
    projects: Array<any>,
    participants: Array<any>
}

const emptySubscriptionWorkflow = {
    type: undefined,
    steps: [],
    step: 0,
    to: undefined,
    projects: [],
    participants: []
}

const SubscriptionWorkflowContext = createContext<SubscriptionWorkflowContextType>([emptySubscriptionWorkflow, () => []]);

const SubscriptionWorkflowContextProvider = ({ children }) => {
    const [workflow, setWorkflow] = useState<SubscriptionWorkflow>();

    return (
        <SubscriptionWorkflowContext.Provider value={[workflow, setWorkflow]}>
            {children}
        </SubscriptionWorkflowContext.Provider>
    );
}

export { SubscriptionWorkflowContext, SubscriptionWorkflowContextProvider };