import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { ModalContext } from '../contexts/ModalContext';

const SubscriptionExpiredModal = () => {
    const [modalProps, setModalProps] = useContext(ModalContext);

    const handleChooseSubscription = () => {
        setModalProps({
            ...modalProps,
            subscriptionExpiredModal: {
                show: true
            },
            subscriptionModal: {
                show: true,
                tab: 2
            }
        });
    }    

    return (
        <div>
            <Dialog
                open={modalProps.subscriptionExpiredModal.show}
                onClose={() => { }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{ "Your " + (modalProps.subscriptionExpiredModal.subscriptionType === "TRIAL" ? "trial" : "subscription") + " period is over" }</DialogTitle>
                <DialogContent dividers>
                    <DialogContentText id="alert-dialog-description">
                        <Typography gutterBottom>
                            Your {modalProps.subscriptionExpiredModal.subscriptionType === "TRIAL" ? "trial" : "subscription"} period is over. You can upgrade or go back to Basic subscription.
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { handleChooseSubscription() }} color="primary" variant="contained">
                        Choose subscription
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default SubscriptionExpiredModal;