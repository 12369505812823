import React, { useState, useEffect } from 'react';
import {Avatar,Tooltip} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { green, red, grey} from '@material-ui/core/colors';
import CustomAvatar from '../Avatar/CustomAvatar';
import {userIdentification} from '../Avatar/userIdentification';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        },
        alignItems: 'center',
    },
    registered: {
        color: grey[900],
        borderColor: green[500],
        width: theme.spacing(3),
        height: theme.spacing(3),
        margin: theme.spacing(1),
    },
    invited: {
        color: grey[900],
        borderColor: red[500],
    },
    iconButton: {
        marginLeft: 0,
        paddingLeft: 0
    },
}));

const RiskiTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'black',
        boxShadow: theme.shadows[1],
        fontSize: 12,
    },
    arrow: {
        color: theme.palette.common.white,
    }
}))(Tooltip);


const RiskVotesAvatarList = ({ riskVotes }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {riskVotes.map(item => {
                // @ts-ignore
                return <div style={{display:"contents"}} spacing={1}>
                    <RiskiTooltip title={item.user.name} arrow>
                    <CustomAvatar imageId={item.userId} className={classes.registered} tooltip={userIdentification(item.user)} />
                </RiskiTooltip> P{item.probability} I{item.impact}
                </div>
            })
            }
        </div>
    );
}

export default RiskVotesAvatarList;