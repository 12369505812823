import React, { useState, useEffect, useContext } from 'react'
import { RiskService } from '../../services/riskService';
import { PagePosition } from '../pagination/pagePosition';
import RizkiTable, { Rows } from '../commons/RizkiTable';
import { RiskHistory } from './riskHistory';
import { emptyPageCount, PageCount } from '../pagination/page';
import { Grid, makeStyles } from '@material-ui/core';
import { logError } from '../../services/grapqhlResponse';

const useStyles = makeStyles((theme) => ({
    content: {
        display: "flex"
    },
    customWidth: {
        width: "55vw",
        paddingLeft: "1%",
        paddingRight: "1%",
        [theme.breakpoints.up('md')]: {
            width: '65vw'
        }
    }
}));

const RiskHistoryList = ({ riskId }) => {
    const classes = useStyles();
    const [riskHistories, setRiskHistories] = useState<PageCount<RiskHistory>>(emptyPageCount());
    const [page, setPage] = useState<PagePosition>({ limit: 10, offset: 0 });

    useEffect(() => {
        new RiskService()
            .getRiskHistoriesByRiskId(riskId, page)
            .then(setRiskHistories)
            .catch(err => logError(err.message));
    }, [riskId, page]);

    const rows: Rows = riskHistories.rows.map(col => (
        [
            { value: col.createdAt, type: 'dd.MM.yyyy HH:mm' },
            { value: [col.userId, col.user.name, null], type: 'Avatar'},
            { value: col.activity, type: 'string' },
        ]
    ));

    return (
        <div className={classes.content}>
            <Grid container xs={12} className={classes.customWidth}>
                <RizkiTable
                    headers={['DATE', 'USER', 'ACTIVITY']}
                    data={{ count: riskHistories.count, rows: rows }}
                    loading={false}
                    queryNewPage={setPage}
                    noDataMessage='No risk history exists'
                    pageSize={10}
                />
            </Grid>
        </div>
    );
}

export default RiskHistoryList;