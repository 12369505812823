import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ParticipantList from './ParticipantList/PartipicantList';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { ParticipantService } from '../services/participantService'
import { SnackbarContext } from './contexts/SnackbarContext';
import Dialog from '@material-ui/core/Dialog';
import { DialogActions, DialogContent, DialogTitle, withStyles } from '@material-ui/core';
import { ResponseError, logError } from '../services/grapqhlResponse';
import { useForm } from 'react-hook-form';
import getRemovedParticipants from './ParticipantList/getRemoveParticipants';
import getUpdatedParticipants from './ParticipantList/getUpdatedParticipants';
import { ServiceContext } from '../services/serviceContext';

const ModalButton = withStyles({
  root: {
    textTransform: 'none',
  },
})(Button);

const useStyles = makeStyles((theme) => ({

  particList: {
    marginLeft: "-9%",
  },
  dialogSize: {
    width: '70%',
    minWidth: '70%'
  },
  content: {
    minHeight: '30vh'
  }
}));

export default function ParticipantModal({ projectData, openParticipantModal, setParticipantModalOpen }) {
  const classes = useStyles();
  const participantService = new ParticipantService();
  const [participantList, setParticipantList] = useState([]);
  const [invitationList, setInvitationList] = useState([]);
  const [projectMemberList, setProjectMemberList] = useState([]);
  const { projectService } = useContext(ServiceContext);

  const projectName = projectData.name;
  const projectId = projectData.id;
  const [, notifyUser] = useContext(SnackbarContext);

  const { control, register, handleSubmit, errors, getValues } = useForm();

  const handleClose = (event?, reason?) => {
    if (reason === 'backdropClick') {
      return;
    }
    setParticipantList([]);
    setInvitationList([]);
    setProjectMemberList([]);
    setParticipantModalOpen(false);
  };

  const saveParticipant = (data) => {
    projectService.updateProject(
      projectId,
      projectName,
      getUpdatedParticipants(data, participantList),
      getRemovedParticipants(data, participantList)
    )
      .then(() => {
        notifyUser({
          show: true,
          type: "success",
          message: 'Participants successfully updated'
        });
        handleClose();
      })
      .catch((err: ResponseError) => {
        notifyUser({
          show: true,
          type: "error",
          message: err.message
        });
        handleClose();
      });
  }

  useEffect(() => {
    const getList = () => {
      if (openParticipantModal) {
        participantService.participantsByProjectId(projectId)
          .then(response => setParticipantList(response))
          .catch((err: ResponseError) => logError(err));
      }
    }
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openParticipantModal]);


  useEffect(() => {
    setProjectMemberList(participantList.filter(item => item.user));
    setInvitationList(participantList.filter(item => item.email));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participantList])

  const body = (
    <>
      <Grid>
        <Grid className={classes.particList} style={{ marginLeft: "5vh" }} >
          <ParticipantList
            projectId={projectId}
            alreadyAddedParticipants={participantList.length === 0 ? null : participantList}
            parLength={participantList.length}
            handleSubmit={handleSubmit}
            control={control}
            register={register}
            errors={errors}
            getValues={getValues}
          />
        </Grid>
      </Grid>
    </>
  );

  return (
    <Dialog
      classes={{ paper: classes.dialogSize }}
      open={openParticipantModal}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <DialogTitle>
        <h5>Project Name: {projectName} </h5>
        <h5>Project Member Count: {Array.isArray(projectMemberList) ? projectMemberList.length : ""}  -  Invitation Count: {Array.isArray(invitationList) ? invitationList.length : ""} </h5>
      </DialogTitle>
      <DialogContent className={classes.content}>
        {body}
      </DialogContent>
      <DialogActions>
        <ModalButton onClick={handleClose} color="primary" variant="outlined">
          CANCEL
        </ModalButton>
        <ModalButton onClick={handleSubmit(saveParticipant)} color="primary" variant="contained">
          SAVE
        </ModalButton>
      </DialogActions>
    </Dialog>
  );
}