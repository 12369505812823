import React, { useEffect } from 'react'
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useForm } from 'react-hook-form';
import Copyright from './copyright';
import { Logo } from './logo/logo';
import { RegisterService } from '../services/registerService';
import { ResponseError, logError } from '../services/grapqhlResponse';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import { Hidden } from '@material-ui/core';
import RizkiLoadingEffect from './load/RizkiLoadingEffect';
import { InputField } from './forms/inputField';
import useLoadingEffect from './load/useLoadingEffect';
import validateInputString from './commons/validateInputString';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function ChangePassword() {
    const classes = useStyles();

    const [password1, setPassword1] = React.useState('');
    const [password2, setPassword2] = React.useState('');
    const [passwordChangeAllowed, setPasswordChangeAllowed] = React.useState(false);
    const [passwordChanged, setPasswordChanged] = React.useState(false);
    const [uuid, setUuid] = React.useState('');
    const [message, setMessage] = React.useState({ error: false, text: '' });

    const [loading, callActionWith] = useLoadingEffect(
        (data: any) => changePassword(data),
        {
            onSuccess: () => notifyOnSuccess(),
            onError: (error: ResponseError) => handleError(error)
        });

    const location = useLocation();
    const params = new URLSearchParams(location.search);

    const [errorText, setErrorText] = React.useState('');
    const { register, handleSubmit, errors } = useForm();
    let uuid1;


    useEffect(() => {
        uuid1 = params.get('id');
        setUuid(uuid1);
        new RegisterService().checkPasswordIdValid(uuid1)
            .then(valid => {
                setPasswordChanged(!passwordChanged);
                setPasswordChangeAllowed(valid);
            }).catch((err: ResponseError) => {
                setPasswordChangeAllowed(false);
                setErrorText("The password recovery link is not valid.");
                logError(err);
            });
    }, []);

    const changePassword = (data: any) => {
        return new RegisterService().changePassword(uuid, password1);
    }

    const notifyOnSuccess = () => {
        setMessage({
            error: false,
            text: 'Your password was successfully changed.'
        });
    }

    const handleError = (error) => {
        setErrorText("An error occured. Your password couldn't be changed.");
        logError(error);
    }


    const onSubmit = data => {
        if (password1 !== password2) {
            setErrorText("Passwords don't match. Please check both passwords.");
            errors.password1 = true;
            errors.password2 = true;
        } else {
            setErrorText("");
            callActionWith(data);
        }
    }

    const handleChange = (prop) => (event) => {
        if (prop === 'password1') setPassword1(event.target.value);
        if (prop === 'password2') setPassword2(event.target.value);
    };

    return (
        passwordChangeAllowed
            ? <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <Logo />
                    <Typography component="h1" variant="h5">
                        Choose your new password
                </Typography>
                    <Typography color='error'>
                        <br></br>
                        {errorText}
                    </Typography>
                    <Typography style={{ color: message.error ? 'red' : 'green' }}>
                        <br />
                        {message.text}
                    </Typography>
                    <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
                        <Grid container spacing={2}>

                            <InputField
                                required={true}
                                name="password1"
                                label="Password"
                                isPassword={true}
                                errors={errors}
                                inputRef={register({
                                    required: true,
                                    pattern: {
                                        value: /^(?=.*\d)(?=.*[A-Za-z])[A-Za-z\d$%&§]{8,20}$/,
                                        message: 'The password must contain 8 - 20 characters and at least one letter and one number. Allowed special characters are  $, %, &, §.'
                                    }
                                })}
                                onChangeCallBack={n => handleChange(n)}
                            />

                            <InputField
                                required={true}
                                name="password2"
                                label="Confirm Password"
                                isPassword={true}
                                errors={errors}
                                inputRef={register({
                                    required: true,
                                    pattern: {
                                        value: /^(?=.*\d)(?=.*[A-Za-z])[A-Za-z\d$%&§]{8,20}$/,
                                        message: 'The password must contain 8 - 20 characters and at least one letter and one number. Allowed special characters are  $, %, &, §.'
                                    }
                                })}
                                onChangeCallBack={n => handleChange(n)}
                            />

                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}>
                            Change Password
                    </Button>
                    </form>
                    <RizkiLoadingEffect withBackdrop={true} loading={loading} />
                </div>
                <Box mt={5}>
                    <Copyright />
                </Box>
            </Container>
            :
            <Box height="95vh" width="100%" display="flex">
                <Hidden only={["sm", "xs"]}>
                    <Grid item md={9} lg={9}>
                        <br></br>
                        <br></br>
                        <br></br>
                        <Grid justify="center">
                            <Typography component="h6" variant="h4" align="center">
                                {"The password recovery link is not valid."}
                            </Typography>
                        </Grid>
                    </Grid>
                </Hidden>
            </Box>
    );
}