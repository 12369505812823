import React, { createContext, useState } from 'react';
import { Refresh } from './refresh';

type RefreshContextType = [Refresh, React.Dispatch<React.SetStateAction<Refresh>>];

const defaultRefresh: Refresh = {
    refreshProject: false
};
const RefreshContext = createContext<RefreshContextType>([defaultRefresh, () => []]);

const RefreshContextProvider = ({children}) => {
    const [refresh, setRefresh] = useState<Refresh>(defaultRefresh);

    return (
        <RefreshContext.Provider value={[refresh, setRefresh]}>
            {children}
        </RefreshContext.Provider>
    );
}

export { RefreshContext, RefreshContextProvider };

export type {RefreshContextType};