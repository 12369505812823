import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

const RizkiTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'black',
        boxShadow: theme.shadows[1],
        fontSize: 12,
    },
    arrow: {
        color: theme.palette.common.white,
    }
}))(Tooltip);

export default RizkiTooltip;