const isEmail = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

const abbreviationOfName = (name) => {
    if (name) {
        return splitName(name)
            .map(next => next.charAt(0).toUpperCase())
            .join('')
            .substring(0, 2);
    }
    return 'U';
}

const splitName = (name) => {
    if (isEmail.test(name)) {
        return name.split('@')[0].split('.');
    }

    return name.split(' ');
}

export { abbreviationOfName };