import React, { useState, useContext } from 'react';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { RiskExportContext } from '../contexts/RiskExportContext';
import useLoadingEffect from '../load/useLoadingEffect';
import { ResponseError, handleError, logError } from '../../services/grapqhlResponse';
import RizkiLoadingEffect from '../load/RizkiLoadingEffect';

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: "15vh",
        width: "40vw"
    },
    content: {
        display: "flex",
        textAlign: "center",
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    closeButtonRoot: {
        margin: 0,
        padding: theme.spacing(2),
    },
    title: {
        color: "#000000",
        fontFamily: "Roboto",
        fontSize: "14px",
        letterSpacing: "0.15px",
        lineHeight: "24px",
        opacity: 0.87,
        textAlign: "left"
    },
    formControl: {
        margin: theme.spacing(2),
        marginTop: theme.spacing(6),
        minWidth: "60%",
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    textArea: {
        marginTop: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
        minWidth: "60%",
    },
    placeholderText: {
        color: "#7B7B7B",
        fontFamily: "Roboto",
        fontSize: "16px",
        letterSpacing: "-0.15px",
        lineHeight: "24px",
        textAlign: "left"
    },
    downloadButton: {
        color: "#FFFFFF",
        fontFamily: "Roboto",
        fontSize: "14px",
        letterSpacing: "0.75px",
        lineHeight: "17px",
        opacity: 0.87,
        width: "100%",
        height: "42px",
        textAlign: "center"
    },
    checkbox: {
        minWidth: "60%",
    },
    remainingCharacterLabel: {
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
        minWidth: "60%",
        float: "right",
        color: "#7B7B7B",
        fontSize: "11px"
    }
}));

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(2.5),
        color: theme.palette.grey[500],
    },
});

export default function RiskExportModal({ risks, toPdf, show, close }) {
    const CHARACTER_LIMIT = 1500;

    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [note, setNote] = useState('');
    const [checked, setChecked] = useState(false);

    const [exportData, setExportData] = useContext(RiskExportContext);

    const [loading, callActionWith] = useLoadingEffect(
        (data: any) => setExportdataOnDownload(data),
        {
            onSuccess: () => handleCloseOnSuccess(),
            onError: (error: ResponseError) => logError(error)
        });


    const setExportdataOnDownload = (data) => {
        return new Promise((resolve, reject) => resolve(setExportData(
            {
                ...exportData,
                note,
                includeRiskIdToExport: checked,
                selectedRiskToExport: []
            })));
    };

    const handleCloseOnSuccess = () => {
        handleClose();
        toPdf();
    };

    const handleDownload = () => {
        callActionWith(new Date());
    }

    const handleCheckboxChange = (event) => {
        setChecked(event.target.checked);
    };

    const handleNoteChange = (event) => {
        let value = event.target.value;
        setNote(value);
    };

    const handleClose = () => {
        setNote('');
        setChecked(false);

        close(false);
    };

    // @ts-ignore
    const DialogTitle = withStyles(styles)((props) => {
        // @ts-ignore
        const { children, classes, onClose, ...other } = props;
        return (
            <MuiDialogTitle disableTypography className={classes.closeButtonRoot} {...other}>
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                    <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </MuiDialogTitle>
        );
    });

    return (
        <Dialog fullScreen={fullScreen} open={show} onClose={handleClose} aria-labelledby="customized-dialog-title" maxWidth="xl">
            {/*
            // @ts-ignore */}
            <DialogTitle className={classes.title} onClose={handleClose}>
                Export view to PDF
            </DialogTitle>
            <DialogContent dividers className={classes.content}>
                <div className={classes.root}>
                    {/*
                    // @ts-ignore */}
                    <TextField className={[classes.placeholderText, classes.textArea]}
                        multiline rows={8} placeholder="Add Notes" variant="outlined"
                        value={note}
                        onChange={handleNoteChange} inputProps={{ maxLength: 1500 }} />

                    <div className={classes.remainingCharacterLabel}>{`${note.length}/${CHARACTER_LIMIT} characters`}</div>
                    <br />

                    <div style={{
                        position: 'absolute', left: '50%', top: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}>
                        <RizkiLoadingEffect loading={loading} />
                    </div>

                    <FormControlLabel
                        className={classes.checkbox}
                        control={
                            <Checkbox checked={checked}
                                onChange={handleCheckboxChange}
                                color="primary"
                            />
                        }
                        label="Include risk numbers?"
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={loading}
                    className={classes.downloadButton}
                    color="primary" variant="contained"
                    onClick={handleDownload}>Download PDF
                    </Button>
            </DialogActions>
        </Dialog>
    );
}