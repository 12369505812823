import React, { useState, useEffect, forwardRef, useImperativeHandle, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { Grid, Typography, makeStyles, TextField } from '@material-ui/core';
import RizkiSlider from '../commons/RizkiSlider'
import { RiskVotesService } from '../../services/riskVotesService';
import { RiskService } from '../../services/riskService';
import RiskVotesAvatarList from './riskVotesAvatarList';
import { comment } from '../../models/voting';
import { SnackbarContext } from '../contexts/SnackbarContext';
import { RizkiHistory } from "../history/rizkiHistory";
import config from '../../config/config';
import { handleError, logError } from '../../services/grapqhlResponse';
import { UserContext } from '../contexts/UserContext';

const useStyles = makeStyles((theme) => ({

    label: {
        opacity: '0.7',
        textTransform: 'uppercase',
        fontSize: '0.9vh',
        lineHeight: '1.5vh',
    },
    text: {
        opacity: '1.0',
        fontSize: '1.2vh',
        lineHeight: '1.5vh',
    },
    grid: {
        marginBottom: "10px"
    },
    mainGrid: {
        marginTop: "40px"
    },
    commentContainer: {
        display: "flex",
        justifyContent: "flex-end"
    },
    buttonGrid: {
        display: "flex",
        justifyContent: "flex-end",
    }
}));

// @ts-ignore  warum forwardRef?
const RiskVotes = forwardRef(({ risk, projectId }, ref) => {
    const classes = useStyles();

    const initialState = {
        riskVoteId: 0,
        probability: 1,
        impact: 1,
        averageProbability: 0,
        averageImpact: 0,
        yourProbability: 0,
        yourImpact: 0,
        comment: "",
        userId: 0
    }

    let riskVotesService = new RiskVotesService();
    let riskService = new RiskService();

    const [user,] = useContext(UserContext);
    const [values, setValues] = useState(initialState);
    const [riskVotes, setRiskVotes] = useState([]);
    const loggedUserId = user.user_id;
    const [saveClicked,] = useState(false);
    const [dataReload, setDataReload] = useState(true);
    const [, notifyUser] = useContext(SnackbarContext);
    const history = useHistory<RizkiHistory>();


    const handleImpactSlider = (event, newValue) => {
        setValues({
            ...values,
            ["impact"]: newValue,
        });

    }

    const handleProbabilitySlider = (event, newValue) => {
        setValues({
            ...values,
            ["probability"]: newValue,
        });
    }

    const handleComment = event => {
        setValues({
            ...values,
            ["comment"]: event.target.value,
        });
    }

    const changeValueState = (newValue) => {
        setValues({
            ...values,
            ...newValue
        });
    }

    useEffect(() => {
        const getRiskVoting = async () => {
            riskVotesService.getRiskVotesByRiskId(risk.id)
                .then(votesByRiskId => {
                    setRiskVotes(votesByRiskId);
                    setDataReload(false);
                })
                .catch(err => {
                    logError(err);
                    setDataReload(false);
                });
        }
        getRiskVoting();
    }, [dataReload]);

    useEffect(() => {
        const calculateAverageVoting = async () => {
            let totalProbabilty = 0;
            let totalImpact = 0;
            let yourProbability = 0;
            let yourImpact = 0;
            let riskVoteId = 0;
            let comment = "";
            const riskVotesSize = riskVotes.length;
            for (let i = 0; i < riskVotesSize; i++) {
                const riskVote = riskVotes[i];
                totalProbabilty += riskVote.probability;
                totalImpact += riskVote.impact;
                if (loggedUserId.toString() === riskVote.userId) {
                    yourProbability = riskVote.probability;
                    yourImpact = riskVote.impact;
                    riskVoteId = riskVote.id;
                    comment = riskVote.comment;
                }
            }
            if (riskVotesSize > 0) {
                if (riskVoteId === 0) {
                    changeValueState({
                        "averageProbability": Math.round(totalProbabilty / riskVotesSize),
                        "averageImpact": Math.round(totalImpact / riskVotesSize)
                    })
                } else {
                    changeValueState({
                        "probability": yourProbability,
                        "impact": yourImpact,
                        "yourProbability": yourProbability,
                        "yourImpact": yourImpact,
                        "averageProbability": Math.round(totalProbabilty / riskVotesSize),
                        "averageImpact": Math.round(totalImpact / riskVotesSize),
                        "riskVoteId": riskVoteId,
                        "comment": comment
                    })
                }
            }
        }
        calculateAverageVoting();
    }, [riskVotes]);

    useImperativeHandle(
        ref,
        () => ({
            saveRiskVote() {
                riskVotesService.saveOrUpdateRiskVote(values, risk.id, projectId)
                    .then(() => riskService.saveRiskHistory(risk.id, "Risk Voted", null))
                    .then(() => {
                        setDataReload(true);
                        notifyUser({
                            show: true,
                            type: "success",
                            message: "Risk Votes saved."
                        });
                    }).catch(err => handleError(err, history, config, notifyUser));
            }
        }),
    )

    return (
        <div >
            <Grid container spacing={1} justify="center" alignItems="center" className={classes.mainGrid}>
                {/*
                // @ts-ignore */}
                <Grid container justify="left" alignItems="center" direction="row" spacing={2}>
                    <Grid item xs={3} className={classes.grid}>
                        <Typography variant="subtitle1" align="left" className={classes.label}>
                            Voting
                        </Typography>
                    </Grid>
                    <Grid item xs={9} className={classes.grid}>
                        <Typography variant="subtitle1" align="left" className={classes.text} id={"Voting"}>
                            {riskVotes != null && riskVotes.length > 0
                                ? <RiskVotesAvatarList riskVotes={riskVotes} ></RiskVotesAvatarList>
                                : "Risk has no votes"
                            }
                        </Typography>
                    </Grid>
                </Grid>
                {/*
                // @ts-ignore */}
                <Grid container justify="left" alignItems="center" direction="row" spacing={2}>
                    <Grid item xs={3} className={classes.grid}>
                        <Typography variant="subtitle1" align="left" className={classes.label}>
                            Average Voting
                    </Typography>
                    </Grid>
                    <Grid item xs={9} className={classes.grid}>
                        <Typography variant="subtitle1" align="left" className={classes.text} id={"Average Voting"}>
                            {riskVotes != null && riskVotes.length > 0
                                ? "P" + values.averageProbability + " I" + values.averageImpact
                                : "Risk has no votes"
                            }
                        </Typography>
                    </Grid>
                </Grid>
                {/*
                // @ts-ignore */}
                <Grid container justify="left" alignItems="center" direction="row" spacing={2}>
                    <Grid item xs={3} className={classes.grid}>
                        <Typography variant="subtitle1" align="left" className={classes.label}>
                            Your Voting
                    </Typography>
                    </Grid>
                    <Grid item xs={9} className={classes.grid}>
                        <Typography variant="subtitle1" align="left" className={classes.text} id={"Your Voting"}>
                            {values.yourProbability !== 0
                                ? "P" + values.yourProbability + " I" + values.yourImpact
                                : "You don't have vote for selected risk"
                            }
                        </Typography>
                    </Grid>
                </Grid>
                <br></br><br></br><br></br>
                <Grid item xs={6}>
                    <Grid container justify="center" spacing={5}>
                        <Grid key={9} item lg={6} xs={12}>
                            <Typography id="probability" gutterBottom className={classes.label}>
                                PROBABILITY
                            </Typography>
                            <RizkiSlider
                                id="probability"
                                name="probability"
                                value={values.probability}
                                aria-labelledby="probability-slider"
                                step={1}
                                marks
                                min={1}
                                max={5}
                                onChange={handleProbabilitySlider}
                                track={false}
                                valueLabelDisplay="off"
                            />
                        </Grid>
                        <Grid key={10} item lg={6} xs={12}>
                            <Typography id="impact" gutterBottom className={classes.label}>
                                IMPACT
                                </Typography>
                            <RizkiSlider
                                id="impact"
                                name="impact"
                                value={values.impact}
                                aria-labelledby="impact-slider"
                                step={1}
                                marks
                                min={1}
                                max={5}
                                onChange={handleImpactSlider}
                                track={false}
                                valueLabelDisplay="off"
                            />
                        </Grid>
                    </Grid>
                    <br></br><br></br> <br></br>
                    <Grid container spacing={5} className={classes.commentContainer}>
                        <Grid key={10} item xs={12}>
                            <TextField
                                id="comment"
                                name="comment"
                                label="Write Comment"
                                helperText="Max. 500 chars"
                                variant="outlined"
                                value={values.comment}
                                fullWidth
                                multiline
                                rows={4}
                                onChange={handleComment}
                                error={!comment.valid(values.comment) && saveClicked}
                                inputProps={{
                                    maxLength: 500,
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
})

export default RiskVotes;