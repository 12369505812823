import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import SubscriptionModalContent from './SubcriptionModalContent';
import SubscriptionInfoModal from './SubscriptionInfoModal';
import { ModalContext } from '../contexts/ModalContext';
import EditProfile from '../EditProfile';
import config from '../../config/config';
import InvoiceDetailModal from '../Payment/InvoiceDetailModal';
import InvoiceModalContent from '../UserSettings/InvoiceModalContent';
import PaymentDetails from '../UserSettings/PaymentDetails';
import { CollaboratorPage } from '../collaborator/CollaboratorPage';
import { UserContext } from '../contexts/UserContext';
import { DialogTitle } from '@material-ui/core';
import checkSubscriptionExpired from '../checkIfSubscriptionExpired';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "70vw",
        paddingLeft: "7%",
        paddingRight: "7%",
        paddingTop: "1%",
        paddingBottom: "5%",
        minHeight: "50vh",
        "@media only screen and (max-width: 2200px)": {
            minHeight: "50vh"
        },
        "@media only screen and (max-height: 760px)": {
            paddingTop: "1%",
            paddingBottom: "1%",
        },
        "@media only screen and (min-width: 600px) and (max-width: 1000px)": {
            width: "100vw",
            paddingLeft: "1%",
            paddingRight: "1%",
        }

    },
    tab: {
        flexGrow: 1,
    },
    content: {
        display: "flex"
    },
    trialButton: {
        minWidth: theme.spacing(11)
    }
}));

const SubscriptionModal = () => {
    const [user,] = useContext(UserContext);
    const [trial, setTrial] = useState(false);
    const [modalProps, setModalProps] = useContext(ModalContext);
    const [saveProfile, setSaveProfile] = useState(false);
    const [trialButtonDisabled, setTrialButtonDisabled] = useState(false);
    const history = useHistory();
    const [disabledPaymentDetails, setDisabledPaymentDetails] = useState(true);

    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const subscriptionType = user.subscriptionType;
    const triedAlready = user.triedAlready;


    const handleClose = () => {
        setModalProps({
            ...modalProps,
            subscriptionModal: {
                show: false
            }
        });
        history.push(config.source.projectList);
    };

    useEffect(() => {
        setDisabledPaymentDetails(user.free);
    }, [user]);

    const handleChange = (event, newValue) => {
        setModalProps({
            ...modalProps,
            subscriptionModal: {
                show: true,
                tab: newValue

            }
        })
    };

    const handleSaveProfile = () => {
        setSaveProfile(true);
    }

    const onTrialClick = () => {
        handleClose();
        setTrial(true);
    }

    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                open={modalProps.subscriptionModal.show}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                maxWidth="lg"
            >
                <DialogTitle style={{ paddingBottom: '0px' }}>
                    <Tabs
                        value={modalProps.subscriptionModal.tab}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                    >
                        <Tab label="Settings" />
                        <Tab label="Collaborators" />
                        <Tab label="Subscriptions" />
                        <Tab label="Invoices" />
                        <Tab label="Payment Details" disabled={disabledPaymentDetails} />
                    </Tabs>
                </DialogTitle>
                <DialogContent className={classes.content} dividers>
                    <div className={classes.root}>
                        {modalProps.subscriptionModal.tab === 0 ? <EditProfile onClosePopup={handleClose} saveProfile={saveProfile} setSaveProfile={setSaveProfile} /> : null}
                        {modalProps.subscriptionModal.tab === 1 ? <CollaboratorPage userId={user.user_id} /> : null}
                        {modalProps.subscriptionModal.tab === 2 ? <SubscriptionModalContent setTrial={setTrial} setTrialButtonDisabled={setTrialButtonDisabled} /> : null}
                        {modalProps.subscriptionModal.tab === 3 ? <InvoiceModalContent /> : null}
                        {modalProps.subscriptionModal.tab === 4 ? <PaymentDetails onClosePopup={handleClose} saveProfile={saveProfile} setSaveProfile={setSaveProfile} /> : null}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" variant="outlined">
                        Cancel
                    </Button>
                    {modalProps.subscriptionModal.tab === 2 && subscriptionType === "BASIC" && !triedAlready ? <Button disabled={trialButtonDisabled} className={classes.trialButton} color="primary" variant="contained" onClick={onTrialClick}>TRIAL</Button> : null}
                    {modalProps.subscriptionModal.tab === 0 ? <Button form='profile-form' type="submit" color="primary" variant="contained" onClick={handleSaveProfile}>SAVE SETTINGS</Button> : null}
                    {modalProps.subscriptionModal.tab === 4 ? <Button form='profile-form' type="submit" color="primary" variant="contained" onClick={handleSaveProfile}>SAVE PAYMENT DETAILS</Button> : null}
                </DialogActions>
            </Dialog>
            <SubscriptionInfoModal trial={trial} setTrial={setTrial} />
            <InvoiceDetailModal />
        </div>
    );
}


export default SubscriptionModal