import React, { useState, useEffect, useContext } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import { ModalContext } from "../contexts/ModalContext";
import { SubscriptionWorkflowContext } from "./SubscriptionWorkflowContext";
import stepToModal from "./stepToModal";
import useLoadingEffect from "../load/useLoadingEffect";
import { ParticipantService } from "../../services/participantService";
import { ResponseError } from "../../services/grapqhlResponse";
import { Constraints } from "../../utils/jwt-token";
import { SnackbarContext } from "../contexts/SnackbarContext";
import { SubscriptionType } from "./subscriptionType";
import SubscriptionService from "../../services/subscriptionService";
import RizkiLoadingEffect from "../load/RizkiLoadingEffect";

const SubscriptionParticipantSelectModal = () => {
  const [participants, setParticipants] = useState([]);
  const [selectedParticipants, setSelectedParticipants] = useState([]);
  const [modalProps, setModalProps] = useContext(ModalContext);
  const [workflow, setWorkflow] = useContext(SubscriptionWorkflowContext);
  const [limits, setLimits] = useState<Constraints>({});
  const [, notifyUser] = useContext(SnackbarContext);

  const [loading, callActionWith] = useLoadingEffect(
    (data: any) => new ParticipantService().participantsByProjectIds(data),
    {
      onSuccess: (arg, result) => setParticipants(result),
      onError: (error: ResponseError) => console.log("error", error),
    }
  );

  useEffect(() => {
    const fetchParticipants = () => {
      callActionWith(workflow.projects);
    };
    const getLimits = (type: SubscriptionType) => {
      new SubscriptionService()
        .getSubscriptionLimitsFor(type)
        .then(setLimits)
        .catch((error) =>
          notifyUser({
            show: true,
            type: "error",
            message: error.message,
          })
        );
    };
    if (modalProps.subscriptionParticipantSelectModal.show) {
      fetchParticipants();
      getLimits(workflow.to);
    }
  }, [modalProps.subscriptionParticipantSelectModal.show]);

  const handleBack = () => {
    const nextStep = workflow.step - 1;
    setWorkflow({
      ...workflow,
      step: nextStep,
    });
    setModalProps({
      ...modalProps,
      subscriptionParticipantSelectModal: {
        show: false,
      },
      [stepToModal(workflow.steps[nextStep])]: {
        show: true,
        step: 2,
      },
    });
  };

  const saveSelection = () => {
    const nextStep = workflow.step + 1;
    setWorkflow({
      ...workflow,
      step: nextStep,
      participants: selectedParticipants,
    });
    setModalProps({
      ...modalProps,
      subscriptionParticipantSelectModal: {
        show: false,
      },
      [stepToModal(workflow.steps[nextStep])]: {
        show: true,
      },
    });
  };

  const handleChange = (event) => {
    const value = event.target.value;
    const newSelectedParticipants = [].concat(selectedParticipants);
    const index = newSelectedParticipants.indexOf(value);
    if (index > -1) {
      newSelectedParticipants.splice(index, 1);
    } else {
      if (newSelectedParticipants.length < limits.participantLimit) {
        newSelectedParticipants.push(value);
      }
    }
    setSelectedParticipants(newSelectedParticipants);
  };

  return (
    <Dialog
      open={modalProps.subscriptionParticipantSelectModal.show}
      onClose={() => {}}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{`Participant Selection`}</DialogTitle>
      <DialogContent dividers>
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <RizkiLoadingEffect loading={loading} />
        </div>
        <DialogContentText id="alert-dialog-description">
          <Typography component={"span"} gutterBottom>
            {participants.length === 0
              ? "You have no participants"
              : `Select ${limits.participantLimit} participant${
                  limits.participantLimit > 1 ? "s" : ""
                } to keep. Unselected ones will be removed from the project.`}
          </Typography>
          <FormGroup>
            {participants &&
              participants.map((participant) => {
                return (
                  <FormControlLabel
                    disabled={
                      (selectedParticipants.length >= limits.participantLimit &&
                      selectedParticipants.indexOf(
                        participant.email || participant.user.email
                      ) === -1) || loading
                    }
                    key={
                      participant.id +
                      (participant.email ? "-" + participant.email : "")
                    }
                    value={participant.email || participant.user.email}
                    control={
                      <Checkbox
                        color="primary"
                        checked={
                          selectedParticipants.indexOf(
                            participant.email || participant.user.email
                          ) > -1
                        }
                        onChange={handleChange}
                        name={participant.email || participant.user.email}
                      />
                    }
                    label={participant.email || participant.user.email}
                    style={{ marginRight: "15%" }}
                  />
                );
              })}
          </FormGroup>
          {participants.length > 0 && selectedParticipants.length === 0 ? (
            <Typography
              style={{
                color: "#0869ff",
                fontFamily: "Roboto",
                textAlign: "center",
              }}
            >
              Note: Without selection all participants are deleted!
            </Typography>
          ) : null}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={loading}
          onClick={() => {
            handleBack();
          }}
          color="primary"
          variant="outlined"
        >
          Back
        </Button>
        <Button
          disabled={loading}
          onClick={saveSelection}
          color="primary"
          variant="contained"
        >
          Next
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SubscriptionParticipantSelectModal;
