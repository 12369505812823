export interface RiskHistory {
    id: number,
    riskId: number,
    activity: string,
    date: Date,
    risk: any;
    changes: {
        name: string,
        probability: string,
        impact: string
    },
    user: {
        name: string
    },
    userId: number,
    createdAt: string
}

export const isRiskHistory = (value: any): value is RiskHistory => 
    value && 'changes' in value;