import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import SubscriptionService from '../../services/subscriptionService';
import { ModalContext } from '../contexts/ModalContext';
import { logError } from '../../services/grapqhlResponse';
import { UserContext } from '../contexts/UserContext';
import { TokenProvider } from '../../utils/tokenProvider';

const SubscriptionInfoModal = ({ trial, setTrial }) => {
    const [modalProps, setModalProps] = useContext(ModalContext);
    const [user,setUser] = useContext(UserContext);
    const subscriptionService = new SubscriptionService();
    const tokenProvider = TokenProvider();

    const handleStart = () => {
        setTrial(false);
        subscriptionService.changeSubscription("TRIAL")
            .then(response => updateTokenContext(response))
            .catch(err => logError(err));
    };

    
    const updateTokenContext = (token) => {
        tokenProvider.setToken(token);
        setUser(tokenProvider.getUserInfo());
    }

    const handleCancel = () => {
        setTrial(false);
        setModalProps({
            ...modalProps,
            subscriptionModal: {
                show: true,
                tab: 2
            }
        });
    };

    return (
        <div>
            <Dialog
                open={trial}
                onClose={handleStart}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Start Rizki's trial period?"}</DialogTitle>
                <DialogContent dividers>
                    <DialogContentText id="alert-dialog-description">
                        <Typography gutterBottom>
                            You are now starting your test period for Premium. The test period is valid for 14-days.
                            After the trial period you will lose your data if you don't buy the subscription. Now you are able to use the following extra features:
                        </Typography>
                        <Typography>
                            <FiberManualRecordIcon style={{ fontSize: 10 }} /> Unlimited Projects
                        </Typography>
                        <Typography>
                            <FiberManualRecordIcon style={{ fontSize: 10 }} /> Unlimited Unique Team Members
                        </Typography>
                        <Typography>
                            <FiberManualRecordIcon style={{ fontSize: 10 }} /> Voting
                        </Typography>
                        <Typography>
                            <FiberManualRecordIcon style={{ fontSize: 10 }} /> Export / Share
                        </Typography>
                        <Typography>
                            <FiberManualRecordIcon style={{ fontSize: 10 }} /> History
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} color="primary" variant="outlined">
                        Cancel
                    </Button>
                    <Button onClick={handleStart} color="primary" variant="contained">
                        Start
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default SubscriptionInfoModal;