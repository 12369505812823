import { Client } from '../utils/client';
import { ApolloClient, gql } from 'apollo-boost';
import { onResponse, createError } from './grapqhlResponse';

export class ParticipantService {

    client(): ApolloClient<any> {
        return new Client().createApolloClient();
    }

    teamMembersLimitReachedForPrjManager(projectId, participants: Array<any>) {
        const filteredListStr = JSON.stringify(participants).replace(/"([^(")"]+)":/g, "$1:");
        const teamMembersLimitReachedForPrjManager = gql`
            query{
                teamMembersLimitReachedForPrjManager(
                    projectId: \"${projectId}\",
                    participants: ${filteredListStr},
                    )
            }`;

        return this.client().query<{ teamMembersLimitReachedForPrjManager: any }>({
            query: teamMembersLimitReachedForPrjManager,
            errorPolicy: 'all'
        }).then(response =>
            onResponse(
                response,
                data => data.teamMembersLimitReachedForPrjManager
            )
        ).catch(createError);
    }

    collaboratorTeam(projectId) {
        const collaboratorTeam = gql`{
            collaboratorTeam(projectId: \"${projectId}\"){
                ... on Invitation {
                    email
                }
                ... on ProjectMember {
                    userId,
                    user{
                        name,
                        email
                    }
                }
            }
        }
        `;
        return this.client().mutate<{ collaboratorTeam }>({ mutation: collaboratorTeam, errorPolicy: 'all' })
            .then(response =>
                onResponse(
                    response,
                    data => data.collaboratorTeam
                )
            ).catch(createError);
    }

    participantsByProjectId(projectId) {
        const getParticipants = gql`{
            participantsByProjectId(projectId: \"${projectId}\"){
                id,
                projectId,
                projectRole
                ... on Invitation {
                    email
                }
                ... on ProjectMember {
                    userId,
                    user{
                        name,
                        email
                    }
                }
            }
        }
        `;
        return this.client().mutate<{ participantsByProjectId }>({ mutation: getParticipants, errorPolicy: 'all' })
            .then(response =>
                onResponse(
                    response,
                    data => data.participantsByProjectId
                )
            ).catch(createError);
    }

    participantsByProjectIds(projectIds: Array<string>) {
        const getParticipants = gql`{
            participantsByProjectIds(projectIds: [${projectIds.map(a => `"${a}"`).join()}]){
                id,
                projectId,
                projectRole
                ... on Invitation {
                    email
                }
                ... on ProjectMember {
                    userId,
                    user{
                        name,
                        email
                    }
                }
            }
        }
        `;
        return this.client().mutate<{ participantsByProjectIds }>({ mutation: getParticipants, errorPolicy: 'all' })
            .then(response =>
                onResponse(
                    response,
                    data => data.participantsByProjectIds
                )
            ).catch(createError);
    }

    removeParticipant(id, email) {
        const RemoveParticipantMutation = gql`
            mutation {
				removeParticipant(
                    id:"${id}",
                    email:"${email}"
              	)
			}`;
        return this.client().mutate<{ removeParticipant }>({ mutation: RemoveParticipantMutation, errorPolicy: 'all' })
            .then(response =>
                onResponse(
                    response,
                    data => data.removeParticipant
                )
            ).catch(createError);
    }
    

    removeParticipants(participants) {
        const participantsStr = JSON.stringify(participants).replace(/"([^(")"]+)":/g, "$1:");
        const RemoveParticipantsMutation = gql`
            mutation {
				removeParticipants(
                    participants:${participantsStr}
              	)
			}`;
        return this.client().mutate<{ removeParticipants }>({ mutation: RemoveParticipantsMutation, errorPolicy: 'all' })
            .then(response =>
                onResponse(
                    response,
                    data => data.removeParticipants
                )
            ).catch(createError);
    }

    updateParticipantsRole(participants) {
        const participantsStr = JSON.stringify(participants).replace(/"([^(")"]+)":/g, "$1:");
        const UpdateParticipantsMutation = gql`
            mutation {
				updateParticipants(
                    participants:${participantsStr}
              	)
			}`;

        return this.client().mutate<{ updateParticipants }>({ mutation: UpdateParticipantsMutation, errorPolicy: 'all' })
            .then(response =>
                onResponse(
                    response,
                    data => data.updateParticipants
                )
            ).catch(createError);
    }
}