const userIdentification = (user) => {
    if (user) {
        if (user.name) {
            return user.name;
        }
        if (user.email) {
            return user.email;
        }        
    }
    return 'Unknown'; //TODO @Baris
}

export { userIdentification };