import React from "react";
import { Box } from "@material-ui/core";
import RiskExportListNew from "./RiskExportListNew";

const RiskExportList = ({ riskList, selectedRisks, includeRiskIdToExport }) => {
  let risks = riskList;
  /*if (riskList) {
    risks = riskList;
    risks.sort((a, b) =>
      a.impact < b.impact
        ? 1
        : a.impact === b.impact
        ? a.probability < b.probability
          ? 1
          : -1
        : -1
    );
  }*/

  return (
    <>      
        <Box
          height="100%"
          width="100%"
          display="flex"
          flexDirection="column"
          border="solid"
          /*
          // @ts-ignore*/
          borderWidth="thin"
          borderColor="lightgray">
          {
            <RiskExportListNew
              riskList={risks}
              selectedRisks={selectedRisks}
              setSelectedRisks={() => { }}
              includeRiskIdToExport={includeRiskIdToExport}
            />
          }
        </Box>
    </>
  );
};

export default RiskExportList;
