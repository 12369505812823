import React, { useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import RiskExportList from './RiskExportList';
import RiskExportMatrix from './RiskExportMatrix';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import config from './config';
import { UserContext } from '../contexts/UserContext';

export default function RiskExportPage({ project, allRisks, selectedRisks, exportNote, includeRiskIdToExport, pickedRiskHistory, numberOfRisksPerPage }) {

    const [user,] = useContext(UserContext);
    const createPages = () => {
        let pages = [];
        var i, j, temparray, chunk = numberOfRisksPerPage;
        let counter = 1;
        for (i = 0, j = allRisks.length; i < j; i += chunk) {
            temparray = allRisks.slice(i, i + chunk);
            pages.push(page(temparray, counter, Math.ceil(allRisks.length / chunk)));
            counter++;
        }
        return pages;
    }

    const userName = user.name;

    const page = (risks, i, amountOfPages) => (
        <Box id={config.exportComponentIdPrefix + i} style={{
            position: 'absolute', zIndex: -2,
            height: config.pageDimentions.heightInPx + 'px',
            width: config.pageDimentions.widthInPx + 'px',
            display: "flex", flexDirection: "column", padding: "30px"
        }} >
            <Typography style={{
                width: "100%", textAlign: "left",
                fontWeight: "bold",
                fontFamily: "Roboto",
                fontSize: "14px",
            }}>{project.name + " / " + moment(new Date()).format("DD MMM YYYY")}</Typography>
            <Grid container style={{ height: "90%", width: "100%", padding: "20px 0px 20px 0px" }}>
                <Grid item md={8} lg={8} direction="column" style={{ height: "100%" }}>
                    <Grid container md={12} lg={12} style={{ height: "75%" }}>
                        {/*
                            // @ts-ignore */}
                        <RiskExportMatrix zIndex={-2} includeRiskIdToExport={includeRiskIdToExport} risks={allRisks}
                            selectedRisks={selectedRisks && selectedRisks.length > 0 ? selectedRisks : null} pickedRiskHistory={pickedRiskHistory} />
                    </Grid>
                    <Grid container md={12} lg={12} style={{ height: "25%", paddingTop: "20px" }}>
                        {/*
                            // @ts-ignore */}
                        <Typography size={'small'} style={{
                            paddingRight: "1%", paddingLeft: "1.5%",
                            fontFamily: "Roboto",
                            fontSize: "12px",
                        }}>Notes</Typography>
                        <div style={{
                            fontSize: 12,
                            margin: "4px",
                            color: "#7B7B7B",
                            fontFamily: "Roboto",
                            letterSpacing: "-0.15px",
                            lineHeight: "14px !important",
                            textAlign: "left",
                            width: "90%",
                            borderStyle: "solid",
                            borderWidth: "thin",
                            padding: "8px 8px 8px 8px",
                            wordWrap: "break-word"
                        }}
                            // @ts-ignore
                            multiline rows={9} variant="outlined" >
                            {exportNote}
                        </div>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <RiskExportList
                        includeRiskIdToExport={includeRiskIdToExport}
                        riskList={risks}
                        selectedRisks={selectedRisks}
                    />
                </Grid>
            </Grid>
            <Typography style={{
                width: "100%", textAlign: "center", top: "0",
                fontFamily: "Roboto",
                fontSize: "11px",
                height: "3%",
                paddingTop: "1%",
            }}>Generated with RIZKI (https://rizki.at) by {userName} on {moment(new Date()).format("DD MMM YYYY HH:mm")}</Typography>
            <Typography style={{
                width: "100%", textAlign: "center", top: "0",
                fontFamily: "Roboto",
                fontSize: "11px",
                paddingTop: "1.2%",
                height: "3%"
            }}>{`${i} / ${amountOfPages}`}</Typography>
        </Box >
    )

    return (
        <>
            {createPages()}
        </>
    )
}