import { Client } from '../utils/client';
import { ApolloClient, gql } from 'apollo-boost';
import { PagePosition } from '../components/pagination/pagePosition';
import { Page, PageCount } from '../components/pagination/page';
import { Project } from '../components/project/project';
import { CollaboratorType } from '../components/collaborator/collaborator';
import { createError, onResponse } from './grapqhlResponse';

export class ProjectService {

	client(): ApolloClient<any> {
		return new Client().createApolloClient();
	}


	createProject(project, participantList, userId) {
		const projectName = project.project;
		const filteredList = participantList.filter(p => !p.alreadyMember);
		const graphQLConvertion = JSON.stringify(filteredList).replace(/"([^(")"]+)":/g, "$1:");

		console.log('calling createProject', userId);
		const CreateProjectMutation = gql`
             mutation {
              createProject(
                  name: "${projectName}",
                  projectManager: "${userId}",
				  participants: ${graphQLConvertion}
                )
                  {
                    id
                  }
              }`;
		return this.client().mutate<{ createProject: any }>({ mutation: CreateProjectMutation, errorPolicy: 'all' })
			.then(response => 
				onResponse(
					response,
					data => data.createProject
				)
			).catch(createError);
	}

	updateProject(projectId, projectName, participantList, participantsToRemove) {
		const filteredList = participantList.filter(p => !p.alreadyMember);
		const filteredListStr = JSON.stringify(filteredList).replace(/"([^(")"]+)":/g, "$1:");
		const participantsToRemoveStr = JSON.stringify(participantsToRemove).replace(/"([^(")"]+)":/g, "$1:");

		const UpdateProjectMutation = gql`
            mutation {
              updateProject(
                id: "${projectId}",
                name: "${projectName}",
				participants: ${filteredListStr},
				participantsToRemove: ${participantsToRemoveStr}
              ) {
                id
              }
			}`;

		return this
			.client()
			.mutate<{ updateProject: any }>({ mutation: UpdateProjectMutation, errorPolicy: 'all' })
			.then(response => onResponse(
				response,
				data => data.updateProject
			)).catch(createError);
	}

	getProjectById(projectId): Promise<Project> {
		const getProjectById = gql`
			{
				projectById(id:"${projectId}"){
					name
					id
					projectManager
				}
			}`;
		return this.client().query<{ projectById: any }>({ query: getProjectById, errorPolicy: 'all' })
			.then(response =>
				onResponse(
					response,
					data => data.projectById
				)
			).catch(createError);
	}

	getProjectForUser(userId: string, page: PagePosition, searchTerm: string = ''): Promise<Page<Project>> {
		const getAllProjects = gql`
          query {
            projectsByUserId(
				id: "${userId}",
				searchTerm: "${searchTerm}", 
				limit: ${page.limit}, 
				offset: ${page.offset}
			) {
				count,
				rows {
					id
            		name
					projectManager
					projectRole
				}
			}
		  }`;

		return this.client().mutate<{ projectsByUserId: any }>({ mutation: getAllProjects, errorPolicy: 'all' })
			.then(response =>
				onResponse(
					response,
					data => data.projectsByUserId
				)
			).catch(createError);
	}

	projectsAsCollboratorByUserId(
		projectManagerId: string,
		collaboratorId: string,
		page: PagePosition): Promise<PageCount<Project>> {
		const getAllProjects = gql`
          query {
            projectsAsCollboratorByUserId(
				projectManagerId: "${projectManagerId}", 
				collaboratorId: "${collaboratorId}",
				limit: ${page.limit}, 
				offset: ${page.offset}
			) {
				count,
				rows {
					id
            		name
					projectManager
					projectRole
				}
			}
		  }`;

		return this.client().mutate<{ projectsAsCollboratorByUserId: any }>({ mutation: getAllProjects, errorPolicy: 'all' })
			.then(response =>
				onResponse(
					response,
					data => data.projectsAsCollboratorByUserId
				)
			).catch(createError);
	}


	projectsAsCollboratorForInvitee(
		projectManagerId: string,
		collaboratorEmail: string,
		page: PagePosition): Promise<PageCount<Project>> {
		const getAllProjects = gql`
          query {
            projectsAsCollaboratorForInvitee(
				projectManagerId: "${projectManagerId}",
				collaboratorEmail: "${collaboratorEmail}",
				limit: ${page.limit}, 
				offset: ${page.offset}
			) {
				count,
				rows {
					id
            		name
					projectManager
					projectRole
				}
			}
		  }`;

		return this.client().mutate<{ projectsAsCollaboratorForInvitee: any }>({ mutation: getAllProjects, errorPolicy: 'all' })
			.then(response =>
				onResponse(
					response,
					data => data.projectsAsCollaboratorForInvitee
				)
			).catch(createError);
	}

	deleteProject(projectId) {
		const DeleteProjectMutation = gql`
            mutation {
              deleteProject(
                id: "${projectId}"
              ) {
                id
              }
			}`;
		return this.client().mutate<{ deleteProject: any }>({ mutation: DeleteProjectMutation, errorPolicy: 'all' })
			.then(response =>
				onResponse(
					response,
					data => data.deleteProject
				)
			).catch(createError);
	}

	deleteParticipantFromProject(participantId: string, projectId: string, type: CollaboratorType): Promise<number> {
		const mutation = gql`
			mutation {
				deleteParticipantFromProject(
					participantId: "${participantId}"
					projectId: "${projectId}",
					type:"${type}"
				)
			}
		`;

		return this.client().mutate<{ deleteParticipantFromProject: any }>({ mutation, errorPolicy: 'all' })
			.then(response =>
				onResponse(
					response,
					data => data.deleteParticipantFromProject
				)
			).catch(createError);
	}

	deleteParticipantFromAllProject(projectManagerId: string, participantId: number,
		type: CollaboratorType): Promise<number> {
		const mutation = gql`
			mutation {
				deleteParticipantFromAllProject(
					projectManagerId: "${projectManagerId}",
					participantId: "${participantId}"
					type:"${type}"
				)
			}
		`;

		return this.client().mutate<{ deleteParticipantFromAllProject: any }>({ mutation, errorPolicy: 'all' })
			.then(response =>
				onResponse(
					response,
					data => data.deleteParticipantFromAllProject
				)
			).catch(createError);
	}
}
