import React, { createContext } from 'react';
import { AvailableServices } from './availableServices';
import { ParticipantService } from './participantService';
import { ProjectService } from './projectService';



const ServiceContext = createContext<AvailableServices>({projectService: null, participantService: null});

const ServiceContextProvider = ({ children }) => {
    const init = {
        projectService: new ProjectService(),
        participantService: new ParticipantService()
    };

    return (
        <ServiceContext.Provider value={init}>
            {children}
        </ServiceContext.Provider>
    );
};

export { ServiceContext, ServiceContextProvider };
