import React, { useEffect, useState, useContext } from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import AppBar from '@material-ui/core/AppBar';
import { makeStyles } from '@material-ui/core/styles';
import SettingsIcon from '@material-ui/icons/Settings';
import { logout } from '../utils/authenticationProvider';
import { useHistory } from "react-router-dom";
import AvatarImages from './Avatar/AvatarImages';
import config from '../config/config';
import { ProjectService } from '../services/projectService';
import { ParticipantService } from '../services/participantService';
import { ModalContext } from './contexts/ModalContext';
import { useParams } from "react-router-dom";
import { ParamTypes } from './params/paramTypes';
import { RizkiHistory } from './history/rizkiHistory';
import { UserContext } from './contexts/UserContext';
import { Logo } from './logo/logo';
import { ResponseError, handleError } from '../services/grapqhlResponse';
import { SnackbarContext } from './contexts/SnackbarContext';
import checkSubscriptionExpired from './checkIfSubscriptionExpired';
import openInNewTab from '../utils/openInNewTab';

const useStyles = makeStyles((theme) => ({
	grow: {
		flexGrow: 1,
	},
	leftPart: {
		flexGrow: 1,
		display: "flex",
		alignItems: "center",
		marginLeft: "1vh"
	},
	rightPart: {
		display: "flex",
		alignItems: "center"
	},
	menuButton: {
		marginRight: theme.spacing(2),
		color: "black"
	},
	title: {
		alignItems: "center",
		color: "#ADADAD",
		fontFamily: "Roboto",
		fontSize: "14px",
		letterSpacing: "0.25px",
		lineHeight: "20px",
	},
	projectHome: {
		alignItems: "center",
		color: "#313131",
		fontFamily: "Roboto",
		opacity: "0.87",
		fontSize: "14px",
		letterSpacing: "0.25px",
		lineHeight: "20px",
	},
	userName: {
		marginRight: theme.spacing(),
		marginLeft: theme.spacing(),
	},
	avatarImages: {
		marginLeft: "20px",
	},
	setting: {
		color: "black",
		marginRight: theme.spacing(1),
		marginLeft: theme.spacing(1),
	}
}));

export default function MenuAppBar() {
	const [user,] = useContext(UserContext);
	const [, notifyUser] = useContext(SnackbarContext);
	const classes = useStyles();
	const [auth, setAuth] = React.useState(true);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const [project, setProject] = React.useState(null);
	const [projectList,] = useState([]);
	const [userRole, setUserRole] = useState('Project Member');
	const [projectId, setProjectId] = React.useState(null);
	const [modalProps, setModalProps] = useContext(ModalContext);
	const history = useHistory<RizkiHistory>();
	const historyState = history.location.state;
	const capitalized = user.subscriptionType.charAt(0) + user.subscriptionType.slice(1).toLowerCase();
	const subscriptionType = user && user.subscriptionType;

	const { redirect } = useParams<ParamTypes>();

	const [redirected, setRedirected] = useState(false);

	const redirectToTab = (tab) => {
		setModalProps({
			...modalProps,
			subscriptionModal: {
				show: true,
				tab
			}
		});
	}

	if (!redirected && redirect === 'invoices') {
		setRedirected(true);
		redirectToTab(3);
	} else if (!redirected && redirect === 'payment-details') {
		setRedirected(true);
		redirectToTab(4);
	}

	let source = "";
	if (historyState) {
		source = historyState.source;
		if (source === config.source.projectList) {
			if (project !== historyState.project) {
				setProject(historyState.project)
			}
		} else if (source === config.source.addRisk) {
			if (projectId !== historyState.projectId) {
				setProjectId(historyState.projectId);
			}
		} else {
			if (project)
				setProject(null);
		}
	}

	useEffect(() => {
		const getParticipants = () => {
			new ParticipantService().participantsByProjectId(project.id)
				.then(participants => {
					const setUserRoleInProject = () => {
						if (project.projectManager.toString() === user.user_id.toString()) {
							setUserRole("Project Manager");
						} else {
							const participant = participants.filter(participant => participant.userId === user.user_id);
							participant && participant.length > 0 && setUserRole(participant[0].projectRole);
						}
					};
					setUserRoleInProject();
				}).catch((err: ResponseError) => handleError(err, history, config, notifyUser));
		}
		if (!!project) {
			getParticipants();
		}
	}, [project, projectList])

	useEffect(() => {
		const getProject = () => {
			new ProjectService().getProjectById(projectId)
				.then(prj => {
					setProject(prj);
					return prj;
				})
				.catch((err: ResponseError) =>
					handleError(err, history, config, notifyUser)
				);
		};

		if (projectId && source === config.source.addRisk) {
			getProject();
		}

	}, [source]);

	useEffect(() => {

		checkSubscriptionExpired(user,
			() => setModalProps({
				...modalProps,
				subscriptionExpiredModal: {
					show: true,
					subscriptionType
				}
			}),
			downgradeTo => setModalProps({
				...modalProps,
				downgradeModal: {
					show: true,
					downgradeTo
				}
			})
		);	
	}, [user]);

	const handleMenu = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleProfile = () => {
		setAnchorEl(null);
		setModalProps({
			...modalProps,
			subscriptionModal: {
				show: true,
				tab: 0
			}
		});
	};

	const handleLogout = () => {
		logout();
		history.push("/login");
		window.location.href = window.location.href;
	};

	const navigateToHome = () => {
		history.push("/project-list");
	};

	const navigateToEditProjectPage = () => {

		history.push("/edit-project", { ...project, actionType: "update-project", source: "project-risks" })
	};

	const getDaysLeft = () => {
		const trialEnd = user.subscriptionValidUntil.getTime();
		const dateNow = new Date().getTime();
		const diffTime = trialEnd - dateNow;
		return diffTime > 0 ? Math.ceil(diffTime / (1000 * 60 * 60 * 24)) : 0
	}

	const handleBuyButton = () => {
		setModalProps({
			...modalProps,
			subscriptionModal: {
				show: true,
				tab: 2
			}
		});
	}

	return (
		<div className={classes.grow}>
			<AppBar position="static" elevation={0}>
				<Toolbar>
					<Logo space={false} size="50px" />
					<div className={classes.leftPart}>
						<Typography className={classes.title} align='left' style={{ cursor: 'pointer' }}
							onClick={() => { navigateToHome() }}>
							Home&nbsp;
          				</Typography>
						{project && history.location.pathname !== "/project-list" && history.location.pathname !== "/newProject" ?
							<span style={{ display: "flex", alignItems: "center" }}>
								<Typography className={classes.projectHome} align='left' style={{ cursor: 'pointer' }}
									onClick={() => {
										if (userRole !== 'Project Member') {
											navigateToEditProjectPage()
										}
									}}>
									{" / " + project.name}
								</Typography>
								<span className={classes.avatarImages}>
									<AvatarImages data={project} />
								</span>
							</span>
							:
							<Typography className={classes.projectHome} align='left' style={{ cursor: 'pointer' }} onClick={() => { navigateToHome() }}>
								/ Projects
          					</Typography>
						}
					</div>
					<div className={classes.rightPart}>
						{
							user.subscriptionType && user.subscriptionType === "TRIAL" ?
								<div className={classes.rightPart}>
									<Typography className={`${classes.projectHome} ${classes.userName}`} align='right'>
										{user.name + ` (Trial, ${getDaysLeft()} days left)`}
									</Typography>
									<Button variant="contained" color="primary" size="small" onClick={handleBuyButton}>BUY</Button>
								</div> :
								<Typography className={classes.projectHome} align='right'>
									{user.name + ` (${capitalized})`}
								</Typography>
						}
						<IconButton
							id="settingsIcon"
							aria-label="account of current user"
							aria-controls="menu-appbar"
							aria-haspopup="true"
							onClick={handleMenu}
							className={classes.setting}
						>
							<SettingsIcon />
						</IconButton>
						<Menu
							id="menu-appbar"
							anchorEl={anchorEl}
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							keepMounted
							transformOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							open={open}
							onClose={handleClose}
						>
							<MenuItem onClick={handleProfile}>Profile</MenuItem>
							<MenuItem onClick={() => openInNewTab('https://www.rizki.at/support-page')}>Support</MenuItem>
							<MenuItem onClick={handleLogout}>Logout</MenuItem>

						</Menu>
					</div>

				</Toolbar>
			</AppBar>
		</div>
	);
}