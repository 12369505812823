import sleep from "./sleep";
import { DateTime } from "luxon";

const waitMinimumTime = (startTime: DateTime, milliseconds: number): Promise<any> => {
    const finishTime = DateTime.local();
    const duration = finishTime.diff(startTime).toObject().milliseconds;
    return duration < milliseconds
        ? sleep(milliseconds - duration)
        : new Promise(resolve => resolve(undefined))
}

export default waitMinimumTime;