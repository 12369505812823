import React from 'react';

const RiskBubble = (props) => {
    const bubbleHeight = props.height;
    const bubbleWidth = props.width;
    const dotId = props.dotId;
    const color = props.color;
    const count = props.count;
    const occurred = props.occurred;

    const marginTop = props.position === 'topRight' ? (-bubbleHeight / 2) + "px" : props.position === 'bottomRight' ? ((bubbleHeight / 2) + 2) + "px" : (-bubbleHeight / 2) + "px" || "-5px";
    const marginLeft = props.position === 'topRight' ? (bubbleWidth + 2) + "px" : props.position === 'bottomRight' ? (bubbleWidth + 2) + "px" : (bubbleWidth + 2) + "px" || "5px";

    return (
        <div
            title={count}
            id={"VoteBubble" + dotId}
            style={{
                backgroundColor: color,
                height: bubbleHeight,
                width: bubbleWidth,
                marginLeft: marginLeft,
                marginTop: marginTop,
                borderRadius: "50%",
                borderStyle: "solid",
                borderColor: "white",
                borderWidth: "1px",
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "8px",
                color: "whitesmoke",
                position: "absolute",
                lineHeight: (bubbleHeight - 1) + 'px',
                cursor: 'default'
            }}>
            {occurred ? '!' : count ? count > 99 ?
                <span style={{
                    fontWeight: 900,
                    fontSize: '8px',
                    display: "inline-block",
                    textAlign: "center",
                    lineHeight: bubbleHeight + 'px',
                    marginTop: '-1px'
                }}>&#8734;</span> : count : ''}
        </div>
    )
}
export default RiskBubble;