import { Client } from '../utils/client';
import { ApolloClient, gql } from 'apollo-boost';
import { Page, PageCount } from '../components/pagination/page';
import { PagePosition } from '../components/pagination/pagePosition';
import { RiskHistory } from '../components/RiskDetails/riskHistory';
import { onResponse, createError } from './grapqhlResponse';
export class RiskService {

    client(): ApolloClient<any> {
        return new Client().createApolloClient();
    }

    addViewedUser(riskId, override) {
        const addViewedUser = gql`
		mutation {
			addViewedUser(riskId: "${riskId}")
			{
			  id
			}
        }`;

        const replaceViewedUsers = gql`
		mutation {
			replaceViewedUsers(riskId: "${riskId}")
			{
			  id
			}
        }`;



        if (override) {
            return this.client().mutate<{ replaceViewedUsers: any }>({
                mutation: replaceViewedUsers,
                errorPolicy: 'all'
            }).then(response =>
                onResponse(
                    response,
                    data => data.replaceViewedUsers
                )
            ).catch(createError);
        }
        else {
            return this.client().mutate<{ addViewedUser: any }>({
                mutation: addViewedUser,
                errorPolicy: 'all'
            }).then(response =>
                onResponse(
                    response,
                    data => data.addViewedUser
                )
            ).catch(createError);
        }
    }

    saveRisk(data) {
        const createRisk = gql`
            mutation {
                createRisk(
                    projectId: "${data.projectId}",
                    name: """${data.name.trim()}""",
                    currency: "${data.currency}",
                    cost: "${data.cost}",
                    countermeasure: """${data.countermeasure.trim()}""",
                    detailedDescription: """${data.detailedDescription.trim()}""",
                    impact: ${data.impact},
                    owner: "${data.owner}",
                    probability: ${data.probability},
                    riskType: ${data.riskType},
                    trend: ${data.trend},
                    shortDescription: """${data.shortDescription.trim()}"""
                ) { id }
            }
        `;
        return this.client().mutate<{ createRisk: any }>({
            mutation: createRisk,
            errorPolicy: 'all'
        }).then(response =>
            onResponse(
                response,
                data => data.createRisk
            )
        ).catch(createError);
    }


    getRiskById(riskId) {
        const getRiskById = gql`
            {
                riskById(id:"${riskId}"){
                    cost
                    countermeasure
                    currency
                    detailedDescription
                    id
                    impact
                    name
                    probability
                    projectId
                    owner
                    riskType
                    shortDescription
                    trend
                    user {
                        name
                    }
                    riskNumber
                }
            }`;
        return this.client().query<{ riskById: any }>({
            query: getRiskById,
            errorPolicy: 'all',
            fetchPolicy: "no-cache"
        }).then(response =>
            onResponse(
                response,
                data => data.riskById
            )
        ).catch(createError);
    }


    getRisksByProjectId(projectId) {
        const getRisksByProjectId = gql`
            {
                risksByProjectId(projectId: "${projectId}"){
                    cost
                    countermeasure
                    currency
                    detailedDescription
                    id
                    impact
                    name
                    probability
                    projectId
                    owner
                    riskType
                    shortDescription
                    trend
                    user {
                        name,
                        id
                    }
                    votesCount
                    changed
                    viewedUsers
                    riskNumber
                }
            }`;
        return this.client().query<{ risksByProjectId: any }>({
            query: getRisksByProjectId,
            errorPolicy: 'all',
            fetchPolicy: "no-cache"
        }).then(response =>
            onResponse(
                response,
                data => data.risksByProjectId
            )
        ).catch(createError);
    }


    updateRisk(data, riskId) {
        const updateRiskMutation = gql`
		mutation {
			updateRisk(
                id: "${riskId}",
                projectId: "${data.projectId}",
                cost: "${data.cost}",
                countermeasure: """${data.countermeasure}""",
                currency: "${data.currency}",
                detailedDescription: """${data.detailedDescription}""",
                impact: ${data.impact},
                name: "${data.name}",
                owner: "${data.owner}",
                probability: ${data.probability},
                riskType: ${data.riskType},
                shortDescription: """${data.shortDescription}""",
                trend: ${data.trend}
			)
			{
			  id
			}
		}`;

        return this.client().mutate<{ updateRisk: any }>({
            mutation: updateRiskMutation,
            errorPolicy: 'all'
        }).then(response =>
            onResponse(
                response,
                data => data.updateRisk
            )
        ).catch(createError);
    }

    saveRiskHistory(riskId, activity, changes) {
        const graphQLConvertion = JSON.stringify(changes).replace(/"([^(")"]+)":/g, "$1:");
        const save = gql`
            mutation {
                createRiskHistory(
                    riskId: "${riskId}",
                    activity: "${activity}",
                    changes: ${graphQLConvertion},
                ) { id }
            }
        `;
        return this.client().mutate<{ createRiskHistory: any }>({
            mutation: save,
            errorPolicy: 'all'
        }).then(response =>
            onResponse(
                response,
                data => data.createRiskHistory
            )
        ).catch(createError);
    }

    getRiskHistoriesByRiskId(riskId: number, page: PagePosition): Promise<PageCount<RiskHistory>> {
        const getHistories = gql`
            {
                riskHistoryByRiskId(
                    riskId: "${riskId}",
                    limit: ${page.limit},
                    offset: ${page.offset},
                ) {
                    count,
                    rows {
                        id
                        riskId
                        userId
                        activity
                        createdAt
                        changes
                        user {
                            name
                        }
                    }
                }
            }
        `;
        return this.client()
            .query<{ riskHistoryByRiskId: any }>({
                query: getHistories,
                errorPolicy: 'all',
                fetchPolicy: "no-cache"
            }).then(response =>
                onResponse(
                    response,
                    data => data.riskHistoryByRiskId
                )
            ).catch(createError);
    }

    getFullRiskHistoriesByRiskId(riskId: number, page: PagePosition): Promise<Page<RiskHistory>> {
        const getHistories = gql`
            {
                fullRiskHistoryByRiskId(
                    riskId: "${riskId}",
                    limit: ${page.limit},
                    offset: ${page.offset},
                ) {
                    count,
                    rows {
                        id
                        riskId
                        userId
                        activity
                        createdAt
                        changes
                        user {
                            name
                        }                        
                        risk
                    }                  
                }
            }
        `;
        return this.client()
            .query<{ fullRiskHistoryByRiskId: any }>({
                query: getHistories,
                errorPolicy: 'all',
                fetchPolicy: "no-cache"
            }).then(response =>
                onResponse(
                    response,
                    data => data.fullRiskHistoryByRiskId
                )
            ).catch(createError);
    }

    deleteRisk(riskId, projectId) {
        const deleteRiskMutation = gql`
		mutation {
			deleteRisk(
                id: "${riskId}",
                projectId: "${projectId}"
			)
			{
			  id
			}
		}`;

        return this.client().mutate<{ deleteRisk: any }>({
            mutation: deleteRiskMutation,
            errorPolicy: 'all'
        }).then(response =>
            onResponse(
                response,
                data => data.deleteRisk
            )
        ).catch(createError);
    }
}

