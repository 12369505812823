import Slider from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/styles';

const RizkiSlider = withStyles({
    rail: {
        backgroundColor: "#bfbfbf",
        height: 4
    },
    mark: {
        borderRadius: 50,
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: "#bfbfbf",
        backgroundColor: "white",
        height: 9,
        width: 9,
        marginTop: -5.5,
        marginLeft: -6.5
    },
    markActive: {
        opacity: 1,
        backgroundColor: "white"
    }
})(Slider);

export default RizkiSlider;