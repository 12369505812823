import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Typography } from "@material-ui/core"
import React, { useState } from "react"
import { Collaborator } from "./collaborator"
import { ProjectList } from "./ProjectList"
import { ParticipantService } from '../../services/participantService'
import { RoleDropDown } from "../commons/RizkiTable"

const useStyles = makeStyles((theme) => ({
    textStyle: {
        color: "#7B7B7B",
        fontFamily: "Roboto",
        opacity: "0.87",
        paddingBottom: "15px"
    },
    dialogSize: {
        width: '70%',
        minHeight: '30vh'
    },
}));

type ProjectsProps = {
    projectManagerId: string,
    collaborator: Collaborator;
    openDialog: boolean;
    onCloseDialog: () => void;
    refreshCollaborators: () => void;
}

export const Projects = (
    {
        projectManagerId,
        collaborator,
        openDialog,
        onCloseDialog,
        refreshCollaborators
    }: ProjectsProps
) => {
    const classes = useStyles();
    const [open, setOpen] = useState<boolean>(openDialog);
    const [changed, setChanged] = useState<boolean>(false);
    const [changedRows, setChangedRows] = useState<RoleDropDown[]>([]);
    const participantService = new ParticipantService();

    const closeDialog = () => {
        setOpen(false);
        onCloseDialog();
    }

    const saveDialog = (): void => {
        participantService.updateParticipantsRole(changedRows)
            .then(
                result => {
                    setChanged(false);
                    setChangedRows([]);
                    refreshCollaborators();
                }
            );
    }

    const addchangedRow = (changedRow: RoleDropDown): void => {
        if (changedRows.length === 0) {
            changedRows.push(changedRow);
        } else {
            let length: Number = changedRows.length;
            for (let index = 0; index < length; index++) {
                if (changedRows[index].project === changedRow.project) {
                    changedRows.splice(index, 1);
                    break;
                } else {
                    changedRows.push(changedRow);
                }
            }
        }

        if (changedRows.length > 0) {
            setChanged(true);
        } else {
            setChanged(false);
        }
    }

    return (
        <Dialog
            open={open}
            classes={{ paper: classes.dialogSize }}>
            <DialogTitle>
                <Typography className={classes.textStyle} component="h4">
                    Projects of {collaborator.name || collaborator.email}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <ProjectList
                    projectManagerId={projectManagerId}
                    collaborator={collaborator}
                    refreshCollaborators={refreshCollaborators}
                    saveChangedRow={(changedRow) => addchangedRow(changedRow)}
                    changedRows={changedRows} />
            </DialogContent>
            <DialogActions>
                <Button color="primary" variant="outlined" onClick={closeDialog}>CANCEL</Button>
                {changed ? <Button color="primary" variant="contained" onClick={saveDialog}>SAVE</Button> : ""}
            </DialogActions>
        </Dialog>
    )
}