import useMediaQuery from '@material-ui/core/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import React, { useContext, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { ModalContext } from '../contexts/ModalContext';
import checkSubscriptionExpired from '../checkIfSubscriptionExpired';
import { UserContext } from '../contexts/UserContext';
import { useHistory } from 'react-router-dom';
import config from '../../config/config';
import { DialogContent, DialogTitle } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { WorkflowItem } from './workflow';
import SchedulerService from '../../services/schedulerService';
import useLoadingEffect from '../load/useLoadingEffect';
import { SubscriptionType } from './subscriptionType';
import { ResponseError } from '../../services/grapqhlResponse';
import { SnackbarContext } from '../contexts/SnackbarContext';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "70vw",
        paddingLeft: "7%",
        paddingRight: "7%",
        paddingTop: "1%",
        paddingBottom: "5%",
        minHeight: "50vh",
        "@media only screen and (max-width: 2200px)": {
            minHeight: "50vh"
        },
        "@media only screen and (max-height: 760px)": {
            paddingTop: "1%",
            paddingBottom: "1%",
        },
        "@media only screen and (min-width: 600px) and (max-width: 1000px)": {
            width: "100vw",
            paddingLeft: "1%",
            paddingRight: "1%",
        }
    },
    content: {
        display: "flex"
    }
}));

const SubscriptionChangeModal = () => {
    const [user,] = useContext(UserContext);
    const history = useHistory();
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [modalProps, setModalProps] = useContext(ModalContext);
    const [workflowIndex, setWorkflowIndex] = useState<number>(0);
    const [, notifyUser] = useContext(SnackbarContext);

    const toType = modalProps.subscriptionChangeModal.to;
    const workflow = modalProps.subscriptionChangeModal.workflow;

    const [loadingDowngrade, callDowngradeWith] = useLoadingEffect(
        (data: { to: SubscriptionType, projectsToBeDeleted: Array<any>, participantsToBeRemoved: Array<any> }) => new SchedulerService().scheduleDowngrade(
            data.to, data.projectsToBeDeleted, data.participantsToBeRemoved
        ),
        {
            onSuccess: (data, response) => {
                notifyUser({
                    show: true,
                    type: "success",
                    message: `Downgrade to ${toType} successful`
                });
                setModalProps({
                    ...modalProps,
                    subscriptionModal: {
                        show: true,
                        tab: 2
                    },
                    subscriptionChangeModal: {
                        show: false,
                        to: undefined,
                        workflow: {
                            type: undefined,
                            plan: undefined,
                            steps: []
                        }
                    }
                });
            },
            onError: (error: ResponseError) => notifyUser({
                show: true,
                type: "error",
                message: `Could not downgrade ${toType}`
            })
        });

    const pay = () => {
        console.log('pay');
    }

    const downgrade = () => {
        callDowngradeWith({to: toType, projectsToBeDeleted: [], participantsToBeRemoved: []});
    }

    const primary = () => {
        const primaryFunction = new Map<WorkflowItem, () => void>(
            [
                ['PAYMENT_METHOD', pay],
                ['DOWNGRADE', downgrade]
            ]
        ).get(workflow[workflowIndex]);

        if (primaryFunction) {
            primaryFunction();
        } else {
            next();
        }
    }

    const cancel = () => {
        if (workflowIndex === 0) {
            setWorkflowIndex(0);
            setModalProps({
                ...modalProps,
                subscriptionChangeModal: {
                    show: false,
                    to: undefined,
                    workflow: {
                        type: undefined,
                        plan: undefined,
                        steps: []
                    }
                },
                subscriptionModal: {
                    show: true,
                    tab: 2
                }
            });
            checkSubscriptionExpired(user,
                () => history.push(config.source.projectList),
                downgradeTo => console.log('downgradeTo', downgradeTo)
            );
        } else {
            back();
        }
    };

    const primaryButtonText = () => {
        return new Map<WorkflowItem, string>(
            [
                ['PAYMENT_METHOD', 'PAY'],
                ['DOWNGRADE', 'DOWNGRADE']
            ]
        ).get(workflow[workflowIndex]) || 'NEXT';
    }

    const next = () => {
        setWorkflowIndex(workflowIndex + 1);
    }

    const back = () => {
        setWorkflowIndex(workflowIndex - 1);
    }

    return (
        <Dialog
            fullScreen={fullScreen}
            open={modalProps.subscriptionChangeModal.show}
            onClose={cancel}
            aria-labelledby="responsive-dialog-title"
            maxWidth="lg"
        >
            <DialogTitle>
                CHANGE SUBSCRIPTION
            </DialogTitle>
            <DialogContent className={classes.content} dividers>
                <div className={classes.root}>
                    {workflow[workflowIndex]}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={cancel} color="primary" variant="outlined">Cancel</Button>
                <Button disabled={loadingDowngrade} onClick={primary} color="primary" variant="contained">{primaryButtonText()}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default SubscriptionChangeModal;