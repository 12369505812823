import { useState, useEffect } from "react";
import waitMinimumTime from "./waitMinimumTime";
import { DateTime } from "luxon";
import { ResponseError } from "../../services/grapqhlResponse";


export type ActionFunction<ACTION_ARGUMENT, ACTION_RESPONSE> = (arg: ACTION_ARGUMENT) => Promise<ACTION_RESPONSE>;

/**
 * Usage:
 * 
 * Define useLoadinEffect with attribute loading and callActionWith. 
 * Using callActionWith is better than setActionArguments because it explains better that also the action is called and not only arguments are set.
 * e.g.
 * const [loading, callActionWith] = useLoadingEffect((data: any) => authenticate(data));
 *
 * and then call callActionWith(<arguments) and the action is called which was defined wiht useLoadingEffect
 * e.g.  
 * callActionWith(data);
 */
const useLoadingEffect = <ACTION_ARGUMENT, ACTION_RESPONSE>(
    action: ActionFunction<ACTION_ARGUMENT, ACTION_RESPONSE>,
    actionResponse: {
        onSuccess: (input: ACTION_ARGUMENT, response: ACTION_RESPONSE) => void,
        onError: (error: ResponseError) => void
    },
    setCustomerLoader?: (loading: boolean) => void): [boolean, (trigger: ACTION_ARGUMENT) => void] => {

    const miminumWaitTime = 500;

    const [loading, setLoading] = useState(false);
    const [actionArguments, setActionArguments] = useState<ACTION_ARGUMENT>();

    useEffect(() => {
        if (actionArguments != null) {
            setLoading(true);
            setCustomerLoader && setCustomerLoader(true);
            const startTime = DateTime.local();
            action(actionArguments)
                .then(response => waitMinimumTime(startTime, miminumWaitTime).then(() => response))
                .then(response => {
                    setLoading(false);
                    setCustomerLoader && setCustomerLoader(false);
                    actionResponse.onSuccess(actionArguments, response);
                    setActionArguments(null);
                    return response;
                })
                .catch(error => {
                    setActionArguments(null);
                    waitMinimumTime(startTime, miminumWaitTime).then(() => {
                        setLoading(false);
                        setCustomerLoader && setCustomerLoader(false);
                        actionResponse.onError(error);
                        return error;
                    })
                }
                );
        }

    }, [actionArguments]);

    return [loading, setActionArguments];
}
export default useLoadingEffect;