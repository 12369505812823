import { Client } from '../utils/client';
import { ApolloClient, gql } from 'apollo-boost';
import { onResponse, createError } from './grapqhlResponse';

export class ShareRiskService {

  client(): ApolloClient<any> {
    return new Client().createApolloClient();
  }


  getShareIdByUserIdAndProjectId(projectId, projectName) {
    const getShareIdQuery = gql`
        query {
            getShareIdByUserIdandProjectId(projectId: "${projectId}", projectName: "${projectName}") {
            shareId
          }
        }`;

    return this.client().query<{ getShareIdByUserIdandProjectId: any }>({
      query: getShareIdQuery,
      errorPolicy: 'all'
    }).then(response =>
      onResponse(
        response,
        data => data.getShareIdByUserIdandProjectId
      )
    ).catch(createError);
  }


  getShareRiskMatrixByShareId(shareId) {
    const getShareRiskMatrixQuery = gql`
        query {
            getShareRiskMatrixByShareId(shareId: "${shareId}") {
            userId
            projectId
            projectName
          }
        }`;

    return this.client().query<{ getShareRiskMatrixByShareId: any }>({
      query: getShareRiskMatrixQuery,
      errorPolicy: 'all'
    }).then(response =>
      onResponse(
        response,
        data => data.getShareRiskMatrixByShareId
      )
    ).catch(createError);
  }
}