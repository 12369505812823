import { Client } from '../utils/client';
import { ApolloClient, gql } from 'apollo-boost';
import { TokenProvider } from '../utils/tokenProvider';
import { Page, PageInfinity, PageNext } from '../components/pagination/page';
import { onResponse, createError } from './grapqhlResponse';
import { SubscriptionType } from '../components/Subscription/subscriptionType';
import Workflow from '../components/Subscription/workflow';
import { Constraints } from '../utils/jwt-token';

export default class SubscriptionService {

    private readonly tokenProvider: any;

    constructor() {
        this.tokenProvider = TokenProvider();
    }

    client(): ApolloClient<any> {
        return new Client().createApolloClient();
    }

    getSubscriptionLimitsFor(type: SubscriptionType): Promise<Constraints> {
        const getSubscriptionLimitsFor = gql`{
            getSubscriptionLimitsFor(type: "${type}") {
                projectLimit,
                participantLimit
            }
        }
        `;

        return this.client().query<{ getSubscriptionLimitsFor: any }>({
            query: getSubscriptionLimitsFor,
            errorPolicy: 'all'
        }).then(response => {
            return onResponse(
                response,
                data => data.getSubscriptionLimitsFor
            );
        }).catch(createError);
    }

    getSubscriptionWorkflow(to: SubscriptionType): Promise<Workflow> {
        const getSubscriptionWorkflow = gql`{
            getSubscriptionWorkflow(to: "${to}") {
                type,
                plan,
                steps
            }
        }
        `;

        return this.client().query<{ getSubscriptionWorkflow: any }>({
            query: getSubscriptionWorkflow,
            errorPolicy: 'all'
        }).then(response => {
            return onResponse(
                response,
                data => data.getSubscriptionWorkflow
            );
        }).catch(createError);
    }

    getUpcomingInvoiceDetails(type: SubscriptionType) {
        const getUpcomingInvoiceDetails = gql`{
            getUpcomingInvoiceDetails(type: "${type}"){
                isPeriodMonthly
                partialPrice
            }
        }
    `;

        return this.client().query<{ getUpcomingInvoiceDetails: any }>({
            query: getUpcomingInvoiceDetails,
            errorPolicy: 'all'
        }).then(response => {
            const resp = onResponse(
                response,
                data => data.getUpcomingInvoiceDetails
            );
            return resp;
        }).catch(createError);
    }

    changeSubscription(plan: string, period?: string) {
        const upgrade = gql`
            mutation {
                changeSubscription(
                    plan: "${plan}",
                    period: ${period != null ? `"${period}"` : null}
                )
            }
        `;

        return this.client().mutate<{ changeSubscription: any }>({
            mutation: upgrade,
            errorPolicy: 'all'
        }).then(response => {
            const resp = onResponse(
                response,
                data => data.changeSubscription
            );
            return resp;
        }).catch(createError);
    }

    downgradeSubscription(plan: string) {
        const upgrade = gql`
            mutation {
                downgradeSubscription(
                    to: "${plan}",
                )
            }
        `;

        return this.client().mutate<{ downgradeSubscription: any }>({
            mutation: upgrade,
            errorPolicy: 'all'
        }).then(response => {
            const resp = onResponse(
                response,
                data => data.downgradeSubscription
            );
            return resp;
        }).catch(createError);
    }

    confirmDowngradeSubscription(projects: Array<string>, participtans: Array<string>) {
        const confirm = gql`
            mutation {
                confirmDowngradeSubscription(
                    selectedProjects: [${projects.map(p => `"${p}"`).join()}],
                    selectedParticipants: [${participtans.map(p => `"${p}"`).join()}],
                )
            }
        `;

        return this.client().mutate<{ confirmDowngradeSubscription: any }>({
            mutation: confirm,
            errorPolicy: 'all'
        }).then(response => {
            const resp = onResponse(
                response,
                data => data.confirmDowngradeSubscription);
            return resp;
        }
        ).catch(createError);
    }

    getPaymentAmounts(type) {
        const amounts = gql`{
            getPaymentAmounts( type: "${type}") {
                montly
                yearly
            }
        }`;

        return this.client().query<{ getPaymentAmounts: any }>({
            query: amounts,
            errorPolicy: 'all',
            fetchPolicy: "no-cache"
        }).then(response =>
            onResponse(
                response,
                data => data.getPaymentAmounts
            )
        ).catch(createError);
    }

    createSubscription(
        { plan, period, paymentId, selectedProjects, selectedParticipants }:
            { plan: string, period: string, paymentId?: string, selectedProjects?: Array<string>, selectedParticipants?: Array<string> }) {
        const buy = gql`
            mutation {
                buySubscription(
                    plan: "${plan}",
                    period: "${period}",
                    paymentId: "${paymentId}",
                    ${selectedProjects ? `selectedProjects: [${selectedProjects.map(p => `"${p}"`).join()}],` : '' }
                    ${selectedParticipants ? `selectedParticipants: [${selectedParticipants.map(p => `"${p}"`).join()}],` : '' }
                )
            }
        `;

        return this.client().mutate<{ buySubscription: any }>({
            mutation: buy,
            errorPolicy: 'all'
        }).then(response => {
            const resp = onResponse(
                response,
                data => data.buySubscription);
            return resp;
        }
        ).catch(createError);
    }

    fetchInvoices(limit, startingBefore = null, startingAfter = null): Promise<PageInfinity<any>> {
        const fetchInvoices = gql`
            query {
                fetchInvoices(
                    limit: ${limit},
                    ${startingBefore ? `startingBefore: "${startingBefore}",` : ''}
                    ${startingAfter ? `startingAfter: "${startingAfter}",` : ''}
                ) {
                    rows {
                        status,
                        invoicePdfUrl,
                        currentPeriodStart,
                        amount,
                        currency,
                        stripeInvoiceNumber
                    },
                    nextStartingBefore
                    nextStartingAfter
                }
            }
        `;

        return this.client().query<{ fetchInvoices: any }>({
            query: fetchInvoices,
            errorPolicy: 'all',
            fetchPolicy: "no-cache"
        }).then(response =>
            onResponse(
                response,
                data => data.fetchInvoices
            )
        ).catch(createError);
    }

    updateCreditCardName(name) {
        const changeCreditCardName = gql`
            mutation {
                changeCreditCardName(
                    name: "${name}"
                )
            }
        `;

        return this.client().mutate<{ changeCreditCardName: any }>({
            mutation: changeCreditCardName,
            errorPolicy: 'all'
        }).then(response => {
            const resp = onResponse(
                response,
                data => data.changeCreditCardName);
            return resp;
        }
        ).catch(createError);
    }
}