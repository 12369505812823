import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import MenuItem from "@material-ui/core/MenuItem";
import { indigo } from '@material-ui/core/colors';
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";
import { useForm, Controller } from "react-hook-form";
import { ClassNameProps } from '../materialUi/classNameProps';
import { useHistory } from "react-router-dom";
import { ParticipantService } from '../../services/participantService';
import { RiskService } from '../../services/riskService';
import RizkiSlider from '../commons/RizkiSlider';
import { SnackbarContext } from '../contexts/SnackbarContext';
import { RizkiHistory } from '../history/rizkiHistory';
import config from '../../config/config';
import { ResponseError, handleError, logError } from '../../services/grapqhlResponse';
import { UserContext } from '../contexts/UserContext';
import useLoadingEffect from '../load/useLoadingEffect';
import RizkiLoadingEffect from '../load/RizkiLoadingEffect';
import { InputField } from '../forms/inputField';
import required from '../../utils/form/validators/isRequired';
import { isEmpty } from '../../utils/isEmpty';

const useStyles = makeStyles((theme) => ({
    formGrid: {
        paddingLeft: "5%",
        paddingRight: "5%",
        paddingBottom: "3%",
        paddingTop: "3%"
    },
    buttonGrid: {
        paddingLeft: "5%",
        paddingRight: "5%",
        paddingBottom: "3%"
    },
    disabledButton: {
        "&:disabled": {
            backgroundColor: indigo[200],
            color: "white"
        }
    }
}));

const theme = createMuiTheme();

theme.typography.h4 = {
    fontSize: '1.3rem',
    '@media (min-width:600px)': {
        fontSize: '1.8rem',
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: '2.0rem',
    },
};

const currencies = [
    {
        value: "USD",
        label: "$"
    },
    {
        value: "EUR",
        label: "€"
    }
];

NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (<NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
            onChange({
                target: {
                    name: props.name,
                    value: values.value,
                },
            });
        }}
        allowNegative={false}
        allowLeadingZeros={false}
        thousandSeparator={'.'}
        decimalSeparator={','}
    />
    );
}

interface Properties {
    projectId, riskId, isFirstRisk, setRiskModal
}

const RiskModalContent = ({ projectId, riskId, isFirstRisk, setRiskModal }: Properties & ClassNameProps) => {
    const classes = useStyles();

    const participantService = new ParticipantService();
    const riskService = new RiskService();
    const history = useHistory<RizkiHistory>();
    const [user,] = useContext(UserContext);


    const initialOwner = [{
        id: user.user_id,
        name: user.name
    }]

    const initialState = {
        projectId: projectId,
        name: "",
        shortDescription: "",
        owner: user.user_id,
        cost: "",
        currency: "EUR",
        detailedDescription: " ",
        countermeasure: "",
        riskType: "1",
        trend: "1",
        probability: 1,
        impact: 1
    }

    const [riskOwners, setRiskOwners] = useState(initialOwner)
    const [values, setValues] = useState(null);
    const [, notifyUser] = useContext(SnackbarContext);
    const [closeRiskModal, setCloseRiskModal] = useState(false);
    const [restoreInitialState, setRestoreInitialState] = useState(false);
    const { register, handleSubmit, errors, control, reset } = useForm();

    useEffect(() => {
        if (riskId) {
            const getRiskData = () => {
                riskService.getRiskById(riskId)
                    .then(response => {
                        const newState = {
                            projectId: projectId,
                            name: response.name,
                            shortDescription: response.shortDescription,
                            owner: response.owner,
                            cost: response.cost,
                            currency: response.currency,
                            detailedDescription: response.detailedDescription,
                            countermeasure: response.countermeasure,
                            riskType: response.riskType.toString(),
                            trend: response.trend.toString(),
                            probability: response.probability,
                            impact: response.impact
                        }
                        setValues(newState);
                    }).catch(error => handleError(error, history, config, notifyUser))
            }
            getRiskData();
        }
        else {
            setValues(initialState);
        }
    }, [])

    useEffect(() => {
        const getProjectData = () => {

            const tempOwners = [...riskOwners];
            participantService.participantsByProjectId(projectId)
                .then(response => {
                    response.map(member => {
                        if (member.user) {
                            tempOwners.push({ id: member.userId, name: member.user.name });
                        }
                    })
                    setRiskOwners(tempOwners);
                }).catch((err: ResponseError) => logError(err));
        }
        getProjectData();
    }, []);

    const [loadingOnAddRisk, callActionOnAddRisk] = useLoadingEffect(
        (data: any) => saveRiskViaRiskService(data),
        {
            onSuccess: () => riskAddedSuccessfully(),
            onError: (error: ResponseError) => handleError(error, history, config, notifyUser)
        });

    const saveRiskViaRiskService = (values: any): Promise<any> => {
        return riskService.saveRisk(values)
            .then(newRisk => riskService.saveRiskHistory(newRisk.id, "Risk Added", values));
    };

    const riskAddedSuccessfully = () => {
        if (closeRiskModal) {
            setRiskModal(false);
            setCloseRiskModal(false);
        }

        if (restoreInitialState) {
            reset(initialState);
            setRestoreInitialState(false);
        }
    };

    const saveRisk = (data) => {
        callActionOnAddRisk(data);
    };

    // this is a temporal workaround for removing . and , because formatNumber doesn't do it in form
    const removeDeviders = (cost) => {
        return cost.replaceAll(".", "");
    }

    const prepareData = (data) => {
        const newdata = { ...data, projectId };
        newdata.cost = removeDeviders(data.cost);

        return newdata;
    }

    const handleSaveNextClick = (data) => {
        const newdata = prepareData(data);
        setRestoreInitialState(true);
        saveRisk(newdata);
    }

    const handleAddRiskClick = (data) => {
        const newdata = prepareData(data);
        setCloseRiskModal(true);
        saveRisk(newdata);
    }

    const handleUpdateRiskClick = (data) => {
        const newdata = prepareData(data);
        const changedFileds = getChangedFields(values, newdata);

        if (!isEmpty(changedFileds)) {
            callActionOnEditRisk(newdata);
        }
        else {
            handleClose();
        }
    }

    const getChangedFields = (oldObj, newObj) => {
        const changedFileds = {};

        Object.entries(newObj).forEach(([key, value]) => {
            if (oldObj[key] !== value) {
                if (["probability", "impact"].includes(key)) {
                    changedFileds["probability"] = newObj["probability"];
                    changedFileds["impact"] = newObj["impact"];
                }
                else {
                    changedFileds[key] = value;
                }
            }
        });
        return changedFileds;
    }


    const [loadingOnEditRisk, callActionOnEditRisk] = useLoadingEffect(
        (data: any) => updateRisk(data),
        {
            onSuccess: () => riskEditedSuccessfully(),
            onError: (error: ResponseError) => handleError(error, history, config, notifyUser)
        });

    const updateRisk = (data) => {
        return riskService.updateRisk(data, riskId)
            .then(() => {
                return riskService.saveRiskHistory(
                    riskId,
                    "Risk Edited",
                    getChangedFields(values, data))
            })
            .then(() => {
                return riskService.addViewedUser(riskId, true);
            });
    };

    const riskEditedSuccessfully = () => {
        handleClose();
    };

    const handleCancelClick = () => {
        handleClose();
    }

    const handleClose = () => {
        setRiskModal(false);
    };

    return (
        <div>
            {
                values == null
                    ? <div style={{
                        position: 'absolute', left: '50%', top: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}>
                        <RizkiLoadingEffect loading={true} />
                    </div>
                    :
                    <form onSubmit={handleSubmit(handleUpdateRiskClick)} noValidate>
                        <Grid container direction="column">

                            <Typography id="welcome" gutterBottom variant="h4" style={{ textAlign: "center", paddingLeft: "5%", paddingRight: "5%", paddingTop: "3%", fontSize: "3vh" }}>
                                {riskId ? "Great job so far, edit your risk!" : !isFirstRisk ? "Great job so far, add your first risk!" : "Great job so far, add your risk!"}
                            </Typography>

                            <Grid container direction="column" className={classes.formGrid} spacing={2} >
                                <Grid item lg={12}>
                                    <Grid container justify="center" spacing={5}>
                                        <Grid key={0} item lg={6} xs={12}>
                                            <InputField
                                                disabled={loadingOnEditRisk || loadingOnAddRisk}
                                                defaultValue={values.name}
                                                name="name"
                                                label="Risk name"
                                                required={true}
                                                autoFocus={true}
                                                inputRef={register({
                                                    pattern: {
                                                        value: /^[^\\\"´`]*$/,
                                                        message: 'A name cannot contain these symbols: \\"/´`'
                                                    },
                                                    validate: {
                                                        required: required("Risk name"),
                                                    }
                                                })}
                                                inputProps={{ maxLength: 40 }}
                                                errors={errors}
                                            />
                                        </Grid>
                                        <Grid key={1} item lg={6} xs={12}>
                                            <InputField
                                                disabled={loadingOnEditRisk || loadingOnAddRisk}
                                                defaultValue={values.shortDescription}
                                                name="shortDescription"
                                                label="Short description of the risk"
                                                required={true}
                                                inputRef={register({
                                                    pattern: {
                                                        value: /^[^\\\"´`]*$/,
                                                        message: 'A name cannot contain these symbols: \\"/´`'
                                                    },
                                                    validate: {
                                                        required: required("Short description"),
                                                    }
                                                })}
                                                inputProps={{ maxLength: 100 }}
                                                errors={errors}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item lg={12}>
                                    <Grid container justify="center" spacing={5}>
                                        <Grid key={2} item lg={6} xs={12}>
                                            <Controller
                                                name={"owner"}
                                                defaultValue={values.owner}
                                                control={control}
                                                as={
                                                    <TextField
                                                        margin="normal"
                                                        disabled={loadingOnEditRisk || loadingOnAddRisk}
                                                        id="owner"
                                                        name="owner"
                                                        label="Risk Owner"
                                                        select
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                    >
                                                        {riskOwners.map(option => (
                                                            <MenuItem key={option.id} value={option.id}>
                                                                {option.name}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                }
                                            />
                                        </Grid>
                                        <Grid key={3} item lg={3} xs={12}>
                                            <InputField
                                                defaultValue={values.cost}
                                                name="cost"
                                                disabled={loadingOnEditRisk || loadingOnAddRisk}
                                                label="Risk costs"
                                                required={true}
                                                errors={errors}
                                                inputProps={{ maxLength: 12 }}
                                                inputRef={register({
                                                    required: true,
                                                    pattern: {
                                                        value: /^[0-9]+$/,
                                                        message: "Risk cost can only contain a positive whole number"
                                                    },
                                                    validate: {
                                                        notZero: value => value != 0 || "Risk cost can only contain a positive whole number"
                                                    }
                                                })}
                                            />
                                        </Grid>
                                        <Grid key={4} item lg={3} xs={12}>
                                            <Controller
                                                name={"currency"}
                                                defaultValue={values.currency}
                                                control={control}
                                                as={
                                                    <TextField
                                                        margin="normal"
                                                        disabled={loadingOnEditRisk || loadingOnAddRisk}
                                                        id="currency"
                                                        name="currency"
                                                        select
                                                        label="Currency"
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                    >
                                                        {currencies.map(option => (
                                                            <MenuItem key={option.value} value={option.value}>
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item lg={12}>
                                    <Grid container justify="center" spacing={5}>
                                        <Grid key={5} item lg={6} xs={12}>

                                            <InputField
                                                disabled={loadingOnEditRisk || loadingOnAddRisk}
                                                name="detailedDescription"
                                                label="Detail description of the risk"
                                                defaultValue={values.detailedDescription}
                                                required={true}
                                                multiline
                                                rows={4}
                                                inputRef={register({
                                                    pattern: {
                                                        value: /^[^\\\"´`]*$/,
                                                        message: 'This field cannot contain these symbols: \\"/´`'
                                                    },
                                                    validate: {
                                                        required: required("Detail description"),
                                                    }
                                                })}
                                                inputProps={{ maxLength: 500 }}
                                                errors={errors}
                                            />
                                        </Grid>
                                        <Grid key={6} item lg={6} xs={12}>
                                            <InputField
                                                disabled={loadingOnEditRisk || loadingOnAddRisk}
                                                name="countermeasure"
                                                label="Countermeasure"
                                                defaultValue={values.countermeasure}
                                                required={true}
                                                multiline
                                                rows={4}
                                                inputRef={register({
                                                    pattern: {
                                                        value: /^[^\\\"´`]*$/,
                                                        message: 'This field cannot contain these symbols: \\"/´`'
                                                    },
                                                    validate: {
                                                        required: required("Countermeasure"),
                                                    }
                                                })}
                                                inputProps={{ maxLength: 500 }}
                                                errors={errors}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item lg={12}>
                                    <Grid container justify="center" spacing={5}>
                                        <Grid key={7} item lg={6} xs={12}>
                                            {/*
                                // @ts-ignore */}
                                            <FormLabel component="legend" labelplacement="start">RISK TYPE</FormLabel>
                                            <Controller
                                                control={control}
                                                defaultValue={values.riskType === "1" ? "1" : "2"}
                                                name="riskType"
                                                as={
                                                    <RadioGroup
                                                        aria-label="riskType"
                                                        defaultValue={"top"}
                                                        name="position"
                                                    >
                                                        <FormControlLabel
                                                            disabled={loadingOnEditRisk || loadingOnAddRisk}
                                                            name="riskType"
                                                            control={<Radio color="primary" />}
                                                            label="Potential"
                                                            value={"1"}
                                                            labelPlacement="end"
                                                            style={{ marginRight: "15%" }}
                                                        />
                                                        <FormControlLabel
                                                            disabled={loadingOnEditRisk || loadingOnAddRisk}
                                                            name="riskType"
                                                            value="2"
                                                            control={<Radio color="primary" />}
                                                            label="Already occurred"
                                                            labelPlacement="end"
                                                        />
                                                    </RadioGroup>
                                                }
                                            />
                                        </Grid>
                                        <Grid key={8} item lg={6} xs={12}>
                                            <FormLabel component="legend">TREND</FormLabel>
                                            <Controller
                                                control={control}
                                                defaultValue={values.trend === "1" ? "1" : "2"}
                                                name="trend"
                                                as={
                                                    <RadioGroup
                                                        aria-label="trend"
                                                        defaultValue={"top"}
                                                        name="position"
                                                    >
                                                        <FormControlLabel
                                                            disabled={loadingOnEditRisk || loadingOnAddRisk}
                                                            name="trend"
                                                            control={<Radio color="primary" />}
                                                            value="1"
                                                            label="Ascending"
                                                            labelPlacement="end"
                                                            style={{ marginRight: "15%" }}
                                                        />
                                                        <FormControlLabel
                                                            disabled={loadingOnEditRisk || loadingOnAddRisk}
                                                            name="trend"
                                                            control={<Radio color="primary" />}
                                                            value="2"
                                                            label="Falling"
                                                            labelPlacement="end"
                                                        />
                                                    </RadioGroup>
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container justify="center" spacing={5}>
                                        <Grid key={9} item lg={6} xs={12}>
                                            <Typography id="probability" gutterBottom>
                                                PROBABILITY
                                            </Typography>
                                            <Controller
                                                control={control}
                                                name="probability"
                                                defaultValue={values.probability}
                                                render={(props) => (
                                                    <RizkiSlider
                                                        {...props}
                                                        onChange={(_, value) => {
                                                            props.onChange(value);
                                                        }}
                                                        disabled={loadingOnEditRisk || loadingOnAddRisk}
                                                        aria-labelledby="probability-slider"
                                                        step={1}
                                                        marks
                                                        min={1}
                                                        max={5}
                                                        track={false}
                                                        valueLabelDisplay="off"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid key={10} item lg={6} xs={12}>
                                            <Typography id="impact" gutterBottom>
                                                IMPACT
                                            </Typography>
                                            <Controller
                                                control={control}
                                                name="impact"
                                                defaultValue={values.impact}
                                                render={(props) => (
                                                    <RizkiSlider
                                                        {...props}
                                                        onChange={(_, value) => {
                                                            props.onChange(value);
                                                        }}
                                                        disabled={loadingOnEditRisk || loadingOnAddRisk}
                                                        aria-labelledby="impact-slider"
                                                        step={1}
                                                        marks
                                                        min={1}
                                                        max={5}
                                                        track={false}
                                                        valueLabelDisplay="off"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid className={classes.buttonGrid} item xs={12}>
                                <Grid container justify="center" spacing={10}>
                                    <Grid key={11} item lg={riskId ? 6 : 4} xs={12}>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            fullWidth
                                            onClick={handleCancelClick}>
                                            CANCEL
                                    </Button>
                                    </Grid>
                                    <Grid key={12} item lg={riskId ? 6 : 4} xs={12}>
                                        {riskId
                                            ?
                                            <Button
                                                disabled={loadingOnEditRisk || loadingOnAddRisk}
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                            >
                                                SAVE
                                        </Button>
                                            :
                                            <Button
                                                disabled={loadingOnEditRisk || loadingOnAddRisk}
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                                onClick={handleSubmit(handleAddRiskClick)}
                                            >
                                                ADD RISK
                                        </Button>}
                                    </Grid>

                                    <div style={{
                                        position: 'absolute', left: '50%', top: '50%',
                                        transform: 'translate(-50%, -50%)'
                                    }}>
                                        <RizkiLoadingEffect loading={loadingOnEditRisk || loadingOnAddRisk} />
                                    </div>
                                    {!riskId ?
                                        <Grid key={13} item lg={4} xs={12}>
                                            <Button
                                                disabled={loadingOnEditRisk || loadingOnAddRisk}
                                                variant="outlined"
                                                color="primary"
                                                fullWidth
                                                onClick={handleSubmit(handleSaveNextClick)}
                                            >
                                                SAVE & NEXT
                                        </Button>
                                        </Grid> : null
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
            }
        </div>
    );

}

export default RiskModalContent;