import React, { useState, useContext } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useForm } from 'react-hook-form';
import { login } from '../utils/authenticationProvider';
import { useHistory } from "react-router-dom";
import { AuthService } from '../services/authService';
import Copyright from './copyright';
import config from '../config/config';
import { useParams } from "react-router-dom";
import { ParamTypes } from './params/paramTypes';
import { Logo } from './logo/logo';
import { ResponseError, logError } from '../services/grapqhlResponse';
import { InputField } from './forms/inputField';
import { UserContext } from './contexts/UserContext';
import RizkiLoadingEffect from './load/RizkiLoadingEffect';
import useLoadingEffect from './load/useLoadingEffect';
import validateInputString from './commons/validateInputString';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Login() {
  const classes = useStyles();
  const [, setUser] = useContext(UserContext);

  const [errorText, setErrorText] = React.useState('');

  const authenticate = (data: any): Promise<any> => {
    return new AuthService().authenticate(
      data.email && data.email.toLowerCase(),
      data.password);
  };

  const redirectAfterLogin = (response) => {
    setUser(response);
    setErrorText("");

    if (!redirected && redirect === 'invoices') {
      setRedirected(true);
      history.push(config.source.redirectToInvoice);
    } else if (!redirected && redirect === 'payment-details') {
      setRedirected(true);
      history.push(config.source.redirectToPayment);
    } else {
      history.push(config.source.projectList);
    }
  };

  const handleError = (error) => {
    logError(error);
    setErrorText("Login failed. Please try again");
    login(null);
  };

  const [loading, callActionWith] = useLoadingEffect(
    (data: any) => authenticate(data),
    {
      onSuccess: (input, response) => redirectAfterLogin(response),
      onError: (error: ResponseError) => handleError(error)
    });

  const history = useHistory();

  const { redirect } = useParams<ParamTypes>();
  const [redirected, setRedirected] = useState(false);

  const { register, handleSubmit, errors } = useForm();

  const onSubmit = data => {
    validateInputString(data.password,
      () => {
        setErrorText("");
        callActionWith(data);
      },
      () => setErrorText("Login failed. Please try again"),
    )
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Logo />
        <Typography component="h1" variant="h5">
          Login
        </Typography>
        <Typography color='error'>
          <br></br>
          {errorText}
        </Typography>

        <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
          <InputField
            label="Email address"
            required={true}
            autoFocus={true}
            name="email"
            inputRef={register({
              required: true,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "Invalid email address"
              }
            })}
            errors={errors}
          />

          <InputField
            label="Password"
            required={true}
            name="password"
            inputRef={register({
              required: true,
            })}
            errors={errors}
            isPassword={true}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            id="signIn"
          >
            Sign In
          </Button>
          <Link href="/recovery" variant="body2">
            Forgot your password?
              </Link>
          <br></br><br></br>
          <Link href="/register" variant="body2">
            Don't have an account? Register now.
              </Link>
        </form>
        <RizkiLoadingEffect withBackdrop={true} loading={loading} />
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}