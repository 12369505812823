import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import RiskModalContent from './RiskModalContent';


const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: "70vh",
        width: "70vw"
    },
    content: {
        display: "flex",
        justifyContent: "center"
    }
}));

const SubscriptionModal = ({ projectId, riskId, isFirstRisk, riskModal, setRiskModal }) => {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClose = () => {
       
    };

    return (
        <Dialog
            fullScreen={fullScreen}
            open={riskModal}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
            maxWidth="xl"
        >

            <DialogContent className={classes.content}>
                <div className={classes.root}>
                    <RiskModalContent
                        className={classes.content} 
                        projectId={projectId} 
                        riskId={riskId} 
                        isFirstRisk={isFirstRisk} 
                        setRiskModal={setRiskModal}  
                    />
                </div>
            </DialogContent>
        </Dialog>
    );
}


export default SubscriptionModal