import React, { createContext, useState} from 'react';
import { ModalProperties } from './modalProperties';

type ModalContextType = [ModalProperties, (n: ModalProperties) => void];
const emptyModalProperties: ModalProperties = {
    errorModal: {
        show: false
    },
    subscriptionModal: {
        show: false,
        tab: 0
    },
    subscriptionChangeModal: {
        show: false,
        to: undefined,
        workflow: {
            type: undefined,
            plan: undefined,
            steps: []
        }
    },
    subscriptionExpiredModal: {
        show: false
    },
    downgradeModal: {
        show: false,
    },
    downgradeToFreeModal: {
        show: false,
    },
    subscriptionProjectSelectModal: {
        show: false,
        participantsToBeRemoved: [],
        projectsToBeDeleted:[]
    },
    subscriptionParticipantSelectModal: {
        show: false
    },
    paymentModal: {
        show: false
    },
    invoiceDetailModal: {
        show: false
    },
    downgradeConfirmModal: {
        show: false,
    },
    projectRisks: {
        selectIndex: 0
    },
    riskDetailModal: {
        show: false,
        risk: null,
        history: undefined,
        selectIndex: 0,
    },
    creditCardDetailsModal: {
        show: false
    },
    PaymentDetails: {
        show: false
    }
};

const ModalContext = createContext<ModalContextType>([emptyModalProperties, () => []]);

const ModalContextProvider = (props) => {
    const [modalProps, setModalProps] = useState<ModalProperties>(emptyModalProperties);

    return (
        <ModalContext.Provider value={[modalProps, setModalProps]}>
            {props.children}
        </ModalContext.Provider>
    );
}

export { ModalContext, ModalContextProvider };