import React, { useContext, useEffect, useState } from "react";
import { ProjectService } from "../../services/projectService";
import RizkiTable, { Row, Rows } from "../commons/RizkiTable";
import { RefreshContext, RefreshContextType } from "../contexts/RefreshContext";
import ConfirmationDialog from "../dialog/confirmationDialog";
import { emptyPageCount, PageCount } from "../pagination/page";
import { PagePosition } from "../pagination/pagePosition";
import { Collaborator } from "./collaborator";
import { CollaboratorService } from "./collaboratorService";

type CollaboratorListProps = {
  userId: string;
  editCollaborator: (collaborator: Collaborator) => void;
  refreshList: boolean;
};

export const CollaboratorList = ({
  userId,
  editCollaborator,
  refreshList,
}: CollaboratorListProps) => {
  const headers = ["USER NAME", "EMAIL"];

  const [collaborators, setCollaborators] = useState<PageCount<Collaborator>>(
    emptyPageCount()
  );
  const [page, setPage] = useState<PagePosition>({ limit: 10, offset: 0 });
  const [refreshProject, setRefreshProject] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [rowToBeDeleted, setRowToBeDeleted] = useState<Row[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [, setRefresh] = useContext<RefreshContextType>(RefreshContext);

  useEffect(() => {
    setLoading(true);
    const service = new CollaboratorService();
    service
      .collaborators(page)
      .then((c) => setCollaborators(c))
      .finally(() => setLoading(false));
  }, [userId, page, refreshList, refreshProject]);

  const rows: Rows = collaborators.rows.map((col) => {
    console.log("col", col);
    return [
      { value: [col.id, col.name, col.email], type: "Avatar", roleType: col.type },
      { value: col.email, type: "string" },
      { value: col.id, type: "string", hidden: true },
      { value: col.type, type: "string", hidden: true },
    ];
  });

  const findCollaborator = (row: Row[]): Collaborator =>
    collaborators.rows.find(
      (c) => c.id === row[2].value && c.type === row[3].value
    );

  const reshreshProject = () => {
    setRefreshProject(!refreshProject);
    setRefresh((refresh) => ({
      ...refresh,
      refreshProject: !refresh.refreshProject,
    }));
  };

  const deleteParticipantFromProjectAll = (row: Row[]) => {
    setLoading(true);
    setShowConfirmation(false);
    const service = new ProjectService();
    service
      .deleteParticipantFromAllProject(userId, row[2].value, row[3].value)
      .then(() => {
        reshreshProject();
        setRowToBeDeleted([]);
      })
      .catch(() => setLoading(false));
  };

  const askForDeleteParticipantFromProjectAll = (row: Row[]) => {
    setRowToBeDeleted(row);
    setShowConfirmation(true);
  };

  return (
    <>
      <RizkiTable
        headers={headers}
        data={{ count: collaborators.count, rows: rows }}
        loading={loading}
        queryNewPage={setPage}
        noDataMessage="No Collaborators exist"
        pageSize={10}
        actions={[
          {
            action: "edit",
            callback: (row) => editCollaborator(findCollaborator(row)),
          },
          {
            action: "delete",
            callback: (row) => askForDeleteParticipantFromProjectAll(row),
          },
        ]}
      />
      <ConfirmationDialog
        show={showConfirmation}
        onCancel={() => setShowConfirmation(false)}
        onConfirmation={() => deleteParticipantFromProjectAll(rowToBeDeleted)}
        title={"Remove participant"}
        confirmationQuestion={`Are you sure you want to remove participant 
                ${
                  rowToBeDeleted.length > 0
                    ? rowToBeDeleted[0].value[1] !== ""
                      ? rowToBeDeleted[0].value[1]
                      : rowToBeDeleted[1].value
                    : ""
                }`}
      />
    </>
  );
};
