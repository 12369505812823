import React, { } from 'react';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useForm } from 'react-hook-form';
import { useHistory } from "react-router-dom";
import { RegisterService } from '../services/registerService';
import Copyright from './copyright';
import { Logo } from './logo/logo';
import { ResponseError, logError, handleError } from '../services/grapqhlResponse';
import { InputField } from './forms/inputField';
import RizkiLoadingEffect from './load/RizkiLoadingEffect';
import useLoadingEffect from './load/useLoadingEffect';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function PasswordRecovery() {
    const classes = useStyles();
    const [errorText, setErrorText] = React.useState('');
    const [message, setMessage] = React.useState({ error: false, text: '' });

    const [loading, callActionWith] = useLoadingEffect(
        (data: any) => recoverPassword(data),
        {
            onSuccess: () => notifyUserOnSuccess(),
            onError: (error: ResponseError) => handleError(error)
        });

    const { register, handleSubmit, errors } = useForm();

    const recoverPassword = (data: any) => {
        return new RegisterService().passwordRecovery(data.email);
    }

    const notifyUserOnSuccess = () => {
        setMessage({
            error: false,
            text: 'We have sent you a confirmation email. Please check your inbox.'
        });
    }

    const handleError = (error: ResponseError) => {
        setErrorText(error.message);
        logError(error);
    }


    const onSubmit = data => {
        callActionWith(data);
    };

    return (
        <Container component="main" maxWidth="xs">
            <div className={classes.paper}>
                <Logo />
                <Typography component="h1" variant="h5">
                    Password Recovery
                </Typography>
                <Typography color='error'>
                    <br></br>
                    {errorText}
                </Typography>
                <Typography style={{ color: message.error ? 'red' : 'green' }}>
                    <br />
                    {message.text}
                </Typography>
                <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
                    <InputField
                        label="Email Address"
                        name="email"
                        required={true}
                        errors={errors}
                        autoFocus={true}
                        inputRef={register({
                            required: true,
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: "Invalid email address"
                            }
                        })}
                    />

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit} >
                        email password recovery link
                    </Button>

                    <Link href="/login" variant="body2">
                        Remembering your password ? Go to Login.
                    </Link>

                </form>
                <RizkiLoadingEffect withBackdrop={true} loading={loading} />
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    );
}