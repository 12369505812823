import React, { useState, useContext, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ParticipantList from "./ParticipantList/PartipicantList";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { ServiceContext } from "../services/serviceContext";
import { SnackbarContext } from "./contexts/SnackbarContext";
import { RizkiHistory } from "./history/rizkiHistory";
import config from '../config/config';
import { ResponseError, handleError } from "../services/grapqhlResponse";
import { UserContext } from "./contexts/UserContext";
import { InputField } from "./forms/inputField";
import required from "../utils/form/validators/isRequired";
import getRemovedParticipants from "./ParticipantList/getRemoveParticipants";
import getUpdatedParticipants from "./ParticipantList/getUpdatedParticipants";
import { isEmpty } from "../utils/isEmpty";

export default function NewProject() {
    const history = useHistory<RizkiHistory>();
    const historyState = history.location.state || {};
    const projectService = useContext(ServiceContext).projectService;
    const participantService = useContext(ServiceContext).participantService;
    const [, notifyUser] = useContext(SnackbarContext);
    const [user,] = useContext(UserContext);
    const [loading, setLoading] = useState(false);

    const [participantList, setParticipantList] = useState([]);

    useEffect(() => {
        const getList = () => {
            if (!isEmpty(historyState)) {
                participantService.participantsByProjectId(historyState.id)
                    .then(response => setParticipantList(response))
                    .catch((err: ResponseError) => handleError(err, history, config, notifyUser));
            }
        }
        getList();
    }, [historyState.id]);

    const saveAndClose = (data) => {
        setLoading(true);
        save(data)
            .then((response) => {
                if (response.success) {
                    history.push("/project-list");
                }
            }).catch((err) => {
                console.log("err", err)
            }).finally(() => setLoading(false));
    }

    const saveAndNext = (data) => {
        setLoading(true);
        save(data)
            .then((response) => {
                if (response.success) {
                    history.go(0);
                }
            }).catch((err) => {
                console.log("err", err)
            }).finally(() => setLoading(false));
    }

    function save(data): Promise<{ success: boolean, message: string }> {
        setLoading(true);
        data.project = data.project && data.project.trim();
        return projectService.createProject(
            { ...data, id: historyState.id },
            getUpdatedParticipants(data, participantList),
            user.user_id
        )
            .then(() => {
                return {
                    success: true,
                    message: "OK"
                };
            }).catch((err: ResponseError) => {
                handleError(err, history, config, notifyUser);
                return ({
                    success: false,
                    message: err.message
                });
            }).finally(() => setLoading(false));
    }

    const updateAndClose = (data) => {
        setLoading(true);
        update(data)
            .then((response) => {
                if (response.success) {
                    history.push("/project-list");
                }
            }).catch((err) => {
                console.error("error", err)
            }).finally(() => setLoading(false));
    }

    function update(data) {
        data.project = data.project && data.project.trim();
        return projectService.updateProject(
            historyState.id,
            data.project,
            getUpdatedParticipants(data, participantList),
            getRemovedParticipants(data, participantList)
        )
            .then(response => {
                if (!response.errors) {
                    return {
                        success: true,
                        message: "OK"
                    };
                } else {
                    handleError(response, history, config, notifyUser);
                    return {
                        success: false,
                        message: response.errors[0].message
                    };
                }
            }).catch((err) => {
                handleError(err, history, config, notifyUser);
                return ({
                    success: false,
                    message: err
                });
            });
    }

    const goProjectListPage = () => {
        history.push("/project-list");
    }

    const { control, register, handleSubmit, errors, getValues } = useForm();

    return (
        <form noValidate>
            <Grid container spacing={2} justify="center" alignItems="center">
                <Grid item xs={10} style={{ width: "80%", marginBottom: "2vh" }}>
                    <Typography component="h1" variant="h4">
                        <br />
                        {historyState.actionType === 'update-project' ? 'Welcome to Rizki, update your project' : 'Welcome to Rizki, create your project'}
                    </Typography>
                </Grid>

                <Grid item style={{ backgroundColor: "#ffffff", width: "80%", height: "90%", minHeight: "90%", paddingBottom: "2vh", paddingTop: "2vh" }}>
                    <Grid item xs={9} style={{ marginLeft: "5vh" }}>
                        <InputField
                            label="Project name"
                            name={`project`}
                            autoFocus={historyState.actionType !== 'update-project'}
                            required={true}
                            defaultValue={historyState.name || ''}
                            inputRef={register({
                                pattern: {
                                    value: /^[^\\\"/´`]*$/,
                                    message: 'A name cannot contain these symbols: \\"/´`'
                                },
                                validate: {
                                    required: required("Project name")
                                }
                            }
                            )}
                            inputProps={{ maxLength: 100 }}
                            errors={errors}
                        />
                        <Typography component="h3" variant="h6">
                            <br />
                        Invite your collaborators
                    </Typography>
                    </Grid>
                    <Grid style={{ marginLeft: "5vh" }}>
                        <ParticipantList
                            projectId={historyState.id}
                            alreadyAddedParticipants={participantList.length === 0 ? null : participantList}
                            parLength={participantList.length}
                            focusEmail={historyState.actionType === 'update-project'}
                            handleSubmit={handleSubmit}
                            control={control}
                            register={register}
                            errors={errors}
                            getValues={getValues} />

                    </Grid>
                    <Grid style={{ marginLeft: "initial", marginTop: "5vh" }} item md={10}>
                        <Grid container justify="center" spacing={5} item xs={12}>


                            <Grid item lg={4} xs={12}>
                                <Button variant="outlined" color="primary" fullWidth onClick={goProjectListPage}>Cancel</Button>
                            </Grid>

                            {historyState.actionType !== 'update-project' ? (
                                <Grid item lg={4} xs={12}>
                                    <Button 
                                    disabled = {loading}
                                    id={'project-save-button'} 
                                    variant="contained" 
                                    color="primary" 
                                    fullWidth 
                                    onClick={handleSubmit(saveAndClose)}>Save</Button>
                                </Grid>) : null}


                            {historyState.actionType === 'update-project' ? (
                                <Grid item lg={4} xs={12}>
                                    <Button 
                                    disabled = {loading}
                                    id={'project-save-button'} 
                                    variant="contained" 
                                    color="primary" 
                                    fullWidth 
                                    onClick={handleSubmit(updateAndClose)}>Update</Button>
                                </Grid>) : null}


                            {historyState.actionType !== 'update-project' ? (
                                <Grid item lg={4} xs={12}>
                                    <Button 
                                    disabled = {loading}
                                    id={'project-save-and-next-button'} 
                                    variant="outlined" 
                                    color="primary" 
                                    fullWidth 
                                    onClick={handleSubmit(saveAndNext)}>Save and Next</Button>
                                </Grid>) : null}

                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

        </form>
    );
}
