import React, { useContext, useEffect } from "react";
import "./Home.css";
import "fontsource-roboto";
import { ThemeProvider } from "@material-ui/styles";
import Login from "./components/login";
import Registration from "./components/registration";
import ChangePassword from "./components/changePassword";
import PasswordRecovery from "./components/passwordRecovery";
import NewProject from "./components/newProject";
import ProjectList from "./components/ProjectList/ProjectList";
import RegisterConfirmation from "./components/registerConfirmation";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import MenuAppBar from "./components/menuAppBar";
import { useAuth } from "./utils/authenticationProvider";
import ProjectRisks from "./components/projectRisks";
import rizkiTheme from "./components/commons/RizkiTheme";
import { UserContext } from "./components/contexts/UserContext";
import { ModalContextProvider } from "./components/contexts/ModalContext";
import { RiskContextProvider } from "./components/contexts/RiskContext";
import { RiskExportContextProvider } from "./components/contexts/RiskExportContext";
import { SnackbarContextProvider } from "./components/contexts/SnackbarContext";
import { ServiceContextProvider } from "./services/serviceContext";
import ErrorModal from "./components/commons/ErrorModal";
import ShareRiskInfo from "./components/ShareRiskMatrix/ShareRiskInfo";
import SubscriptionExpiredModal from "./components/Subscription/SubscriptionExpiredModal";
import SubscriptionProjectSelectModal from "./components/Subscription/SubscriptionProjectSelectModal";
import StripeModal from "./components/Payment/StripeModal";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import config from "./config/config";
import CreditKarteDetailsModel from "./components/UserSettings/CreditKarteDetailsModal";
import RizkiSnackbar from "./components/commons/RizkiSnackbar";
import { CssBaseline } from "@material-ui/core";
import { RefreshContextProvider } from "./components/contexts/RefreshContext";
import SubscriptionChangeModal from "./components/Subscription/SubscriptionChangeModal";
import { SubscriptionWorkflowContextProvider } from "./components/Subscription/SubscriptionWorkflowContext";
import SubscriptionModal from "./components/Subscription/SubscriptionModal";
import DowngradeModal from "./components/Subscription/DowngradeModal";
import SubscriptionParticipantSelectModal from "./components/Subscription/SubscriptionParticipantSelectModal";
import DowngradeConfirmModal from "./components/Subscription/DowngradeConfirmModal";
import UpgradeToFreeModal from "./components/Subscription/DowngradeToFreeModal";

const stripePromise = loadStripe(config.stripe.key, { locale: "en" });

function Home() {
  const [, subscriptionExpired] = useAuth();
  const [user] = useContext(UserContext);

  return (
    <div className="Home">
      <ServiceContextProvider>
        <ThemeProvider theme={rizkiTheme}>
          <CssBaseline />
          <Router>
            <div>
              <Switch>
                {user && (
                  <>
                    <ModalContextProvider>
                      <RiskContextProvider>
                        <RiskExportContextProvider>
                          <SnackbarContextProvider>
                            <RefreshContextProvider>
                              <Route
                                /*
                                //@ts-ignore*/
                                path={new RegExp("^/(?!share).*$")}
                                component={MenuAppBar}
                              />
                              {!subscriptionExpired && (
                                <>
                                  <Route
                                    path="/change-password"
                                    component={ChangePassword}
                                  />
                                  <Route
                                    path="/project-list"
                                    component={ProjectList}
                                  />
                                  <Route
                                    path="/project-risks"
                                    component={ProjectRisks}
                                  />
                                  <Route
                                    path="/newproject"
                                    component={NewProject}
                                  />
                                  <Route
                                    path="/edit-project"
                                    component={NewProject}
                                  />
                                  <Route
                                    path="/share/:shareId"
                                    component={ShareRiskInfo}
                                  />
                                  <Route
                                    path="/"
                                    exact
                                    component={ProjectList}
                                  />
                                </>
                              )}
                              <ErrorModal />
                              <SubscriptionExpiredModal />
                              <SubscriptionWorkflowContextProvider>
                                <DowngradeModal />
                                <UpgradeToFreeModal />
                                <SubscriptionModal />
                                <SubscriptionProjectSelectModal />
                                <SubscriptionParticipantSelectModal />
                                <SubscriptionChangeModal />{" "}
                                <Elements stripe={stripePromise}>
                                  <StripeModal />
                                </Elements>
                                <DowngradeConfirmModal />
                              </SubscriptionWorkflowContextProvider>
                              <Elements stripe={stripePromise}>
                                <CreditKarteDetailsModel />
                              </Elements>
                              <RizkiSnackbar />
                            </RefreshContextProvider>
                          </SnackbarContextProvider>
                        </RiskExportContextProvider>
                      </RiskContextProvider>
                    </ModalContextProvider>
                  </>
                )}
                {!user && (
                  <>
                    <Route path="/login" component={Login} />
                    <Route path="/register" component={Registration} />
                    <Route path="/recovery" component={PasswordRecovery} />
                    <Route path="/confirm" component={RegisterConfirmation} />
                    <Route path="/change-password" component={ChangePassword} />
                    <Route path="/share/:shareId" component={ShareRiskInfo} />
                    <Route path="/" exact component={Login} />
                    <Route
                      path="/project-list/:redirect?"
                      exact
                      component={Login}
                    />
                  </>
                )}
              </Switch>
            </div>
          </Router>
        </ThemeProvider>
      </ServiceContextProvider>
    </div>
  );
}

export default Home;
