import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Backdrop } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
        backgroundColor: 'rgba(0,0,0,0.08)'
    },
}));

type Props = {
    withBackdrop?: boolean,
    loading: boolean
}

const RizkiLoadingEffect = ({ withBackdrop, loading }: Props) => {
    const classes = useStyles();
    return (
        withBackdrop
            ?
            <Backdrop
                className={classes.backdrop}
                open={loading}>
                <CircularProgress />
            </Backdrop>
            : loading
                ? <div className={classes.root}>
                    <CircularProgress />
                </div>
                : null
    );
}

export default RizkiLoadingEffect;