import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { grey } from '@material-ui/core/colors';
import moment from 'moment';
import RiskMatrixSvg from '../RiskMatrix/RiskMatrixSvg';

const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: "white"
    },
    cell: {
        borderColor: grey[300],
    }
}));

let initialMatrix = [
    [null, null, null, null, null, null],
    [null, null, null, null, null, null],
    [null, null, null, null, null, null],
    [null, null, null, null, null, null],
    [null, null, null, null, null, null],
    [null, null, null, null, null, null]
]

const setMatrixData = data => {
    const temp = initialMatrix.map(arr => {
        return arr.slice();
    });
    if (data && data.length > 0) {
        data.map(risk => {
            let p = risk.changes
                ? - (risk.risk.probability - 5)
                : - (risk.probability - 5);
            let i = risk.changes
                ? risk.risk.impact
                : risk.impact;
            if (temp[p][i] === null) {
                temp[p][i] = [];
            }
            temp[p][i].push(
                {
                    date: moment(risk.createdAt).format('DD/MM'),
                    id: risk.id,
                    count: risk.votesCount,
                    riskType: risk.changes ? "1" : risk.riskType,
                    riskNumber: risk.riskNumber?.toString(),
                    risk: risk
                }
            )
        })
    }
    return temp;
}

const RiskExportMatrix = ({ risks, selectedRisks, includeRiskIdToExport, pickedRiskHistory }) => {
    const classes = useStyles();

    let matrix;
    if (selectedRisks) {
        if (pickedRiskHistory && pickedRiskHistory.length > 0) {
            matrix = setMatrixData(pickedRiskHistory);
        } else {
            matrix = setMatrixData(selectedRisks);
        }
    } else {
        matrix = setMatrixData(risks);
    }

    return (
        <Box zIndex={-2} className={classes.root} width="100%" height="100%" display="flex" alignItems="center">
            <Box id={"stage-id"} zIndex={-2} bgcolor="white" width="94%" height="100%" display="flex"
                flexDirection="column" justifyContent="center" alignContent="center">
                <RiskMatrixSvg matrixData={matrix} selectedRisks={selectedRisks} pickedRisk={pickedRiskHistory} includeRiskIdToExport={includeRiskIdToExport && (!pickedRiskHistory || pickedRiskHistory.length === 0)} />
            </Box>
        </Box>
    );
}
export default RiskExportMatrix;