import React, { useState, useEffect, useContext } from 'react';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import { makeStyles } from '@material-ui/core/styles';
import withWidth from "@material-ui/core/withWidth";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import IconButton from '@material-ui/core/IconButton';
import { green, red, grey } from '@material-ui/core/colors';
import ParticapantModal from '../participantModal'
import { ParticipantService } from '../../services/participantService';
import CustomAvatar from './CustomAvatar';
import { userIdentification } from './userIdentification';
import { ResponseError } from '../../services/grapqhlResponse';
import { UserContext } from '../contexts/UserContext';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    registered: {
        color: grey[900],
        borderColor: green[500],
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    invited: {
        color: grey[900],
        borderColor: red[500],
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
}));

const ImageAvatars = ({ data, width }) => {
    const classes = useStyles();
    const participantService = new ParticipantService();
    const isSmallScreen = /sm/.test(width);
    const isMediumScreen = /md|lg/.test(width);
    const isLargeScreen = /xl/.test(width);

    const [project,] = useState(data);
    const [participants, setParticipants] = useState<any>();
    const [participantModalOpen, setParticipantModalOpen] = useState(false);
    const [userRole, setUserRole] = useState('Project Member');
    const [user,] = useContext(UserContext);
    const [pars, setPars] = useState<null>();

    const addAvatar = () => {
        setParticipantModalOpen(true)
    }

    useEffect(() => {
        const getList = () => {
            participantService.participantsByProjectId(project.id)
                .then(response => {
                    setParticipants(response);
                }).catch((err: ResponseError) => console.error(err.message));
        }
        getList();
    }, [participantModalOpen]);

    useEffect(() => {
        const setUserRoleInProject = () => {
            if (project.projectManager.toString() === user.user_id.toString()) {
                setUserRole("Project Manager");
            } else {
                const participant = participants.filter(participant => participant.userId === user.user_id.toString());
                participant && participant.length > 0 && setUserRole(participant[0].projectRole);
            }
        };
        if (!!participants) {
            setUserRoleInProject();

            if (participants) {

                setPars(participants.map(par => {
                    return <CustomAvatar
                        imageId={par?.userId}
                        registered={par.email ? false : true}
                        tooltip={userIdentification({ email: par.email, name: par?.user?.name })}
                        reloadImage={participants}
                    />
                }));
}

        }
    }, [participants]);


return (
    <div className={classes.root}>
        <IconButton onClick={addAvatar}>
            {userRole !== 'Project Member' ? <AddCircleOutlineIcon style={{ fontSize: 30 }} color="primary" /> : null}
        </IconButton>
        <AvatarGroup max={isLargeScreen ? 20 : isMediumScreen ? 10 : isSmallScreen ? 5 : 1} spacing={-2}>
            {pars}
        </AvatarGroup>
        {<ParticapantModal openParticipantModal={participantModalOpen} setParticipantModalOpen={setParticipantModalOpen} projectData={project}></ParticapantModal>}

    </div>
)
}

export default withWidth()(ImageAvatars);