import React, { useState, useEffect, useContext } from "react";
import RizkiTable, { Rows } from "../commons/RizkiTable";
import SubscriptionService from "../../services/subscriptionService";
import moment from "moment";
import config from "./config";
import { formatAmount } from "../../utils/utils";
import { PagePositionInfinity } from "../pagination/pagePosition";
import { emptyPageInfinity, isPageBefore, isPageNext, PageInfinity } from "../pagination/page";
import { logError } from "../../services/grapqhlResponse";

const InvoiceModalContent = (props) => {
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState<PageInfinity<any>>(emptyPageInfinity());
  const [page, setPage] = useState<PagePositionInfinity>({
    limit: 5,
    startingBefore: null,
    startingAfter: null,
  });

  const transformInvoiceDataToTableRow = (invoices): Array<Rows> => {
    return invoices.map((invoice) => {
      const amountWithCurrency =
        invoice && formatAmount(invoice.amount) + " " + invoice.currency;
      const invoiceDate = moment(invoice.currentPeriodStart).format(
        "DD.MM.YYYY"
      );
      return [
        {
          value:
            invoice.status !== "draft"
              ? "RIZKI_Invoice_" + invoice.stripeInvoiceNumber
              : "NEXT INVOICE",
          type: "link",
          link: invoice.invoicePdfUrl,
        },
        { value: invoiceDate, type: "string" },
        { value: invoice.stripeInvoiceNumber, type: "string" },
        { value: amountWithCurrency, type: "string" },
      ];
    });
  };

  useEffect(() => {
    const fetchInvoices = () => {
      setLoading(true);
      const subscriptionService = new SubscriptionService();
      subscriptionService
        .fetchInvoices(page.limit, page.startingBefore, page.startingAfter)
        .then((invoicesData) => {
          const invoices = invoicesData.rows || [];
          setRows({
            nextStartingBefore: isPageBefore(invoicesData) ? invoicesData.nextStartingBefore : null,
            nextStartingAfter: isPageNext(invoicesData) ? invoicesData.nextStartingAfter : null,
            rows: transformInvoiceDataToTableRow(invoices),
          });
          setLoading(false);
        })
        .catch((err) => {
          setRows({
            nextStartingAfter: null,
            rows: transformInvoiceDataToTableRow([]),
          });
          setLoading(false);
          logError(err);
        });
    };
    fetchInvoices();
  }, [page]);

  return (
    <RizkiTable
      headers={config.invoiceTableHeaders}
      pageSize={5}
      data={rows}
      loading={loading}
      queryNewPage={setPage}
    />
  );
};

export default InvoiceModalContent;
