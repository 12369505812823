import { ApolloQueryResult, FetchResult } from "apollo-boost";
import { GraphQLError } from "graphql";

export type ResponseError = {
	code: "404" | "STANDARD_ERROR" | "FORBIDDEN" | string;
	message: string;
}

export const onResponse = <T>(
	response: ApolloQueryResult<T> | FetchResult<T, Record<string, any>, Record<string, any>>,
	callback: (data: T) => any) => {
	if (response.errors == null) {
		return callback(response.data)
	}

	throw response.errors;
}

export const createError = (error: Error | ReadonlyArray<GraphQLError>): void => {
	console.log('createError', error);
	if (isGraphqlError(error)) {
		throw {
			code: error[0].extensions.code,
			message: error[0].message
		} as ResponseError;
	}

	throw {
		code: 'STANDARD_ERROR',
		message: error.message
	} as ResponseError;
}

export const isGraphqlError = (error: any): error is ReadonlyArray<GraphQLError> => {
	return Array.isArray(error)
		&& error.length > 0
	// && error[0].hasOwnProperty('extensions')
	// && error[0].hasOwnProperty('originalError');
}

export const handleError = (error: ResponseError, history, config, notifyUserCallback) => {
	if (['404', 'FORBIDDEN'].includes(error.code)) {
		history.push(
			config.source.projectList,
			{
				actionType: 'show-error-message',
				source: "edit-project",
				message: error.message
			});
	} else {
		notifyUserCallback({
			show: true,
			type: "error",
			message: error.message
		});
	}

	console.log("Error: ", error.message);

	notifyUserCallback({
		show: true,
		type: "error",
		message: error.message
	});
}

export const logError = (error: ResponseError) => {
	console.debug(error.message, " ", error.code);
};