import React, { useEffect, useState } from "react";
import { withStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";

import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";

import RizkiLoadingEffect from "../load/RizkiLoadingEffect";
import { Paging } from "../pagination/pagePosition";
import {
  hasBefore,
  hasNext,
  isNext,
  isPageBefore,
  isPageCount,
  isPageNext,
  Page,
} from "../pagination/page";
import { Grid, MenuItem, TextField } from "@material-ui/core";
import { DateTime } from "luxon";
import CustomAvatar from "../Avatar/CustomAvatar";
import { userIdentification } from "../Avatar/userIdentification";

const StyledTableCell = withStyles((theme) => ({
  head: {
    fontWeight: "bold",
    color: "#7B7B7B",
    fontFamily: "Roboto",
    fontSize: "10px",
    letterSpacing: "1.5px",
    lineHeight: "16px",
    opacity: "0.87",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {},
}))(TableRow);

const useStyles = makeStyles({
  table: {
    width: "100%",
  },
  container: {
    overflowX: "unset",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  editPen: {
    color: "#337bff",
  },
  draft: {
    fontWeight: "bold",
    color: "#7B7B7B",
    fontFamily: "Roboto",
    fontSize: "10px",
    letterSpacing: "1.5px",
    lineHeight: "16px",
    opacity: "0.87",
  },
  lastLine: {
    borderBottom: "none"
  }
});

/**
 * Custom table component for Rizki components.
 * Number of headers and columns should match. Otherwise data representation will be miss leading. TODO @Baris check this in unit tests
 *
 * Eg.
 * headers: ['header-name', 'header-name2']
 * rows:    [
 *              [
 *                  {value: 'row1-data', type:'link', link:'www.ogs.at'},
 *                  {value: 'row1-data2', type:'string'
 *              ],
 *              [
 *                  {value: 'row2-data', type:'link', link:'www.ogs.at'},
 *                  {value: 'row2-data2', type:'string'
 *              ]
 *          ]
 * loading: true/false
 *
 * !Important: Currently only 'link' and 'string' row types are supported
 */
type RizkiTableProps = {
  headers: Array<string>;
  data: Page<Array<Row>>;
  pageSize?: number;
  loading: boolean;
  noDataMessage?: string;
  actions?: Array<{ action: PossibleActions; callback: (row: Row[]) => void }>;
  queryNewPage: React.Dispatch<React.SetStateAction<Paging>>;
};

export type PossibleActions = "edit" | "delete";

export type Rows = Array<Array<Row>>;

export type Row = RowString | RowLink | RowAvatar | RoleDropDown;

export type RowString = {
  value: any;
  type: "string" | "dd.MM.yyyy HH:mm";
  hidden?: boolean;
};

export type RowAvatar = {
  value: [number | string, string, string];
  type: "Avatar";
  roleType?: string;
  hidden?: boolean;
};

export type RowLink = {
  value: any;
  type: "link";
  link: string;
  hidden?: boolean;
};

export type RoleDropDown = {
  value: any;
  project: any;
  type: "roleDropDown";
  roleType: string;
  callback: (changedRow: Row) => void;
  hidden?: boolean;
};

type AllTypes =
  | "string"
  | "link"
  | "Avatar"
  | "dd.MM.yyyy HH:mm"
  | "roleDropDown";

const RizkiTable = ({
  headers = [],
  data = { count: 0, nextStartingAfter: null, rows: [] },
  pageSize = 10,
  noDataMessage = "No Data exist",
  loading,
  queryNewPage,
  actions = [],
}: RizkiTableProps) => {
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(pageSize);
  const [pageNumber, setPageNumber] = useState(0);

  const handlePagination = (page, currentRowsPerPage?) => {
    const limit = currentRowsPerPage || rowsPerPage;
    if (isPageCount(data)) {
      const offset = 0 + page * limit;
      queryNewPage({ limit, offset });
    }
  };

  const handleNextPage = () => {
    const limit = rowsPerPage;
    if (isPageNext(data)) {
      queryNewPage({
        limit,
        startingBefore: null,
        startingAfter: data.nextStartingAfter,
      });
    }
  };

  const handleBeforePage = () => {
    const limit = rowsPerPage;
    if (isPageBefore(data)) {
      queryNewPage({
        limit,
        startingBefore: data.nextStartingBefore,
        startingAfter: null,
      });
    }
  };

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage);
    handlePagination(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const currentRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(currentRowsPerPage);
    queryNewPage({ limit: 0, offset: currentRowsPerPage });
    setPageNumber(0);
  };

  const actionsElements = new Map<
    PossibleActions,
    (row: Row[], callback: (row: Row[]) => void) => any
  >([
    [
      "edit",
      (row, callback) => (
        <EditIcon
          className={classes.editPen}
          fontSize="small"
          onClick={() => {
            callback(row);
          }}
        ></EditIcon>
      ),
    ],
    [
      "delete",
      (row, callback) => (
        <DeleteIcon
          className={classes.editPen}
          fontSize="small"
          onClick={() => {
            callback(row);
          }}
        ></DeleteIcon>
      ),
    ],
  ]);

  const typeElements = new Map<AllTypes, (cell: Row) => any>([
    [
      "link",
      (cell: RowLink) => (
        <a href={cell.link}>
          {cell.link ? (
            cell.value
          ) : (
            <span className={classes.draft}>{cell.value}</span>
          )}
        </a>
      ),
    ],
    ["string", (cell: Row) => <span>{cell.value}</span>],
    [
      "dd.MM.yyyy HH:mm",
      (cell: Row) => (
        <span>{DateTime.fromISO(cell.value).toFormat(cell.type)}</span>
      ),
    ],
    [
      "Avatar",
      (cell: RowAvatar) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {cell.roleType === "USER" ? (
            <CustomAvatar imageId={cell.value[0]} tooltip={userIdentification({ email: cell.value[2], name: cell.value[1] })} />
          ) : (
            <CustomAvatar imageId={null} tooltip={userIdentification({ email: cell.value[2], name: cell.value[1] })} />
          )}

          <span style={{ marginLeft: "10px" }}>{cell.value[1]}</span>
        </div>
      ),
    ],
    [
      "roleDropDown",
      (cell: RoleDropDown) => {
        return (
          <TextField
            size="small"
            id="role"
            name="role"
            select
            label="Role"
            defaultValue={cell.value}
            variant="outlined"
            fullWidth
            onChange={(event) => {
              cell.value = event.target.value;
              cell.callback(cell);
            }}
            required
          >
            <MenuItem value={"Project Member"}>Project Member</MenuItem>
            <MenuItem value={"Project Admin"}>Project Admin</MenuItem>
          </TextField>
        );
      },
    ],
  ]);

  return (
    <>
      {data.rows.length === 0 && !loading ? (
        <Grid id="noProjects" container justify="center" alignItems="center">
          {noDataMessage}
        </Grid>
      ) : (
        <TableContainer className={classes.container}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <StyledTableCell key={header}>{header}</StyledTableCell>
                ))}
                {actions.length > 0 && (
                  <StyledTableCell key="actions"></StyledTableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.rows && data.rows.length > 0 ? (
                data.rows.map((row, index) => (
                  <StyledTableRow key={index}>
                    {row
                      .filter((r) => !r.hidden)
                      .map((cell) => (
                        <StyledTableCell component="th" scope="row">
                          {typeElements.get(cell.type)(cell)}
                        </StyledTableCell>
                      ))}
                    {actions.length > 0 && (
                      <StyledTableCell component="th" scope="row" align="right">
                        {actions.map((a) => (
                          <IconButton>
                            {actionsElements.get(a.action)(row, a.callback)}
                          </IconButton>
                        ))}
                      </StyledTableCell>
                    )}
                  </StyledTableRow>
                ))
              ) : (
                <StyledTableRow>
                  <StyledTableCell
                    colSpan={headers.length}
                    align="center"
                    component="th"
                    scope="row"
                  >
                    <div style={{ height: "100%", display: "contents" }}>
                      {!!loading && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            margin: "20px",
                          }}
                        >
                          {<RizkiLoadingEffect loading={loading} />}
                        </div>
                      )}
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
            {isNext(data, pageSize) && isPageCount(data) ? (
              <TableFooter style={{ margin: "20px" }}>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[pageSize]}
                    colSpan={headers.length + (actions.length > 0 ? 1 : 0)}
                    count={data.count}
                    rowsPerPage={rowsPerPage}
                    page={pageNumber}
                    SelectProps={{
                      inputProps: { "aria-label": "rows per page" },
                      native: true,
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            ) : null}
            {isNext(data) ? (
              <TableFooter style={{ margin: "20px" }}>
                <TableRow>
                  <TableCell className={classes.lastLine} align="right" colSpan={headers.length + (actions.length > 0 ? 1 : 0)}>
                    <TableInfinitActions
                      onBefore={handleBeforePage}
                      onNext={handleNextPage}
                      before={hasBefore(data)}
                      next={hasNext(data)}
                    />
                  </TableCell>
                </TableRow>
              </TableFooter>
            ) : null}
          </Table>
        </TableContainer>
      )}
    </>
  );
};

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

const TableInfinitActions = ({ onBefore, onNext, before, next }) => {
  const theme = useTheme();
  const [initial, setInitial] = useState(true);

  const handleBackButtonClick = (event) => {
    initial && setInitial(false);
    onBefore(event);
  };

  const handleNextButtonClick = (event) => {
    initial && setInitial(false);
    onNext(event);
  };

  return (
    <>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={initial || !before}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={!next}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
    </>
  );
};

const TablePaginationActions = (props) => {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default RizkiTable;
