import React from 'react';
import RiskBubble from './RiskBubble';
import styled, { keyframes } from "styled-components";

const pulse = (props) => keyframes`
0% {
    box-shadow: 0 0 0 0 ${props.color}ad;
  }
  70% {
      box-shadow: 0 0 0 10px ${props.color}00;
  }
  100% {
      box-shadow: 0 0 0 0 ${props.color}00;
  }
`;

const StyledDot = styled.div`
    margin: 5px;
    margin-left: 5px;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    font-weight: bold;
    font-size: 8px;
    color: white;
    cursor: pointer;
    animation: ${props => props.animation === true ? pulse(props) : ""} 2s linear infinite;
    background-color: ${props => props.color};
    height: ${props => props.height ? props.height + "px" : "18px"};
    width: ${props => props.width ? props.width + "px" : "18px"};
    line-height: ${props => props.height ? props.height + "px" : "18px"};
`;

const RiskDot = (props) => {
    const bubbleHeight = (props.height && props.height / 1.5) || "10px";
    const bubbleWidth = (props.width && props.width / 1.5) || "10px";

    return (
        <StyledDot {...props} data-testid={props.dotId}
            onClick={() => {
                if (props.onClick) {
                    props.risk && props.selectIndex !== null && props.selectIndex !== undefined && props.onClick(props.risk, props.selectIndex);
                }
            }}
            id={props.dotId}
            title={props.alt}>
            {
                props.count ?
                    <>
                        <RiskBubble dotId={props.dotId} color={props.color} count={props.count} height={bubbleHeight} width={bubbleWidth} position={'topRight'}></RiskBubble>
                        {props.value && props.riskOccurred
                            ? <RiskBubble dotId={props.dotId + '-alreadyOccurred'}
                                color={props.color} count={props.count} occurred={props.riskOccurred}
                                height={bubbleHeight} width={bubbleWidth} position={'bottomRight'} />
                            : null}
                    </>
                    :
                    props.value && props.riskOccurred
                        ? <RiskBubble dotId={props.dotId + '-alreadyOccurred'}
                            color={props.color} count={props.count}
                            height={bubbleHeight} width={bubbleWidth} position={'topRight'} occurred={props.riskOccurred} />
                        : null
            }
            { !props.value && props.riskOccurred ? '!' : props.value ? props.value : null}
        </StyledDot >
    )
}

export default RiskDot;