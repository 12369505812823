import React, { useState, useEffect, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import { RiskService } from "../../services/riskService";
import { ShareRiskService } from "../../services/shareRiskService";
import ShareRiskList from "./ShareRiskList";
import { useParams } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { ParamTypes } from "../params/paramTypes";
import { ResponseError, logError } from "../../services/grapqhlResponse";
import Moment from "moment";
import RiskMatrixSvg from "../RiskMatrix/RiskMatrixSvg";
import { RiskHistory } from "../RiskDetails/riskHistory";
import { DateTime } from "luxon";

const useStyles = makeStyles((theme) => ({
  projectNameStyle: {
    fontSize: "x-large",
  },
}));

let initialMatrix = [
  [null, null, null, null, null, null],
  [null, null, null, null, null, null],
  [null, null, null, null, null, null],
  [null, null, null, null, null, null],
  [null, null, null, null, null, null],
  [null, null, null, null, null, null],
];

const calculateMatrixData = (data) => {
  let temp = initialMatrix.map((arr) => {
    return arr.slice();
  });
  if (data && data.length > 0) {
    data.map((risk, index) => {
      let p = risk.changes ? -(risk.risk.probability - 5) : -(risk.probability - 5);
      let i = risk.changes ? risk.risk.impact : risk.impact;
      if (temp[p][i] === null) {
        temp[p][i] = [];
      }

      risk.changes
        ? temp[p][i].push({
            risk: risk,
            name: Moment(risk.createdAt).format("DD/MM"),
            index: index,
            riskType: "1",
          })
        : temp[p][i].push({
            risk: risk,
            name: risk.name,
            count: risk.votesCount,
            index: index,
            animation: risk.changed,
            riskType: risk.riskType,
          });
    });
  }
  return temp;
};

export default function ShareRiskInfo() {
  const [projectRisks, setProjectRisks] = useState([]);
  const [shareId] = useState(useParams<ParamTypes>().shareId);
  const [projectId, setProjectId] = useState(null);
  const [selectedRisks, setSelectedRisks] = useState([]);
  const [projectName, setProjectName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [matrix, setMatrix] = useState([]);
  const [pickedRisk, setPickedRisk] = useState([]);
  const riskService = new RiskService();
  const shareRiskService = new ShareRiskService();
  const classes = useStyles();
  let selectedRiskId = null;

  useEffect(() => {
    const getProjectId = () => {
      if (shareId) {
        shareRiskService
          .getShareRiskMatrixByShareId(shareId)
          .then((response) => {
            setProjectId(response.projectId);
            setProjectName(response.projectName);
          })
          .catch((err) => {
            setErrorMessage("This project is not found. Maybe it is deleted.");
          });
      }
    };
    getProjectId();
  }, [shareId]);

  const getRisksByProjectId = (projectId) => {
    riskService
      .getRisksByProjectId(projectId)
      .then((risks) => {
        setProjectRisks(risks);
        setMatrix(calculateMatrixData(risks));
      })
      .catch((err: ResponseError) => {
        setErrorMessage(err.message);
        logError(err);
      });
  };

  useEffect(() => {
    const getProject = () => {
      getRisksByProjectId(projectId);
    };
    if (projectId) {
      getProject();
    }
  }, [projectId]);

  useEffect(() => {
    const getHistories = async () => {
      setPickedRisk([]);
      riskService
        .getFullRiskHistoriesByRiskId(selectedRisks[0].id, {
          limit: 10000,
          offset: 0,
        })
        .then((response) => {
          const uniqueDays: Array<RiskHistory> = response.rows
            .filter((item) => item.activity !== "Risk Voted")
            .reduceRight<Array<RiskHistory>>((acc, cur, index, source) => {
              if (index === source.length - 1) {
                return acc.concat(cur);
              }
              const last = acc[acc.length - 1];
              const lastDay = DateTime.fromISO(last.createdAt).toFormat("yyyyMd");
              const curDay = DateTime.fromISO(cur.createdAt).toFormat("yyyyMd");
              if (lastDay === curDay && last.changes.probability && last.changes.impact) {
                return acc;
              }
              return acc.concat(cur);
            }, [])
            .reverse();

          const groupByPI = (acc: Array<RiskHistory>, cur: RiskHistory, index: number, source: Array<RiskHistory>) => {
            if (index === source.length - 1) {
              return acc.concat(cur);
            }
            const last = acc[acc.length - 1];
            if (cur.risk.probability === last.risk.probability && cur.risk.impact === last.risk.impact) {
              return acc;
            }
            return acc.concat(cur);
          };

          const lastChanges = uniqueDays
            .reduceRight<Array<RiskHistory>>(groupByPI, [])
            .slice(0, 15)
            .reverse()
            .map((h, index) => ({ ...h, index }));

          setPickedRisk(
            uniqueDays
              .reverse()
              .filter((item) => item.changes && item.changes.probability && item.changes.impact)
              .slice(0, 15)
              .reverse()
          );
          setMatrix(
            calculateMatrixData(
              uniqueDays
                .reduceRight<Array<RiskHistory>>(groupByPI, [])
                .slice(0, 15)
                .map((h, index) => ({ ...h, index }))
            )
          );
        })
        .catch((err) => logError(err));
    };

    if (selectedRisks.length === 1) {
      getHistories();
    } else if (selectedRisks.length > 1) {
      setMatrix(calculateMatrixData(selectedRisks));
    } else {
      getRisksByProjectId(projectId);
    }
  }, [selectedRisks]);

  selectedRisks.map((item) => {
    selectedRiskId = item.id;
  });

  return (
    <Box height="95vh" width="100%" display="flex">
      {!projectId ? (
        <>
          <Hidden only={["sm", "xs"]}>
            <Grid item md={9} lg={9}>
              <br></br>
              <br></br>
              <br></br>
              <Grid justify="center">
                <Typography className={classes.projectNameStyle} component="h6" variant="h4" align="center">
                  {errorMessage}
                </Typography>
              </Grid>
            </Grid>
          </Hidden>
        </>
      ) : (
        <>
          <Hidden only={["sm", "xs"]}>
            <Grid item md={9} lg={9}>
              <br></br>
              <Grid justify="center">
                <Typography className={classes.projectNameStyle} component="h6" variant="h4" align="center">
                  {projectName}
                </Typography>
              </Grid>
              <Box
                id="riskMatrix"
                marginTop="2%"
                marginLeft="auto"
                marginRight="auto"
                bgcolor="white"
                width="90%"
                display="flex"
                justifyContent="center"
                alignContent="center"
              >
                <Box
                  margin="2%"
                  width="100%"
                  maxHeight="1000px"
                  bgcolor="white"
                  display="flex"
                  flexDirection="column"
                  alignContent="center"
                >
                  <RiskMatrixSvg
                    matrixData={matrix}
                    selectedRisks={selectedRisks}
                    pickedRisk={pickedRisk}
                    animation={true}
                  />
                </Box>
              </Box>
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <ShareRiskList
              riskList={projectRisks}
              selectedRisks={selectedRisks}
              setSelectedRisks={setSelectedRisks}
              setPickedRisk={setPickedRisk}
            />
          </Grid>
        </>
      )}
    </Box>
  );
}
