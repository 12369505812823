import React, { useContext, useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Typography from "@material-ui/core/Typography";
import { ModalContext } from "../contexts/ModalContext";
import { ProjectService } from "../../services/projectService";
import Checkbox from "@material-ui/core/Checkbox";
import { UserContext } from "../contexts/UserContext";
import { SubscriptionWorkflowContext } from "./SubscriptionWorkflowContext";
import stepToModal from "./stepToModal";
import { SubscriptionType } from "./subscriptionType";
import SubscriptionService from "../../services/subscriptionService";
import { Constraints } from "../../utils/jwt-token";
import { SnackbarContext } from "../contexts/SnackbarContext";
import useLoadingEffect from "../load/useLoadingEffect";
import { ResponseError } from "../../services/grapqhlResponse";
import RizkiLoadingEffect from "../load/RizkiLoadingEffect";

const SubscriptionProjectSelectModal = () => {
  const [user] = useContext(UserContext);
  const [modalProps, setModalProps] = useContext(ModalContext);
  const [ownProjects, setOwnProjects] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [workflow, setWorkflow] = useContext(SubscriptionWorkflowContext);
  const [limits, setLimits] = useState<Constraints>({});
  const [, notifyUser] = useContext(SnackbarContext);

  const type = workflow?.to;

  const [loading, callActionWith] = useLoadingEffect(
    (userId: string) =>
      new ProjectService().getProjectForUser(userId, {
        offset: 0,
        limit: 1000,
      }),
    {
      onSuccess: (arg, projects) => {
        const filteredProjects = projects.rows.filter((project) => {
          return project.projectManager.toString() === user.user_id.toString();
        });
        setOwnProjects(filteredProjects);
      },
      onError: (error: ResponseError) => console.log("error", error),
    }
  );

  useEffect(() => {
    const fetchProjects = () => {
      callActionWith(user.user_id);
    };

    const getLimits = (type: SubscriptionType) => {
      new SubscriptionService()
        .getSubscriptionLimitsFor(type)
        .then(setLimits)
        .catch((error) =>
          notifyUser({
            show: true,
            type: "error",
            message: error.message,
          })
        );
    };

    if (modalProps.subscriptionProjectSelectModal.show) {
      fetchProjects();
      getLimits(workflow.to);
    }
  }, [modalProps.subscriptionProjectSelectModal.show]);

  const goBack = () => {
    const nextStep = workflow.step - 1;
    setWorkflow({
      ...workflow,
      step: nextStep,
    });
    setModalProps({
      ...modalProps,
      subscriptionProjectSelectModal: {
        show: false,
      },
      [stepToModal(workflow.steps[nextStep])]: {
        ...modalProps[stepToModal(workflow.steps[nextStep])],
        show: true,
        tab: 2,
      },
    });
  };

  const goNext = () => {
    const nextStep = workflow.step + 1;
    setWorkflow({
      ...workflow,
      step: nextStep,
      projects: selectedProjects,
    });
    setModalProps({
      ...modalProps,
      subscriptionProjectSelectModal: {
        show: false,
      },
      [stepToModal(workflow.steps[nextStep])]: {
        show: true,
      },
    });
  };

  const handleChange = (event) => {
    const value = event.target.value;
    const newProjects = [].concat(selectedProjects);
    const index = newProjects.indexOf(value);
    if (index > -1) {
      newProjects.splice(index, 1);
    } else {
      if (newProjects.length < limits.projectLimit) {
        newProjects.push(value);
      }
    }
    setSelectedProjects(newProjects);
  };

  return (
    <div>
      <Dialog
        open={modalProps.subscriptionProjectSelectModal.show}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Downgrading to ${
          type?.charAt(0).toUpperCase() + type?.slice(1)
        } Account`}</DialogTitle>
        <DialogContent dividers>
          <div
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <RizkiLoadingEffect loading={loading} />
          </div>
          <DialogContentText id="alert-dialog-description">
            <Typography component={"span"} gutterBottom>
              {ownProjects.length === 0
                ? "You have no project"
                : `Select ${limits.projectLimit} project${
                    limits.projectLimit > 1 ? "s" : ""
                  } to keep. Unselected ones will be deleted`}
            </Typography>
            <FormGroup style={{ flexWrap: "nowrap" }}>
              {ownProjects &&
                ownProjects.map((project) => {
                  return (
                    <FormControlLabel
                      disabled={
                        (selectedProjects.length >= limits.projectLimit &&
                        selectedProjects.indexOf(project.id) === -1) || loading
                      }
                      key={project.id}
                      value={project.id}
                      control={
                        <Checkbox
                          color="primary"
                          checked={selectedProjects.indexOf(project.id) > -1}
                          onChange={handleChange}
                          name={project.name}
                        />
                      }
                      label={project.name}
                      style={{ marginRight: "5%" }}
                    />
                  );
                })}
            </FormGroup>
            {ownProjects.length > 0 && selectedProjects.length === 0 ? (
              <Typography
                style={{
                  color: "#0869ff",
                  fontFamily: "Roboto",
                  textAlign: "center",
                }}
              >
                Note: Without selection all projects are deleted!
              </Typography>
            ) : null}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={loading}
            onClick={() => {
              goBack();
            }}
            color="primary"
            variant="outlined"
          >
            Back
          </Button>
          <Button
            disabled={loading}
            onClick={() => {
              goNext();
            }}
            color="primary"
            variant="contained"
          >
            Next
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SubscriptionProjectSelectModal;
