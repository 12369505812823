
const getErrorFromFieldArray = (
    errors: any,
    arrayLocation: { key: string, index: number, name: string }
): string | undefined => {
    if (arrayLocation == null || errors == null) {
        return undefined;
    }

    if (errors[arrayLocation.key]
        && errors[arrayLocation.key].length > arrayLocation.index
        && errors[arrayLocation.key][arrayLocation.index]) {
        return errors[arrayLocation.key][arrayLocation.index][arrayLocation.name]
    }
    return undefined;
}

export default getErrorFromFieldArray;