import React from "react";

type Props = {
    space?: boolean;
    size?: string;
}

export const Logo = ({ space = true, size = '150px' }: Props) => {
    return (
        <img src={'/rizki_s.svg'} alt='logo with claim' width={size} style={ space ? {marginBottom: "2vh"} : {}} />
    )
}