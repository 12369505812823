import React, { useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import Slider from "material-ui/Slider";
import { Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    avatarImageStyle: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        },

    },
    avatarSize: {
        width: theme.spacing(15),
        height: theme.spacing(15),
    }
}));

export default function EditAvatar(props) {
    const classes = useStyles();

    const [imageZoom, setImageZoom] = useState(1);
    const [editor, setEditor] = useState(null);

    const handleCroppedImage = () => {
        const canvasScaled = editor.getImageScaledToCanvas();
        const croppedImg = canvasScaled;
        props.setCroppedImage(croppedImg);
    }

    const onImageReady = () => {
        handleCroppedImage();
    }

    const onImageChange = () => {
        handleCroppedImage();
    }

    const handleZoomSlider = (_, value) => {
        setImageZoom(value);
    };

    return (<Grid item>
        <div className={classes.avatarImageStyle} style={{justifyContent: "center"}}>
            <AvatarEditor
                ref={setEditor}
                image={props.uploadedImage}
                width={200}
                height={200}
                scale={imageZoom}
                borderRadius={100}
                onImageReady={onImageReady}
                onImageChange={onImageChange}
                color={[224, 224, 224, 1]}
            />
        </div>
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}
        >
            <label
                style={{
                    fontSize: 12,
                    marginRight: 10,
                    paddingBottom: 22,
                    fontWeight: 600
                }}>Zoom</label>
            <Slider
                min={1}
                max={5}
                step={0.0000000001}
                onChange={handleZoomSlider}
                value={imageZoom}
                style={{ width: 200 }}></Slider>
        </div>
    </Grid>);
}